import { IsActiveMatchOptions } from "@angular/router";
import { AppBarCloseAction, AppBarOptions, AppSidenavItem } from "common";
import { addDefaultAppSidenavItemOptions } from "../Tools";
import {
  AppBarDropdownObject,
  AppBarMenuItemAction,
  MenuItemType,
} from "./shared/app-bar-dropdown/app-bar-dropdown.model";
import {
  BrokerStatus,
  SubmissionUiLabel,
  WebPaths,
} from "./shared/SharedConstants";

export const appBarData = (): AppBarOptions => {
  return {
    logoSize: "logo-s",
    isSidenavAppBar: true,
    closeAction: AppBarCloseAction.SIDENAV,
    closeIcon: "keyboard_arrow_left",
    backgroundClass: "dashboard-app-bar",
    containerWrapperClass: "dashboard-app-bar",
    containerClass: "space-between-center",
    sidenavOpenIcon: "keyboard_arrow_right",
  };
};
export const appBarDropdownData = (): AppBarDropdownObject => {
  return {
    menuIcon: "settings",
    menuItems: [
      {
        icon: "person",
        label: "Profile",
        typeOptions: { type: MenuItemType.LINK, url: "/profile" },
      },
      {
        icon: "lock",
        label: "Update Password",
        typeOptions: {
          type: MenuItemType.LINK,
          url: "/update-password",
        },
      },
      {
        icon: "info",
        label: "About",
        typeOptions: {
          type: MenuItemType.LINK,
          url: "/about",
        },
      },
      {
        icon: "directions_run",
        label: "Logout",
        typeOptions: {
          type: MenuItemType.ACTION,
          action: AppBarMenuItemAction.LOG_OUT,
        },
      },
    ],
  };
};
export const appSidenavRouterLinkActiveOptions = (): IsActiveMatchOptions => {
  return {
    matrixParams: "exact",
    queryParams: "subset",
    paths: "exact",
    fragment: "exact",
  };
};

export const baseAppSidenavItems = ({
  showConditionalOfferTab,
}: {
  showConditionalOfferTab: boolean;
}): AppSidenavItem[] => {
  const baseAppSidenavItems = [
    {
      url: WebPaths.Dashboard,
      label: "Home",
      icon: "home",
      query: {
        sort: undefined,
      },
    },
    {
      url: WebPaths.Submissions,
      label: SubmissionUiLabel.SUBMISSIONS,
      icon: "assignment",
      subitems: [
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.INCOMPLETE],
          },
          label: SubmissionUiLabel.INCOMPLETE,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.PROCESSING, BrokerStatus.CLOSING],
          },
          label: SubmissionUiLabel.PROCESSING,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.OFFER],
          },
          label: SubmissionUiLabel.OFFER,
        },
        ...(showConditionalOfferTab
          ? [
              {
                url: WebPaths.Submissions,
                query: {
                  brokerStatus: [BrokerStatus.CONDITIONAL_OFFER],
                },
                label: SubmissionUiLabel.CONDITIONAL_OFFER,
              },
            ]
          : []),
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.CONTRACT_READY],
          },
          label: SubmissionUiLabel.CONTRACT_READY,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.CONTRACT_OUT],
          },
          label: SubmissionUiLabel.CONTRACT_OUT,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.DECLINED],
          },
          label: SubmissionUiLabel.DECLINED,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.OPEN],
          },
          label: SubmissionUiLabel.OPEN,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.FUNDED],
          },
          label: SubmissionUiLabel.FUNDED,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.CLOSED],
          },
          label: SubmissionUiLabel.CLOSED,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.DORMANT],
          },
          label: SubmissionUiLabel.DORMANT,
        },
        {
          url: WebPaths.Submissions,
          query: {
            brokerStatus: [BrokerStatus.NOT_INTERESTED],
          },
          label: SubmissionUiLabel.NOT_INTERESTED,
        },
      ],
    },
  ];
  return addDefaultAppSidenavItemOptions(baseAppSidenavItems, {
    query: { sort: "-updatedOn" },
  });
};
