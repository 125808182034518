import { Injectable} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable} from 'rxjs';
import { finalize, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AvoidConcurrentHttpCallsInterceptor implements HttpInterceptor {
	
    private debug = true;
    private pendingCalls = new Map<string, Observable<any>>();

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method === 'GET') {
			const cachedObservable = this.pendingCalls.get(request.urlWithParams);
            if (this.debug && cachedObservable) console.log(`${new Date().toISOString()} Concurrent calls hit: ${request.urlWithParams}`);
			return cachedObservable ? cachedObservable : this.sendRequest(request, next);
		}
		return next.handle(request);
	}

	private sendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (this.debug) console.log(`${new Date().toISOString()} Concurrent calls put: ${request.urlWithParams}`);
		const observable = next.handle(request).pipe(
            finalize(() => {
                this.pendingCalls.delete(request.urlWithParams);
                if (this.debug) console.log(`${new Date().toISOString()} Concurrent calls del: ${request.urlWithParams}`);
            }),
            share()
        );
        this.pendingCalls.set(request.urlWithParams, observable);
        return observable;
    }
}
