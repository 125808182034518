import { NgModule } from "@angular/core";
import { CommonModule, MfaModule as MfaCommonModule } from "common";
import { MfaComponent } from "./mfa.component";
import { MfaRoutingModule } from "./mfa-routing.module";

@NgModule({
  imports: [MfaCommonModule, CommonModule, MfaRoutingModule],
  declarations: [MfaComponent],
})
export class MfaModule {}
