import { Injectable } from "@angular/core";
import { BrokerStatus, SubmissionDetailsViews, SubmissionUiLabel, WebPaths, submissionStageUiMap } from "../../../../shared/SharedConstants";
import { ApplicationStage } from "common";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SubmissionDetailsService {
  constructor(private router: Router) { }

  goToSubmissionDetails(id: number, brokerStatus: BrokerStatus, applicationStage: ApplicationStage) {
    const expectedDetailsView = this.mapBrokerStatusToSubmissionDetailsView(brokerStatus, applicationStage);
    return this.navigateTo(WebPaths.SubmissionDetails(expectedDetailsView, id));
  }

  isDetailsViewCorrectForBrokerStatus(detailsView: SubmissionDetailsViews, brokerStatus: BrokerStatus, applicationStage: ApplicationStage) {
    const expectedDetailsView = this.mapBrokerStatusToSubmissionDetailsView(brokerStatus, applicationStage);
    return detailsView === expectedDetailsView;
  }

  private mapBrokerStatusToSubmissionDetailsView(brokerStatus: BrokerStatus, applicationStage: ApplicationStage): SubmissionDetailsViews {
    switch (brokerStatus) {
      case BrokerStatus.PROCESSING:
      case BrokerStatus.NOT_INTERESTED:
      case BrokerStatus.DECLINED: {
        return SubmissionDetailsViews.ProcessingDetails;
      }
      case BrokerStatus.OPEN:
      case BrokerStatus.FUNDED: {
        return SubmissionDetailsViews.OpenDetails;
      }
      case BrokerStatus.DORMANT: {
        if (applicationStage !== ApplicationStage.Closed) {
          return SubmissionDetailsViews.ProcessingDetails;
        }
        return SubmissionDetailsViews.ClosedDetails;
      }
      case BrokerStatus.CLOSED: {
        return SubmissionDetailsViews.ClosedDetails;
      }
      case BrokerStatus.CONTRACT_READY:
      case BrokerStatus.CONTRACT_OUT: {
        return SubmissionDetailsViews.CheckoutReady;
      }
      case BrokerStatus.INCOMPLETE: {
        if (
          !Array.from(submissionStageUiMap.keys())
            .filter(
              key =>
                submissionStageUiMap.get(key) === SubmissionUiLabel.INCOMPLETE,
            )
            .includes(applicationStage)
        ) {
          console.warn(
            `Application stage ${applicationStage} does not match broker status of Incomplete.`,
          );
        }
        if (applicationStage === ApplicationStage.ClosingIncomplete) {
          return SubmissionDetailsViews.OfferDetails;
        } else {
          return SubmissionDetailsViews.SubmissionForm;
        }
      }
      case BrokerStatus.OFFER:
      case BrokerStatus.CONDITIONAL_OFFER: {
        return SubmissionDetailsViews.OfferDetails;
      }
      case BrokerStatus.CLOSING: {
        return SubmissionDetailsViews.ClosingReview;
      }
    }
  }

  private navigateTo(url: string) {
    void this.router.navigate([url]);
  }
}
