import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WebPaths } from 'projects/partner/src/app/shared/SharedConstants';
import { HomeFacade } from './home.facade';
import { HomeData } from './home.model';

@Component({
  selector: 'ifbp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeFacade]
})
export class HomeComponent implements OnInit {
  data$: Observable<HomeData>;
  readonly WebPaths = WebPaths;

  constructor(private readonly homeFacade: HomeFacade) { }

  ngOnInit(): void {
    this.data$ = this.homeFacade.getHomeData$();
  }
}
