import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BrokerDataDetails } from "../../broker/domain/models/broker.model";

@UntilDestroy()
@Component({
  selector: "ifbp-thank-you-dialog",
  templateUrl: "./thank-you-dialog.component.html",
})
export class ThankYouDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { brokerDataDetails: BrokerDataDetails },
  ) {}
}
