import { Component, Input } from '@angular/core';
import { AutocompleteComponent } from 'common';
import { EMPTY, Observable } from 'rxjs';
import { SubmissionsFacade } from '../../../../domain/+state/submissions.facade';
import { SubmissionsHintsFieldName } from '../../../../domain/models/submissions.model';

@Component({
  selector: 'ifbp-submission-autocomplete-input',
  templateUrl: '../../../../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.html',
  styleUrls: ['../../../../../../../../../common/src/lib/ui/autocomplete/autocomplete.component.css']
})
export class SubmissionAutocompleteInputComponent extends AutocompleteComponent {
  constructor(private submissionsFacade: SubmissionsFacade) {
    super();
  }

  @Input()
  label: string;

  @Input()
  fieldName: SubmissionsHintsFieldName;

  optionFilter: (input: string) => Observable<any[]> = (input) => {
    return input
    ? this.submissionsFacade.getSubmissionsHints({
      fieldName: this.fieldName,
      value: input,
    })
    : EMPTY;
  }
}
