import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import {
  ApplicationRequestData,
  ApplicationResponse,
  ErrorResponse,
  UppyInput,
} from "../submission/models/submission.model";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  ApplicationOffer,
  ApplicationStage,
  AppPageService,
  CheckoutRequirementStatus,
  OfferBundleCheckoutRequirement,
  OfferBundleCheckoutRequirementService,
  OfferBundleStatus,
  ProductCode,
} from "common";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { SubmissionService } from "../shared/submission.service";
import {
  hasValue,
  getUppyInputFromFile,
  filterAndSplitCheckoutRequirements,
  convertToExistingFile,
} from "../../Tools";
import { finalize, mergeMap, tap } from "rxjs/operators";
import { forkJoin, Observable, of } from "rxjs";
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MAX_FILE_COUNT } from "./closing-review.data";
import { UppyFile } from "@uppy/core";
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from "../feature-modules/submissions/features/not-interested-action/not-interested-action.model";
import { UploadFileDialogImprovedComponent } from "../shared/upload-file-dialog-improved/upload-file-dialog-improved.component";

@UntilDestroy()
@Component({
  selector: "ifbp-closing-review",
  templateUrl: "./closing-review.component.html",
  styleUrls: ["./closing-review.component.scss"],
})
export class ClosingReviewComponent implements OnInit {
  actionInProgress: boolean;
  applicationId: number;
  bundleId: number | undefined;
  applicationInformation: ApplicationResponse;
  checkoutRequirements: OfferBundleCheckoutRequirement[] = [];
  closingDocumentsForm: UntypedFormGroup;
  isClosingDocumentsValid: boolean;
  nextStepRequirements: OfferBundleCheckoutRequirement[] = [];
  selectedOffer: ApplicationOffer | undefined;
  notInterestedActionData: NotInterestedActionInputData;

  readonly CheckoutRequirementStatus = CheckoutRequirementStatus;
  readonly ProductCode = ProductCode;

  constructor(
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private route: ActivatedRoute,
    private router: Router,
    private submissionService: SubmissionService,
    private offerBundleCheckoutRequirementService: OfferBundleCheckoutRequirementService,
    private dialog: MatDialog,
  ) {
    this.appBarActionsService.actions = [
      {
        id: "save",
        label: "SAVE",
        buttonType: "button",
      },
      {
        id: "submit",
        label: "SUBMIT",
        buttonType: "button",
        buttonAppearance: "flat",
        buttonColor: "primary",
      },
    ];
  }

  ngOnInit() {
    this.initRouteData();
    this.initAppBarActions();
  }

  private initRouteData() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.dataInit(this.applicationId);
      }
    });
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = params["id"];
      if (hasValue(applicationId)) {
        this.dataInit(parseInt(applicationId));
      }
    });
  }

  private dataInit(applicationId: number) {
    this.applicationId = applicationId;
    this.submissionService
      .getApplicationData(this.applicationId)
      .pipe(
        mergeMap((data: ApplicationResponse) => {
          this.applicationInformation = data;
          this.notInterestedActionData = this.initNotInterestedActionData(data);
          const acceptedOfferBundle = this.applicationInformation.offerBundles
            ?.filter(
              offerBundle => offerBundle.status === OfferBundleStatus.Accepted
            )
            ?.at(0);
          this.selectedOffer = acceptedOfferBundle?.offers?.at(0);
          this.bundleId = acceptedOfferBundle?.id;
          this.appBarTitleService.title = `${this.applicationInformation.accountName} - Closing`;
          if (!hasValue(this.bundleId)) {
            return of(null);
          } else {
            return this.offerBundleCheckoutRequirementService.getList(
              this.bundleId,
            );
          }
        }),
      )
      .subscribe(
        (checkoutRequirements: OfferBundleCheckoutRequirement[] | null) => {
          if (!hasValue(checkoutRequirements)) {
            return;
          }
          [this.checkoutRequirements, this.nextStepRequirements] =
            filterAndSplitCheckoutRequirements(checkoutRequirements);
          this.initClosingDocuments();
        },
      );
  }

  private initNotInterestedActionData(data: ApplicationResponse) {
    return {
      applicationId: this.applicationId,
      brokerStatus: data.brokerStatus,
      createOnDate: new Date(data.createdOn),
      previousStage: <ApplicationStage>data?.previousStage
    }
  }

  private initClosingDocuments() {
    const documentsData = {};
    for (const checkoutRequirement of this.checkoutRequirements) {
      if (checkoutRequirement.documents.length === 0) {
        documentsData[`${checkoutRequirement.id}`] = of(null);
      }
      for (const document of checkoutRequirement.documents) {
        documentsData[`${checkoutRequirement.id}-${document.id}`] =
          this.submissionService.getCheckoutRequirementFile(
            checkoutRequirement.id,
            document.id,
          );
      }
    }
    this.closingDocumentsForm = new FormGroup({});
    forkJoin(documentsData).subscribe((data: { [id: string]: Blob | null }) => {
      for (const checkoutRequirement of this.checkoutRequirements) {
        const existingCheckoutRequirementDocuments = [];
        for (const document of checkoutRequirement.documents) {
          const blob = data[`${checkoutRequirement.id}-${document.id}`];
          if (hasValue(blob)) {
            existingCheckoutRequirementDocuments.push(
              getUppyInputFromFile(document, blob),
            );
          }
        }
        this.closingDocumentsForm.addControl(
          checkoutRequirement.id.toString(),
          new FormControl(
            existingCheckoutRequirementDocuments,
            checkoutRequirement.status === CheckoutRequirementStatus.Required
              ? Validators.required
              : [],
          ),
        );
      }
      this.onClosingDocumentsChange();
    });
    this.closingDocumentsForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onClosingDocumentsChange());
  }

  private initAppBarActions() {
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  private onClosingDocumentsChange() {
    this.isClosingDocumentsValid = this.closingDocumentsForm.valid;
    this.appBarActionsService.enable("submit", this.isClosingDocumentsValid);
  }

  openUploadDialog(checkoutRequirementId: number) {
      const dialogRef = this.openFileDialog(MAX_FILE_COUNT - this.closingDocumentsForm
        .get([checkoutRequirementId])
        ?.value.length);

    dialogRef.afterClosed().subscribe((uploadedFiles) => {
      if (!uploadedFiles)
        return;

      this.closingDocumentsForm.patchValue({
        [checkoutRequirementId]: [...this.closingDocumentsForm.get([checkoutRequirementId])?.value, ...uploadedFiles],
      });
    });
  }

  private openFileDialog(maxFileCount: number): MatDialogRef<UploadFileDialogImprovedComponent, UppyFile[]> {
    return UploadFileDialogImprovedComponent.open(this.dialog, { maxFileCount });
  }

  removeDocument(checkoutRequirementId: number, documentIndex: number) {
    const currentCheckoutRequirementDocuments = this.closingDocumentsForm.get([
      checkoutRequirementId,
    ])?.value;
    this.closingDocumentsForm.patchValue({
      [checkoutRequirementId]: currentCheckoutRequirementDocuments
        .slice(0, documentIndex)
        .concat(currentCheckoutRequirementDocuments.slice(documentIndex + 1)),
    });
    this.updateIsFormChanged(checkoutRequirementId);
    if (!this.closingDocumentsForm.dirty) {
      this.appBarActionsService.enable("submit", false);
    }
  }

  private updateIsFormChanged(checkoutRequirementId: number) {
    const control = this.closingDocumentsForm.get([checkoutRequirementId]);
    if (!hasValue(control)) {
      return;
    }
    if (
      control.value.filter((document: UppyFile) => !document.meta.isExisting)
        .length > 0
    ) {
      control.markAsDirty();
    } else {
      control.markAsPristine();
    }

    this.closingDocumentsForm.updateValueAndValidity();
  }

  private getFilesToSave(): Observable<ErrorResponse>[] {
    const filesToSave: Observable<ErrorResponse>[] = [];
    this.checkoutRequirements?.forEach(checkoutRequirement => {
      this.closingDocumentsForm
        .get([checkoutRequirement.id])
        ?.value.forEach((file: UppyFile) => {
          const fileToSave = this.checkSaveFile(file, checkoutRequirement);
          if (hasValue(fileToSave)) {
            filesToSave.push(fileToSave);
          }
        });
    });
    return filesToSave;
  }

  save() {
    this.actionInProgress = true;

    const tasks: Observable<ErrorResponse | ApplicationRequestData>[] = [];
    const filesToSave = this.getFilesToSave();
    if (filesToSave.length > 0)
      filesToSave.forEach((fileToSave) => tasks.push(fileToSave));

    if (tasks.length > 0) {
      forkJoin(tasks)
        .pipe(
          finalize(() => {
            this.actionComplete();
          }))
        .subscribe();
    }
    else
      this.actionComplete();
  }


  submit() {
    this.actionInProgress = true;
    const filesToSave = this.getFilesToSave();
    if (filesToSave.length > 0) {
      forkJoin(filesToSave)
        .pipe(
          mergeMap(() =>
            this.submissionService.updateApplicationStage(
              this.applicationId,
              ApplicationStage.ClosingReview,
            ),
          ),
          finalize(() => {
            this.actionComplete();
          }),
        )
        .subscribe();
    } else {
      this.submissionService
        .updateApplicationStage(this.applicationId, ApplicationStage.ClosingReview)
        .pipe(
          finalize(() => {
            this.actionComplete();
          }),
        )
        .subscribe();
    }
  }

  private checkSaveFile(
    file: UppyFile,
    checkoutRequirement: OfferBundleCheckoutRequirement,
  ): Observable<ErrorResponse> | null {
    if (!file.meta.isExisting) {
      return this.submissionService.saveCheckoutRequirementFile(
        checkoutRequirement.id,
        checkoutRequirement.category,
        file,
      )
      .pipe(
        tap(fileIds => 
          this.markUploadedFileAsExisting(checkoutRequirement, file, fileIds)
        )
      );
    } else {
      return null;
    }
  }

  private markUploadedFileAsExisting(checkoutRequirement: OfferBundleCheckoutRequirement, file: UppyFile, fileIds: ErrorResponse) {
    const currentCheckoutRequirementDocuments = this.getFilesWithId(this.closingDocumentsForm.get([
      checkoutRequirement.id,
    ])?.value) as UppyInput[];

    var convertedFile = convertToExistingFile(file as UppyFile, fileIds[0] as number);
    this.closingDocumentsForm?.patchValue({
      [checkoutRequirement.id]: [...currentCheckoutRequirementDocuments, convertedFile],
    });
  }

  private getFilesWithId(files: UppyInput[]): UppyInput[] {
    return files.filter(file => file.meta.id);
  }

  private actionComplete() {
    this.actionInProgress = false;
    this.appPageService.back();
  }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    if (data.isSuccess) {
      this.appPageService.back();
    }
  }
}
