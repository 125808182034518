import { ActionButtonColor, ActionButtonType } from "projects/partner/src/app/shared/models/action-button/action-button.model";

export interface AdvisorWidgetData {
  avatar: string;
  name: string;
  email: string;
  phone?: string;
  calendarUrl: string;
}

export interface DashboardWidgetsData {
  mainWidgets: DashboardWidgetData[];
  asideWidgets: DashboardWidgetData[];
}

export interface DashboardWidgetData {
  active: boolean;
  title?: string;
  body?: string;
  iconUrl?: string;
  actions?: DashboardWidgetActionData[];
}

export interface DashboardWidgetActionData {
  label: string;
  buttonType: ActionButtonType;
  buttonColor: ActionButtonColor;
  type: DashboardActionType;
  redirectUrl: string;
}

export enum DashboardActionType {
  RedirectInternal = "redirect-internal",
  RedirectExternal = "redirect-external",
}
