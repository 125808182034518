<!-- modified from portal project (release 4.0)
IdeaFinancial.Web/projects/portal/src/app/user/user-password-change-form/user-password-change-form.component.html -->
<div fxLayoutAlign="center start" class="update-password-container">
  <form fxLayout="column" class="update-password-form" (ngSubmit)="submit()" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Current password</mat-label>
      <input matInput type="password" required formControlName="currentPassword" autocomplete="current-password">
      <mat-error>
        Please enter your current password.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>New password</mat-label>
      <input matInput type="password" required formControlName="newPassword" autocomplete="new-password">
      <mat-error>
        Minimum of 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>New password confirmation</mat-label>
      <input matInput type="password" required formControlName="confirmPassword" autocomplete="new-password">
      <mat-error>
        Your password and confirmation must match
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="flex-end center" class="update-password-button-row center-center layout margin-top">
      <button mat-flat-button type="button" class="update-password-button" (click)="cancel()">CANCEL</button>
      <button mat-flat-button color="primary" class="update-password-button" type="submit" [disabled]="!this.form.valid">SAVE</button>
    </div>
  </form>
</div>
