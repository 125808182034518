import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SignInFacade } from './sign-in.facade';
import { SignInActionsService, SignInComponentInputData, SignInFormData } from 'common';

@UntilDestroy()
@Component({
  selector: 'ifbp-sign-in',
  templateUrl: './sign-in.component.html',
  providers: [SignInFacade]
})
export class SignInComponent implements OnInit {
  data: SignInComponentInputData;

  constructor(
    private readonly facade: SignInFacade,
    private readonly actionsService: SignInActionsService
  ) { }

  ngOnInit() {
    this.initData();
    this.watchActions();
  }

  private initData() {
    this.data = this.facade.initData();
  }

  private watchActions() {
    this.actionsService.signInByPassword$.pipe(untilDestroyed(this))
      .subscribe((data: SignInFormData) => this.facade.signInByPassword(data));
    this.actionsService.forgotPassword$.pipe(untilDestroyed(this))
      .subscribe((email: string) => this.facade.forgotPassword(email));
  }
}
