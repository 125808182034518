import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { ApiUrls } from "../../../shared/SharedConstants";
import { AppSettings } from "projects/partner/src/app.settings";
import { DashboardWidgetsData } from "../domain/model/dashboard.model";
import { catchError } from "rxjs/operators";
import { MessageService } from "common";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class DashboardRemote {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private messageService: MessageService
  ) { }

  getDashboardWidgets(): Observable<any> {
    const errorMessage = "Something went wrong while retrieving dashboard widget data, please try again later";

    return this.http.get<DashboardWidgetsData>(ApiUrls(this.settings).DashboardWidgets).pipe(catchError(() => {
      this.messageService.error(errorMessage);
      Sentry.captureException(errorMessage);
      return of(null);
    }));
  }
}
