import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  MessageService,
} from "common";
import { ApiUrls } from "../../../shared/SharedConstants";
import { AppSettings } from "projects/partner/src/app.settings";
import { catchError, map } from "rxjs/operators";
import { defaultObservableErrorHandler } from "projects/partner/src/Tools";
import { ApplicationData, SubmissionQueryParams, SubmissionsHintsQueryParams, SubmissionsHintsResponse, SubmissionsQueryResult, UpdateApplicationPayload } from "../domain/models/submissions.model";
import { ApiPaths } from "../../../core/const/api-paths";

@Injectable({
  providedIn: "root",
})
export class SubmissionsRemote {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private messageService: MessageService
  ) { }

  updateApplicationStage(
    applicationId: number,
    payload: UpdateApplicationPayload,
  ): Observable<ApplicationData> {
    return this.http
      .patch<any>(
        ApiUrls(this.settings).UpdateApplicationStage({
          application: { id: applicationId },
        }),
        payload,
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getSubmissions(params: SubmissionQueryParams): Observable<SubmissionsQueryResult> {
    return this.http.get<SubmissionsQueryResult>(ApiPaths.submission.list, { params: <any>params });
  }

  getSubmissionsHints(params: SubmissionsHintsQueryParams): Observable<string[]> {
    return this.http.get<SubmissionsHintsResponse>(ApiPaths.submission.hints, { params: <any>params })
      .pipe(map(response => (response.values)));
  }
}
