// based on components in application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/shared/share-offer
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router, UrlTree } from "@angular/router";
import { ApplicationBaseData, ApplicationOffer, DomHelper } from "common";
import { AppSettings } from "projects/partner/src/app.settings";
import { hasValue } from "projects/partner/src/Tools";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApplicationService } from "../application.service";
import { BankLinkSentComponent } from "./bank-link-sent/bank-link-sent.component";
import { PlaidInfoComponent } from "./plaid-info/plaid-info.component";
import { SendBankLinkEmailComponent } from "./send-bank-link-email/send-bank-link-email.component";
import { SendOfferEmailComponent } from "./send-offer-email/send-offer-email.component";
import {
  CUSTOMER_PATH,
  DEFAULT_DIALOG_CONFIG,
  getRootUrl,
  SHARE_PATH,
} from "./share-offer.data";

declare global {
  interface Window {
    Plaid: any;
  }
}

@Component({
  selector: "ifbp-share-offer",
  templateUrl: "./share-offer.component.html",
  styleUrls: ["./share-offer.component.scss"],
})
export class ShareOfferComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;

  @Input() offer: ApplicationOffer;
  @Input() bundleId: number;
  @Input() emailBody: string;
  @Input() uuid: string;
  @Input() showPlaidLink: boolean;
  @Input() showCustomerRegistration: boolean;

  application: ApplicationBaseData;
  offerId: number;
  shareUrl: string;
  customerUrlTree: UrlTree;
  rootUrl: string;

  constructor(
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _applicationService: ApplicationService,
    private _snackBar: MatSnackBar,
    private appSettings: AppSettings,
  ) {
    this._unsubscribeAll = new Subject();
    this.rootUrl = getRootUrl(
      this.appSettings.rootSite.url.split(".").at(-1) ?? "",
    );
  }

  ngOnInit(): void {
    this._applicationService
      .getByUuid(this.uuid)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(this.dataInit.bind(this));
  }

  private dataInit(data: ApplicationBaseData) {
    this.application = data;
    this.customerUrlTree = this._router.createUrlTree(["summary"], {
      queryParams: { uuid: this.uuid, bundleId: this.bundleId },
    });
    this.shareUrl = `${
      this.rootUrl
    }${SHARE_PATH}${this.customerUrlTree.toString()}`;
  }

  sendEmailOffer(): void {
    SendOfferEmailComponent.show(
      this.dialog,
      this.application,
      this.shareUrl,
      this.offer,
      this.emailBody,
    );
  }

  openSendBankLinkEmailDialog(): void {
    const dialogRef = this.dialog.open(SendBankLinkEmailComponent, {
      ...DEFAULT_DIALOG_CONFIG,
      data: {
        application: this.application,
      },
    });
    dialogRef.afterClosed().subscribe(email => {
      if (hasValue(email)) {
        this.openBankLinkSentDialog(email);
      }
    });
  }

  private openBankLinkSentDialog(email: string): void {
    this.dialog.open(BankLinkSentComponent, {
      ...DEFAULT_DIALOG_CONFIG,
      data: {
        email,
      },
    });
  }

  copy(): void {
    DomHelper.copy(this.shareUrl);
    this._snackBar.open(`Link copied`, "", { duration: 5000 });
  }

  customerView(): void {
    this._route.url.subscribe(_url => {
      window.open(
        `${this.rootUrl}${CUSTOMER_PATH}${this.customerUrlTree.toString()}`,
      );
    });
  }

  openPlaidInfoDialog(): void {
    PlaidInfoComponent.show(this.dialog);
  }
}
