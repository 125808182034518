import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms"
import { SignUpForm } from "./sign-up.model";

export const getSignUpFormGroup = () => {
    return new FormGroup<SignUpForm>({
        email: new FormControl('', {
            validators: [
                Validators.required, 
                Validators.email
            ]
        }),
        registrationToken: new FormControl('', {
            validators: [
                Validators.required
            ]
        }),
        password: new FormControl('', {
            validators: [
                Validators.required,
                Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
            ]
        }),
        confirmPassword: new FormControl('', {
            validators: [
                Validators.required,
                formPasswordConfirmationValidator,
              ]
        }),
      })
}

function formPasswordConfirmationValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.parent) return;
    const passwordControl = control.parent.get('password');
    if (passwordControl.valid && control.value !== passwordControl.value)
      return { notMatch: 'Your password and confirmation must match.' };
    return null;
  }