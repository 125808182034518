import { Component, Input } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BrokerStatus } from "../SharedConstants";

@UntilDestroy()
@Component({
  selector: "ifbp-submission-status",
  templateUrl: "./submission-status.component.html",
  styleUrls: ["./submission-status.component.scss"],
})
export class SubmissionStatusComponent {
  @Input() brokerStatus: BrokerStatus;
  readonly BrokerStatus = BrokerStatus;
}
