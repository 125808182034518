// modified from portal project (release 4.0)
// IdeaFinancial.Web/projects/portal/src/app/user/user-password-change-form/user-password-change-form.component.ts
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import { AppPageService } from "common";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { passwordValidatorRegex } from "../shared/SharedConstants";
import { BrokerUserData } from "../broker/domain/models/broker.model";
import { MessageService } from "projects/common/src/core/message/message.service";
import { BrokerFacade } from "../broker/domain/+state/broker.facade";

@UntilDestroy()
@Component({
  selector: "ifbp-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.scss"],
})
export class UpdatePasswordComponent implements OnInit {
  form: UntypedFormGroup;
  brokerUser: BrokerUserData;

  constructor(
    private appPageService: AppPageService,
    private formBuilder: UntypedFormBuilder,
    private brokerFacade: BrokerFacade,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.form = this.formBuilder.group(
      {
        currentPassword: ["", Validators.required],
        newPassword: [
          "",
          [Validators.required, Validators.pattern(passwordValidatorRegex)],
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: formPasswordConfirmationValidator(
          "newPassword",
          "confirmPassword",
        ),
      },
    );
  }

  ngOnInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.brokerUser = data.brokerUser;
    });
  }

  cancel() {
    this.appPageService.back();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.brokerFacade
      .updateBrokerPassword(this.brokerUser.id, {
        currentPassword: this.form.value.currentPassword,
        newPassword: this.form.value.newPassword,
        confirmPassword: this.form.value.confirmPassword,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: this.actionComplete.bind(this),
        error: this.trySetIncorrectPasswordError.bind(this),
      });
  }

  private trySetIncorrectPasswordError(response: HttpErrorResponse) {
    if (!response.error?.Message.toLowerCase().includes("current password")) {
      this.messageService.error("There was an error updating your password. Try again or contact your business advisor if the error keeps occurring.");
      return;
    }

    this.form.controls["currentPassword"].setErrors({
      ...this.form.controls["currentPassword"].errors,
      wrongPassword: true,
    });
  }

  private actionComplete() {
    this.messageService.success("Your password has been successfully updated.");
    this.appPageService.back();
  }
}

function formPasswordConfirmationValidator(
  controlName: string,
  matchingControlName: string,
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
