<div fxLayout="column"
     fxLayoutAlign="start center"
     class="checkout-ready-container">
  <mat-card fxLayout="column"
            fxLayoutAlign="start center"
            class="loan-summary-card mat-elevation-z">
    <div class="header">{{ selectedOffer?.productCode | productCodeLabel}} offer selected</div>
    <div fxLayout="row"
         fxLayoutAlign="space-evenly center"
         class="card-row">
      <div fxLayout="column"
           fxLayoutAlign="start center"
           class="card-column">
        <div class="info-text">{{ selectedOffer?.amount | currency : 'USD' : 'symbol' : '1.0-0' }}</div>
        <div class="subheading">{{ selectedOffer?.productCode === ProductCode.LOC ? "Credit limit" : "Loan amount"}}</div>
      </div>
      <div fxLayout="column"
           fxLayoutAlign="start center"
           class="card-column">
        <div class="info-text">
          {{
            selectedOffer?.repaymentTerm + (
              selectedOffer?.repaymentTerm > 1
                ? " months"
                : " month"
            )
          }}
        </div>
        <div class="subheading">Term</div>
      </div>
      <div fxLayout="column"
           fxLayoutAlign="start center"
           class="card-column">
        <div class="info-text">{{ selectedOffer?.paymentFrequency | paymentFrequencyLabel }}</div>
        <div class="subheading">Payment frequency</div>
      </div>
    </div>
  </mat-card>
  <div class="note">
    {{
      isContractOut
        ? "Checkout has been sent!"
        : "Congratulations, it’s time for checkout!"
    }}
  </div>
  <div class="note-detail">
    {{
      isContractOut
        ? "Resend an activation email to the client with instructions on how to access their funds below"
        : "Pressing Send Checkout will create the loan and send an activation email to the client with instructions on how to access their funds."
    }}
  </div>
  <button mat-flat-button
          color="primary"
          class="send-checkout-button"
          (click)="onSendCheckoutClick()">
    {{
      isContractOut
        ? "RESEND"
        : "SEND"
    }} CHECKOUT
  </button>
  <ifbp-share-offer *ngIf="selectedOffer"
                    [uuid]="applicationInformation.uuid"
                    [offer]="selectedOffer"
                    [bundleId]="bundleId"></ifbp-share-offer>
  <div class="bottom-actions-container"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <ifbp-not-interested-action [data]="notInterestedActionData"
                                (actionComplete)="onNotInterestedActionComplete($event)">
    </ifbp-not-interested-action>
    <button mat-button
            (click)="onClickBack()">BACK</button>
  </div>
</div>
