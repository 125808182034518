// based on application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/calculator/term-offer-calculator.ts
import { OfferCalculator } from "./offer-calculator";

export class TermOfferCalculator extends OfferCalculator {
  protected getAnnualRate(): number {
    return this.rate / 100;
  }

  protected getMonthlyRate(): number {
    return this.annualRate / 12;
  }

  protected getTotalInterest(term: number): number {
    return this._calculatorService.getTermLoanTotalInterest(
      this.applicationOffer,
      term,
    );
  }

  protected getTermPayment(): number {
    return this._calculatorService.getTermLoanPayment(
      this.amount,
      this.totalInterest,
      this.totalNumberOfPayments,
    );
  }

  protected initSampleDraw(): void {
    this._sampleDraw = this.amount;
  }

  protected getCommision(): number {
    return (this.amount * this.applicationOffer.maxUpsell) / 100;
  }

  override get disbursedAmount(): number {
    return this.sampleDraw - this.drawFee - this.originationFeeAmount;
  }
}
