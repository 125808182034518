import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-selection-submitted-dialog",
  templateUrl: "./selection-submitted-dialog.component.html",
  styleUrls: ["./selection-submitted-dialog.component.scss"],
})
export class SelectionSubmittedDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      advisorFirstName: string;
      advisorEmail: string;
      advisorPhone: string;
    },
  ) {}
}
