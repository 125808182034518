export const MAX_FILE_COUNT = 25;

export const ALLOWED_FILE_TYPES = [".pdf", ".jpeg", ".png", ".tiff"];

export const DEFAULT_SLIDER_OPTIONS = {
  showSelectionBar: true,
  showTicks: true,
  hideLimitLabels: true,
  hidePointerLabels: true,
};
