import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { SignUpFacade } from './sign-up.facade';
import { BrandingService } from 'common';

@UntilDestroy()
@Component({
  selector: 'ifbp-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [SignUpFacade]
})
export class SignUpComponent implements OnInit {
  form: UntypedFormGroup;
  companyName: string | undefined;
  loading: boolean;
  queryParams: any;

  constructor(
    private facade: SignUpFacade,
    private route: ActivatedRoute,
    private brandingService: BrandingService
  ) {
    this.loading = false;
    this.form = this.facade.initForm();
  }

  public ngOnInit() {
    this.initCompanyName();
    this.setFormFieldsFromQueryParams();
  }

  private initCompanyName() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding) => {
        this.companyName = branding?.dba || branding?.name;
      })
  }

  private setFormFieldsFromQueryParams() {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(this.setQueryParams.bind(this));
  }

  public submit() {
    if (!this.form.valid) return;

    this.loading = true;
    this.facade.signUp(this.form.getRawValue());
    this.loading = false;
  }

  private setQueryParams(queryParams: any) {
    this.queryParams = queryParams;
    this.form.patchValue(queryParams);
  }
}

