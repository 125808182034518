import { Component } from '@angular/core';

@Component({
  selector: 'ifc-google-logo',
  templateUrl: './google-logo.component.html',
  styleUrls: ['./google-logo.component.css']
})
export class GoogleLogoComponent {

  constructor() { }

}
