<span class="owner-information-form-greeting" fxLayoutAlign="center">Hello {{this.brokerUser.firstName}}, tell us about your client</span>
<form class="flex column" [formGroup]="form">
  <div formArrayName="owners" *ngFor="let ownerForm of form.controls.owners['controls']; let ownerIndex = index;" class="owner-form-container">
    <div class="flex column" [formGroup]="ownerForm">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" class="owner-information-form-subheader-container">
        <span class="owner-information-form-subheader-subheading">Owner {{ownerIndex + 1}}</span>
        <button *ngIf="ownerIndex > 0 && !ownerForm.get('isExisting').value" class="remove-owner-button" mat-button type="button" (click)="onClickRemoveOwner(ownerIndex)">
          <mat-icon class="mat-gray">cancel</mat-icon>
          <span>REMOVE</span>
        </button>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName" />
          <mat-error *ngIf="ownerForm.get('firstName').errors | hasControlError:['required']">First name is required.</mat-error>
          <mat-error *ngIf="ownerForm.get('firstName').errors | hasControlError:['maxlength']">First name is too long.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName" />
          <mat-error *ngIf="ownerForm.get('lastName').errors | hasControlError:['required']">Last name is required.</mat-error>
          <mat-error *ngIf="ownerForm.get('lastName').errors | hasControlError:['maxlength']">Last name is too long.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-error *ngIf="ownerForm.get('email').errors | hasControlError:['required']">Email is required.</mat-error>
          <mat-error *ngIf="ownerForm.get('email').errors | hasControlError:['maxlength']">Email is too long.</mat-error>
          <mat-error *ngIf="ownerForm.get('email').errors | hasControlError:['email']">Please enter a valid email.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50">
          <mat-label>Mobile phone number</mat-label>
          <input
            matInput
            formControlName="mobilePhone"
            mask="(000) 000-0000"
          />
          <mat-error *ngIf="ownerForm.get('mobilePhone').errors | hasControlError:['required']">Mobile phone number is required.</mat-error>
          <mat-error *ngIf="ownerForm.get('mobilePhone').errors | hasControlError:['pattern']">Please enter a valid mobile phone number.</mat-error>
        </mat-form-field>
      </div>
      <span class="owner-information-form-subheader">Home address</span>
      <ifbp-address-form [form]="ownerForm.controls.address"></ifbp-address-form>
      <span class="owner-information-form-subheader">Birthdate</span>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
        <div fxFlex.gt-xs="50">
          <ifc-datepicker
            id="date-form-field"
            [fc]="ownerForm.controls.birthdate"
            [min]="minDate"
            [max]="maxDate"
            [maskConfig]="null"
            label="Date of birth"
          ></ifc-datepicker>
        </div>
        <mat-form-field appearance="outline" fxFlex.gt-xs="50">
          <mat-label>Social security number</mat-label>
          <input
            matInput
            formControlName="ssn"
            mask="000-00-0000"
          />
          <mat-error *ngIf="ownerForm.get('ssn').errors | hasControlError:['required']">Social security number is required.</mat-error>
          <mat-error *ngIf="ownerForm.get('ssn').errors | hasControlError:['maxlength', 'minlength']">Please enter a valid social security number.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="form.controls.owners['controls'].length < 4" fxLayout="row wrap" fxLayoutAlign="start start" >
    <button mat-flat-button (click)="onClickAddOwner()"class="add-owner-button">
      <mat-icon class="mat-green">add_circle</mat-icon>
      <span>ANOTHER OWNER</span>
    </button>
  </div>
</form>
