import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import {
  BrokerUserData,
  BrokerDataDetails,
  BrokerUsersQueryResult,
} from "../domain/models/broker.model";
import { BrokerFacade } from "../domain/+state/broker.facade";
import { take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class BrokerCurrentResolver implements Resolve<BrokerUserData> {
  constructor(private facade: BrokerFacade) {}

  resolve(): Observable<BrokerUserData> {
    return this.facade.getCurrentBroker$().pipe(take(1));
  }
}

@Injectable({ providedIn: "root" })
export class BrokerDataDetailsResolver implements Resolve<BrokerDataDetails> {
  constructor(private facade: BrokerFacade) {}

  resolve(): Observable<BrokerDataDetails> {
    return this.facade.getBrokerDataDetails$().pipe(take(1));
  }
}

@Injectable({ providedIn: "root" })
export class BrokerUsersResolver implements Resolve<BrokerUsersQueryResult> {
  constructor(private facade: BrokerFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BrokerUsersQueryResult> {
    return this.facade
      .getBrokerUsers$({ active: true, ...route.queryParams })
      .pipe(take(1));
  }
}

@Injectable({ providedIn: "root" })
export class BrokerUserResolver implements Resolve<BrokerUserData> {
  constructor(private facade: BrokerFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BrokerUserData> {
    return this.facade.getBrokerUser$(route.params.id).pipe(take(1));
  }
}
