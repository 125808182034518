import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoResultsFoundActionData, NoResultsFoundData } from './no-results-found.model';
import { ActionButtonType } from 'projects/partner/src/app/shared/models/action-button/action-button.model';

@Component({
  selector: 'ifbp-no-results-found',
  templateUrl: './no-results-found.component.html',
  styleUrls: ['./no-results-found.component.scss']
})
export class NoResultsFoundComponent {
  @Input()
  data: NoResultsFoundData;

  @Output()
  actionClicked: EventEmitter<NoResultsFoundActionData> = new EventEmitter();

  readonly ButtonType = ActionButtonType;

  onClickAction(data: NoResultsFoundActionData) {
    this.actionClicked.emit(data);
  }
}
