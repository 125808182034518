import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SubmissionService } from "projects/partner/src/app/shared/submission.service";
import { ApplicationData } from "../models/submissions.model";

@Injectable({
  providedIn: "root",
})
export class SubmissionsBridgeService {
  constructor(
    private readonly submissionService: SubmissionService,
  ) { }

  getApplicationData(
    id: number,
  ): Observable<ApplicationData> {
    return this.submissionService.getApplicationData(id) as unknown as Observable<ApplicationData>;
  }
}
