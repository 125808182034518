import { NgModule } from "@angular/core";
import { SubmissionsSharedModule } from "../../shared/submissions-shared.module";
import { SubmissionListComponent } from './submission-list.component';
import { SubmissionListRoutingModule } from "./submission-list-routing.module";
import { SubmissionListFilterDialogComponent } from "./components/submission-list-filter-dialog/submission-list-filter-dialog.component";
import { SubmissionAutocompleteInputComponent } from "./components/account-name-input/submission-autocomplete-input.component";
import { NotInterestedActionModule } from "../not-interested-action/not-interested-action.module";

@NgModule({
  imports: [
    SubmissionsSharedModule,
    SubmissionListRoutingModule,
    NotInterestedActionModule
  ],
  declarations: [
    SubmissionListComponent,
    SubmissionListFilterDialogComponent,
    SubmissionAutocompleteInputComponent
  ],
})
export class SubmissionListModule { }
