import { Injectable } from "@angular/core";
import { EMPTY, Observable, of } from "rxjs";
import { BrokerStateService } from "./broker.state.service";
import {
  BrokerUserData,
  BrokerDataDetails,
  BrokerProfileData,
  BrokerPasswordChangeRequest,
  BrokerUsersQueryResult,
  BrokerEditUserData,
} from "../models/broker.model";
import { BrokerRemote } from "../../infrastructure/broker.remote";
import { catchError, mapTo, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { MessageService } from "common";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class BrokerFacade {
  constructor(
    private readonly stateService: BrokerStateService,
    private readonly remote: BrokerRemote,
    private messageService: MessageService
  ) {}

  getCurrentBroker$(): Observable<BrokerUserData> {
    if (this.stateService.isBrokerStateLoaded()) {
      return this.stateService.getBroker$();
    }
    return this.refreshCurrentBroker$();
  }

  private refreshCurrentBroker$(): Observable<BrokerUserData> {
    return this.remote.getCurrent().pipe(
      tap((broker: BrokerUserData) => this.stateService.setBroker(broker)),
      switchMap(() => this.stateService.getBroker$()),
    );
  }

  getBrokerDataDetails$(): Observable<BrokerDataDetails> {
    if (this.stateService.isBrokerDataDetailsStateLoaded()) {
      return this.stateService.getBrokerDataDetails$();
    }

    return this.refreshBrokerDataDetails$();
  }

  private refreshBrokerDataDetails$(): Observable<BrokerDataDetails> {
    return this.getCurrentBroker$().pipe(
      switchMap((currentBroker: BrokerUserData) => {
        return this.remote.getBrokerDataDetails(currentBroker.brokerId);
      }),
      tap((details: BrokerDataDetails) =>
        this.stateService.setBrokerDataDetails(details),
      ),
      switchMap(() => this.stateService.getBrokerDataDetails$()),
    );
  }

  getBrokerUsers$(params: any): Observable<BrokerUsersQueryResult> {
    return this.getCurrentBroker$().pipe(
      switchMap((currentBroker: BrokerUserData) => {
        return this.remote.getBrokerUsers(currentBroker.brokerId, params);
      }),
    );
  }

  getBrokerUser$(id: number): Observable<BrokerUserData> {
    return this.remote.getBrokerUser(id);
  }

  createBrokerUser(data: BrokerEditUserData): Observable<BrokerUserData> {
    return this.remote.createBrokerUser(data).pipe(
      catchError(error => this.handleCreateBrokerError(error))
    );
  }

  deleteBrokerUser(id: number): Observable<BrokerUserData> {
    return this.remote.deleteBrokerUser(id);
  }

  updateBrokerUser(
    id: number,
    data: BrokerProfileData | BrokerEditUserData,
  ): Observable<void> {
    return this.remote.updateBrokerUser(id, data).pipe(
      mergeMap(() => this.refreshCurrentBroker$().pipe(take(1))),
      mapTo(undefined),
    );
  }

  resendInvitation(brokerUserId: number): Observable<void> {
    return this.remote.resendInvitation(brokerUserId);
  }

  private handleCreateBrokerError(
    response: HttpErrorResponse
  ) : Observable<BrokerUserData> {
    if (response.status === 409) {
      this.messageService.error("A broker memeber with that email already exists.");
      return EMPTY;
    }

    this.messageService.error("Team member cannot be added.");
    return EMPTY;
  }

  updateBrokerPassword(
    id: number,
    data: BrokerPasswordChangeRequest,
  ): Observable<void> {
    return this.remote.updateBrokerPassword(id, data);
  }

  clearBrokerCache(): void {
    return this.stateService.clearState();
  }
}
