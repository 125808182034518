import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { FilterRange, EnumHelper, ObjectHelper } from 'common';
import { SubmissionQueryParams, SubmissionsHintsFieldName } from '../../../../domain/models/submissions.model';
import { BrokerStatus, BrokerStatusLabel } from 'projects/partner/src/app/shared/SharedConstants';
import { adaptSubmissionFilters } from '../../submission-list.data';

@Component({
  selector: 'ifbp-submission-list-filter-dialog',
  templateUrl: './submission-list-filter-dialog.component.html',
  styleUrls: ['./submission-list-filter-dialog.component.scss']
})
export class SubmissionListFilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  filters: SubmissionQueryParams;
  brokerStatusOptions = EnumHelper.getMappedNamesAndValues(BrokerStatus, BrokerStatusLabel);
  readonly FieldName = SubmissionsHintsFieldName;

  constructor(
    public dialogRef: MatDialogRef<SubmissionListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder) {

    this.filters = data.filters;

    this.form = this.formBuilder.group({
      accountName: this.filters.accountName,
      brokerStatus: { value: ObjectHelper.makeArray(this.filters.brokerStatus), disabled: false },
      firstAgent: this.filters.firstAgent,
      assignedAgent: this.filters.assignedAgent,
      createdOn: this.filters.createdOn,
      createdOnFrom: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      createdOnTo: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      updatedOn: this.filters.updatedOn,
      updatedOnFrom: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
      updatedOnTo: new UntypedFormControl(undefined, { validators: null, updateOn: 'blur' }),
    });
  }
  ngOnInit(): void {
    // TODO required "setTimeout" to refresh UI when initializing date, should be fixed in RYNO-10935 - Refactor common "ifc-datepicker" control, currently has many errors
    setTimeout(() => {
      this.form.patchValue({
        createdOnFrom: this.filters.createdOnFrom ? new Date(this.filters.createdOnFrom) : undefined,
        createdOnTo: this.filters.createdOnTo ? new Date(this.filters.createdOnTo) : undefined,
        updatedOnFrom: this.filters.updatedOnFrom ? new Date(this.filters.updatedOnFrom) : undefined,
        updatedOnTo: this.filters.updatedOnTo ? new Date(this.filters.updatedOnTo) : undefined,
      });
    }, 0);
  }

  static show(dialog: MatDialog, filters: SubmissionQueryParams): Observable<any> {
    return dialog
      .open(SubmissionListFilterDialogComponent, {
        data: { filters, },
        closeOnNavigation: true,
      })
      .afterClosed();
  }

  createdOnDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      createdOnFrom: dataRange.from,
      createdOnTo: dataRange.to
    });
  }

  updatedOnDateSelected(dataRange: FilterRange<moment.Moment>) {
    this.form.patchValue({
      updatedOnFrom: dataRange.from,
      updatedOnTo: dataRange.to
    });
  }

  onClickOk() {
    const filters = adaptSubmissionFilters({...this.filters, ...this.form.value });
    this.dialogRef.close(filters);
  }
}
