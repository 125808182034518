// from component in application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/shared/share-offer/send-offer-email
import { CurrencyPipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  ApplicationBaseData,
  ApplicationOffer,
  BrandingService,
  ProductCode,
  ProductCodeLabel,
} from "common";
import { hasValue } from "projects/partner/src/Tools";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-send-offer-email",
  templateUrl: "./send-offer-email.component.html",
  styleUrls: ["./send-offer-email.component.scss"],
})
export class SendOfferEmailComponent implements OnInit {
  form: UntypedFormGroup;
  companyName: string;
  emailBody: string;

  constructor(
    public dialogRef: MatDialogRef<SendOfferEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private currencyPipe: CurrencyPipe,
    private brandingService: BrandingService,
  ) {
    if (hasValue(this.data.application?.contactEmails)) {
      this.form = this.formBuilder.group({
        email: this.data.application?.contactEmails?.join("; ") ?? "",
      });
    }
  }

  ngOnInit(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe(companyData => {
        this.companyName = companyData.dba ?? "";

        if (hasValue(this.data?.emailBody)) {
          this.emailBody = this.data?.emailBody;
        } else {
          this.emailBody =
            `Congratulations! ${this.data?.application.accountName
            } has been approved for a ${this.productCodeLabel(
              this.data?.offer.productCode,
            )}` +
            ` of ${this.currencyPipe.transform(
              this.data?.offer.amount,
              "USD",
              "symbol",
              "1.2-2",
            )} from ${this.companyName
            }. See the offer details here:%0D%0A${encodeURIComponent(
              this.data?.shareUrl,
            )}`;
        }
      });
  }

  productCodeLabel(code: ProductCode): string {
    return ProductCodeLabel.get(code) ?? "";
  }

  static show(
    dialog: MatDialog,
    application: ApplicationBaseData,
    shareUrl: string,
    offer: ApplicationOffer,
    emailBody: string,
  ) {
    dialog.open(SendOfferEmailComponent, {
      width: "560px",
      data: {
        application,
        shareUrl,
        offer,
        emailBody,
      },
      closeOnNavigation: true,
    });
  }
}
