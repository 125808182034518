import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  InitMfaData,
  MessageService,
  MfaApiResponse,
  MfaContextData,
  MfaContextType,
  MfaErrorCodeType,
  MfaErrorType,
  MfaService,
} from "common";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getErrorForMessageService } from "projects/partner/src/Tools";
import { AccountRemote } from "../../infrastructure/account.remote";
import { MfaTokenPayload, ResetPasswordRequest, ResetPasswordResponse } from "../model/account.model";
import { WebPaths } from "projects/partner/src/app/shared/SharedConstants";

@Injectable({
  providedIn: "root",
})
export class AccountResetPasswordService {
  constructor(
    private readonly remote: AccountRemote,
    private readonly mfaService: MfaService,
    private readonly messageService: MessageService,
    private readonly router: Router,
  ) {}

  resetPassword(
    request: ResetPasswordRequest,
    tokenPayload?: MfaTokenPayload,
  ): Observable<ResetPasswordResponse> {
    return this.remote.resetPassword(request, tokenPayload).pipe(
      map(() => {
        this.handleSuccess();
        return { hasError: false };
      }),
      catchError(error => this.handleError(error, request)),
    );
  }

  private handleSuccess() {
    this.router.navigate([WebPaths.ResetPasswordDone]);
  }

  private handleError(
    response: any,
    request: ResetPasswordRequest,
  ): Observable<ResetPasswordResponse> {
    if (response.status === 403 && this.hasMfaError(response?.error?.error)) {
      return of(this.handleMfaErrors(response?.error, request));
    }
    if (response?.error?.Message) {
      this.messageService.error(
        getErrorForMessageService(response?.error?.Message),
      );
    }
    return of({
      hasError: true,
      message: response?.error?.error,
    });
  }

  private hasMfaError(errorType: any) {
    return Object.values(MfaErrorType).includes(errorType);
  }

  private handleMfaErrors(
    mfaResponse: MfaApiResponse,
    request: ResetPasswordRequest,
  ): ResetPasswordResponse {
    switch (mfaResponse.error) {
      case MfaErrorType.MfaRequired:
        return this.handleMfaRequiredError(mfaResponse, request);
      case MfaErrorType.MfaCodeError:
        return this.handleMfaCodeError(mfaResponse);
      default:
        console.error(`Mfa error: ${mfaResponse.error}`);
        return {
          hasError: true,
          message: "Error occurred while trying to set password.",
        };
    }
  }

  private handleMfaRequiredError(
    mfaResponse: MfaApiResponse,
    request: ResetPasswordRequest,
  ): ResetPasswordResponse {
    const contextData: MfaContextData = {
      password: request.newPassword,
      confirmPassword: request.confirmPassword,
      token: request.token,
    };

    const data: InitMfaData = {
      mfaContextType: MfaContextType.ResetPassword,
      mfaResponse,
      contextData,
    };

    this.mfaService.initMfa(data);
    return { hasError: true };
  }

  private handleMfaCodeError(
    mfaResponse: MfaApiResponse,
  ): ResetPasswordResponse {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.InvalidMfaCode
    ) {
      return {
        hasError: true,
        message: mfaResponse?.send_code_status?.error_result?.error,
      };
    }
    return { hasError: false };
  }
}
