import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Uppy, UppyFile } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DEFAULT_FILE_DIALOG_HEIGHT, DEFAULT_FILE_DIALOG_WIDTH, FILE_SIZE_50_MB } from "common";
import {
  defaultUppyDialogConfig,
  DEFAULT_ALLOWED_FILE_TYPES,
} from "./upload-file-dialog-improved.data";
import { hasValue } from "projects/partner/src/Tools";
import { DEFAULT_UPPY_FILE_DIALOG_CLASS } from "../SharedConstants";

export type UploadFileDialogRef = MatDialogRef<UploadFileDialogImprovedComponent, UppyFile[]>;

export interface UploadDialogData {
  maxFileCount: number;
  allowedFileTypes?: string[];
}

@Component({
  selector: "ifbp-upload-file-dialog-improved",
  templateUrl: "./upload-file-dialog-improved.component.html",
  styleUrls: ["./upload-file-dialog-improved.component.scss"],
})
export class UploadFileDialogImprovedComponent implements OnInit {
  files: UppyFile[] = [];
  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;
  allowedFileTypes: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UploadDialogData,
    @Inject(MatDialogRef)
    private dialogRef: UploadFileDialogRef,
  ) {
    if (hasValue(this.data.allowedFileTypes)) {
      this.allowedFileTypes = this.data.allowedFileTypes;
    } else {
      this.allowedFileTypes = DEFAULT_ALLOWED_FILE_TYPES;
    }
  }

  static open(dialog: MatDialog, data: UploadDialogData): MatDialogRef<UploadFileDialogImprovedComponent, UppyFile[]> {
    return dialog.open<UploadFileDialogImprovedComponent, UploadDialogData, UppyFile[]>(UploadFileDialogImprovedComponent,
      {
        data: data,
        height: `${DEFAULT_FILE_DIALOG_HEIGHT}px`,
        width: `${DEFAULT_FILE_DIALOG_WIDTH}px`,
        panelClass: DEFAULT_UPPY_FILE_DIALOG_CLASS,
      }
    );
  }

  ngOnInit() {
    this.initUppyConfig();
  }

  private initUppyConfig() {
    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: this.data.maxFileCount,
        maxFileSize: FILE_SIZE_50_MB,
        allowedFileTypes: this.allowedFileTypes,
      },
      locale: {
        strings: {
          exceedsSize:
            "The uploaded file exceeds the maximum file size (%{size}). Please reduce the size of the file and try again.",
        },
      },
    });
    this.uppyDashboardOptions = {
      ...defaultUppyDialogConfig,
      height: DEFAULT_FILE_DIALOG_HEIGHT - 65, // 65px for footer
      width: DEFAULT_FILE_DIALOG_WIDTH,
    };
  }

  closeDialog() {
    this.dialogRef.close(this.uppy.getFiles());
  }
}
