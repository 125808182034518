<h2 mat-dialog-title>Thanks for your submission</h2>
<mat-dialog-content>
  <p style="max-width: 470px;">
    We are currently reviewing the application and will reach out to 
    you shortly. Contact {{data.brokerDataDetails.advisorFirstName}} 
    at {{data.brokerDataDetails.advisorEmail}} or {{data.brokerDataDetails.advisorPhone | phone}} 
    if you have any questions.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button 
    mat-button 
    mat-dialog-close 
    color="primary"
  >
    OK
  </button>
</mat-dialog-actions>