<ifc-app-card [asideOrderLtLg]="-1" [asideHideXS]="true" [fxLayoutDirection]="'row'" fxLayout="row" fxLayout.gt-md="row"
  fxFlexAlign.lt-lg="center center" class="sign-in-panel">
  <aside class="sign-up">
    <div class="flex column start-start">
      <p class="mat-display-3 title">Let's do this!</p>
      <div class="user-aside-hr"></div>
      <p class="mat-display-1 aside">Realize Your Next <br />Opportunity with <br />{{companyName}}</p>
    </div>
  </aside>
  <div class="flex column start-start sign-up">
    <p class="mat-headline">
      Create an account
    </p>
    <form class="flex column" (ngSubmit)="submit()" [formGroup]="form">
      <input matInput autocomplete="off" required formControlName="registrationToken" hidden>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input id="email" matInput placeholder="Email" autocomplete="email" required formControlName="email" disabled>
        <mat-error *ngIf="form.controls.email.hasError('notUnique'); else emailCommonError">
          {{form.controls.email.getError('notUnique')}}
        </mat-error>
        <ng-template #emailCommonError>
          <mat-error>
            Please enter a valid email address.
          </mat-error>
        </ng-template>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" placeholder="Password" autocomplete="new-password" required formControlName="password">
        <mat-hint>
          Min. of 8 characters, 1 uppercase and 1 lowercase letter, 1 number
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput type="password" placeholder="Confirm your password" autocomplete="new-password" required
          formControlName="confirmPassword">
        <mat-hint>
          Your password and confirmation must match
        </mat-hint>
      </mat-form-field>
      <div class="flex row center-center register-button-row">
        <span class="user-sign-up-form-opt-in-label">
          By registering an account, I agree to be contacted by {{companyName}} via call, email, and text.
          To opt-out, you can reply 'Stop' at any time or click the unsubscribe link in the emails.
          Message and data rates may apply.
        </span>
        <button mat-raised-button color="primary" class="green-button" type="submit"
          [disabled]="loading">Continue</button>
      </div>
      <ifc-ssl-info></ifc-ssl-info>
    </form>
  </div>
</ifc-app-card>
