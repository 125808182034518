import { UserDbRole } from "../../../users/domain/models/users.model";

export interface AccountData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: UserDbRole;
  brokerId: number;
  mobilePhone: string;
}

export interface BrokerData {
  id: number;
  advisorFirstName: string;
  advisorLastName: string;
  advisorName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorCalendarLinkUrl: string;
  advisorAvatar: string;
  name: string;
}

export interface AccountSummary {
  account: AccountData;
  broker: BrokerData;
}

export interface RegistrationTokenStatusData {
  tokenExpirationHours: number;
  status: RegistrationTokenStatus;
}

export enum RegistrationTokenStatus {
  None = "none",
  Active = "active",
  Invalid = "invalid"
}

export interface SingUpRequest {
  email: string;
  registrationToken: string;
  password: string;
  confirmPassword: string;
}

export enum SignUpResponseErrorEnum {
  EmailMismatch = 'email-mismatch',
  AccountStatusInvalid = 'acc-status',
}

export interface SignUpResponseError {
  errorCodes: string[] | SignUpResponseErrorEnum[];
  message: string;
  status: string;
}

export interface SignInRequest extends Partial<SignInMfaRequest> {
  grant_type: string;
  client_id: string;
  username?: string;
  password?: string;
  client_secret?: string;
  code?: any;
}

export interface SignInMfaRequest {
  mfa_token: string;
  mfa_code: string;
  trust_device?: boolean;
}

export interface SignInExtras {
  username: string;
  rememberMe: boolean;
  loginType: string;
}

export interface SignInResponse {
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
}

export interface SignInErrorResponse {
  hasError: boolean;
  errorCode?: string;
}

export enum LoginType {
  Partner = "#$partner",
}

export interface ResetPasswordResponse {
  hasError?: boolean;
  message?: string;
}

export interface ResetPasswordRequest {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

export interface MfaTokenPayload {
  mfaToken?: string;
  mfaCode?: string;
}
