// modified from portal project
// IdeaFinancial.Web/projects/portal/src/guards/oauth.guard.ts
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { OAuthService } from "common";

@Injectable({
  providedIn: "root",
})
export class OAuthGuard implements CanActivate {
  constructor(private oauthService: OAuthService, private router: Router) {}

  async canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<UrlTree | boolean> {
    if (!this.oauthService.authenticated) {
      return this.oauthService
        .refreshAuthToken()
        .toPromise()
        .then(res => {
          if (!res?.access_token) {
            this.saveHistory();
            return false;
          }

          this.oauthService.sessionBegin(
            sessionStorage.loginType,
            res?.access_token,
            res?.expires_in,
            res?.refresh_token,
            res?.refresh_token_expires_in,
          );

          return true;
        })
        .catch(err => {
          this.saveHistory();
          return false;
        });
    } else {
      this.oauthService.restartIdleTimer();
      return this.oauthService.authenticated;
    }
  }

  private saveHistory() {
    if (window.location.pathname !== "/mfa") {
      const params = new URLSearchParams(window.location.search);
      params.delete("sessionId");
      sessionStorage.setItem("navigation.history", window.location.pathname);
      sessionStorage.setItem(
        "navigation.historyQuery",
        `${window.location.pathname}?${params.toString()}`,
      );
    }
  }
}
