import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { WebPaths } from "../../../shared/SharedConstants";
import { UsersBridgeService } from "../domain/+state/users-bridge.service";

@Injectable({
  providedIn: "root",
})
export class UserDetailsGuard implements CanActivate {
  constructor(
    private bridgeService: UsersBridgeService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.bridgeService.getCurrentBroker$().pipe(
      take(1),
      map(brokerUser => {
        if (brokerUser.id.toString() !== route.paramMap.get("id")) {
          return true;
        }
        return this.router.createUrlTree([WebPaths.Team]);
      }),
    );
  }
}
