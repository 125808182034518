import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppBarComponent, AppPageComponent, AppSidenavComponent } from "common";
import { appBarData, appBarDropdownData } from "projects/partner/src/app/app.data";
import { AppPageResolver, AppSidenavItemsResolver } from "projects/partner/src/app/app.resolver";
import { AppBarDropdownComponent } from "projects/partner/src/app/shared/app-bar-dropdown/app-bar-dropdown.component";
import { SubmissionListComponent } from "./submission-list.component";

const routes: Routes = [
  {
    path: "submissions",
    title: "Submissions – Idea Financial Partner",
    component: AppPageComponent,
    data: {
      appBar: appBarData(),
      appBarDropdown: appBarDropdownData(),
    },
    resolve: {
      appPage: AppPageResolver,
      appSidenav: AppSidenavItemsResolver,
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarDropdownComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "sidenav-content",
        component: AppSidenavComponent,
      },
      {
        path: "",
        component: SubmissionListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubmissionListRoutingModule { }
