import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  AppBarAction,
  ApplicationStage,
  PHONE_VALIDATOR_PATTERN,
  ValidatorProvider,
  ZIP_VALIDATOR_PATTERN,
} from "common";
import { hasValueFn } from "../../Tools";
import {
  BusinessInformationGetData,
  OwnerData,
} from "./models/submission.model";

export const businessInformationIndex = 0;
export const documentsAndCashFlowIndex = 2;
export const ownerInformationIndex = 1;

export const businessInformationAppBarActions: AppBarAction[] = [
  {
    id: "save",
    label: "SAVE",
    buttonType: "button",
  },
  {
    id: "next",
    label: "NEXT",
    buttonType: "button",
    buttonAppearance: "flat",
    buttonColor: "primary",
  },
];

export const ownerInformationAppBarActions: AppBarAction[] = [
  {
    id: "back",
    label: "BACK",
    buttonType: "button",
  },
  {
    id: "save",
    label: "SAVE",
    buttonType: "button",
  },
  {
    id: "next",
    label: "NEXT",
    buttonType: "button",
    buttonAppearance: "flat",
    buttonColor: "primary",
  },
];

export const documentsAndCashFlowFilesAppBarActions: AppBarAction[] = [
  {
    id: "back",
    label: "BACK",
    buttonType: "button",
  },
  {
    id: "save",
    label: "SAVE",
    buttonType: "button",
  },
  {
    id: "submit",
    label: "SUBMIT",
    buttonType: "button",
    buttonAppearance: "flat",
    buttonColor: "primary",
  },
];

const getAddressForm = (
  initialValues?: OwnerData | BusinessInformationGetData,
) => {
  return {
    line1: new FormControl(initialValues?.address.line1 ?? "", {
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(128),
      ],
    }),
    line2: new FormControl(initialValues?.address.line2 ?? "", {
      validators: Validators.maxLength(16),
    }),
    city: new FormControl(initialValues?.address.city ?? "", {
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
      ],
    }),
    state: new FormControl(initialValues?.address.state ?? "", {
      validators: [
        Validators.required,
      ]
    }),
    zip: new FormControl(initialValues?.address.zip ?? "", {
      validators: [
        Validators.required,
        Validators.pattern(ZIP_VALIDATOR_PATTERN),
      ],
    }),
  };
};

const getBusinessInformationRequiredToSave = (
  initialValues?: BusinessInformationGetData,
) => {
  return {
    name: new FormControl(initialValues?.name ?? "", {
      validators: [Validators.required, Validators.maxLength(256)],
    }),
  };
};

export const getBusinessInformationForm = (
  initialValues?: BusinessInformationGetData,
) => {
  return {
    requiredToSave: new FormGroup(
      getBusinessInformationRequiredToSave(initialValues),
    ),
    ein: new FormControl(initialValues?.ein ?? "", {
      validators: [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ],
    }),
    dba: new FormControl(initialValues?.dba ?? "", {
      validators: Validators.maxLength(120),
    }),
    entityType: new FormControl(initialValues?.entityType ?? "", {
      validators: [Validators.required]
    }),
    phone: new FormControl(initialValues?.phone ?? "", {
      validators: [
        Validators.required,
        Validators.pattern(PHONE_VALIDATOR_PATTERN),
      ],
    }),
    address: new FormGroup(
      getAddressForm(initialValues),
    ),
    timeInBusiness: new FormControl(initialValues?.timeInBusiness ?? "", {
      validators: [
        Validators.required,
      ]
    }),
    description: new FormControl(initialValues?.description ?? "", {
      validators: [Validators.required, Validators.maxLength(32_000)],
    }),
    monthlySales: new FormControl(initialValues?.monthlySales ?? "", {
      validators: Validators.max(9_999_999_999.99),
    }),
    requestedAmount: new FormControl(initialValues?.requestedAmount ?? "", {
      validators: Validators.max(9_999_999_999.99),
    }),
  };
};

export const getOwnerInformationForm = () => {
  return {
    owners: new FormArray([]),
  };
};

export const getOwnerForm = (
  initialValues?: OwnerData,
) => {
  return {
    firstName: new FormControl(initialValues?.firstName ?? "", {
      validators: [Validators.required, Validators.maxLength(40)],
    }),
    lastName: new FormControl(initialValues?.lastName ?? "", {
      validators: [Validators.required, Validators.maxLength(80)],
    }),
    email: new FormControl(initialValues?.email ?? "", {
      validators: [Validators.required, ...ValidatorProvider.salesforceEmailValidators()],
    }),
    ssn: new FormControl(initialValues?.ssn ?? "", {
      validators: [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ],
    }),
    mobilePhone: new FormControl(initialValues?.mobilePhone ?? "", {
      validators: [
        Validators.required,
        Validators.pattern(PHONE_VALIDATOR_PATTERN),
      ],
    }),
    address: new FormGroup(
      getAddressForm(initialValues),
    ),
    birthdate: new FormControl(
      hasValueFn(initialValues?.birthdate, v => new Date(v)) ?? undefined,
      {
        validators: [Validators.required], updateOn: 'blur'
      },
    ),
    isExisting: new FormControl(initialValues?.isExisting ?? false),
    id: new FormControl(initialValues?.id ?? null),
  };
};

export const getDocumentsAndCashFlowForm = () => {
  return {
    applicationStage: new FormControl<ApplicationStage>(ApplicationStage.New),
    bankStatementFiles: new FormControl([], [Validators.required]),
    signedApplicationFiles: new FormControl([]),
    fileIdsToDelete: new FormControl([]),
  };
}
