<div class="wrapper">
  <div class="card">
    <ifc-avatar-icon class="card__avatar"
                     [data]="data?.avatar"></ifc-avatar-icon>
    <div class="content">
      <div class="title">
        <span class="title__name">{{data?.name}}</span>
        <span class="title__description">Business Advisor</span>
      </div>
      <div class="content__contacts">
        <div (click)="$event.stopPropagation()">
          <a href="mailto:{{data?.email}}">{{data?.email}}</a>
        </div>
        <span class="secondary-text">{{ data?.phone | phone }}</span>
      </div>
    </div>
  </div>
  <button mat-stroked-button
          class="action"
          (click)="openCalendarUrl()">
    <div class="action__body">
      <mat-icon>phone_in_talk</mat-icon>
      <span>SCHEDULE A CALL</span>
    </div>
  </button>
</div>
