import { NgModule } from "@angular/core";
import { SharedModule } from "projects/partner/src/app/shared/shared.module";
import { SignUpRoutingModule } from "./sign-up-routing.module";
import { SignUpComponent } from "./sign-up.component";

@NgModule({
  imports: [SharedModule, SignUpRoutingModule],
  declarations: [SignUpComponent],
})
export class SignUpModule { }
