import { Injectable } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { BrandingService, TimeHelper, defaultCalendarLinkUrl } from "common";
import { DashboardBridgeService } from "./dashboard-bridge.service";
import moment from "moment";
import { map } from "rxjs/operators";
import { BrokerUserData } from "projects/partner/src/app/broker/domain/models/broker.model";
import { AdvisorWidgetData, DashboardWidgetsData } from "../model/dashboard.model";
import { DashboardRemote } from "../../infrastructure/dashboard.remote";

@Injectable({
  providedIn: "root",
})
export class DashboardFacade {
  constructor(
    private readonly bridgeService: DashboardBridgeService,
    private readonly remote: DashboardRemote,
    private readonly brandingService: BrandingService,
  ) { }

  getCustomerGreeting$(): Observable<string> {
    return this.bridgeService
      .getCurrentBroker$()
      .pipe(map((userData: BrokerUserData) =>
        `${TimeHelper.getGreetingTime(moment())}, ${userData?.firstName ?? userData?.lastName}!`
      ));
  }

  getAdvisorData$(): Observable<AdvisorWidgetData> {
    return combineLatest([this.bridgeService.getBrokerDataDetails$(), this.brandingService.getBranding$()])
      .pipe(map(([brokerData, brandingData]) => {
        const useIndividual = brandingData?.configuration?.useIndividualAdvisorPhoneNumbers;

        return {
          avatar: brokerData?.advisorAvatar,
          name: brokerData?.advisorName,
          email: brokerData?.advisorEmail,
          phone: useIndividual ? brokerData?.advisorPhone : brandingData?.phone ?? undefined,
          calendarUrl: brokerData?.advisorCalendarLinkUrl ?? defaultCalendarLinkUrl
        }
      }))
  }

  getDashboardWidgets(onlyActive: boolean = false): Observable<DashboardWidgetsData> {
    return this.remote.getDashboardWidgets().pipe(map(data => {
      if (onlyActive) {
        return {
          mainWidgets: data?.mainWidgets?.filter(widget => widget.active),
          asideWidgets: data?.asideWidgets?.filter(widget => widget.active),
        }
      }

      return data;
    }))
  }
}
