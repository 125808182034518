// based on application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/calculator/offer-calculator.ts
import {
  ApplicationBaseData,
  ApplicationOffer,
  FeeType,
  PaymentFrequency,
  ProductCodeLabel,
} from "common";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { CalculatorService } from "./calculator.service";
import { LoanCard } from "./loan-card";

export abstract class OfferCalculator {
  private _amount: number;
  protected _sampleDraw: number;
  sampleDurationIndex = 0;
  brokerInitialDrawCommision = 0;

  constructor(
    public application: ApplicationBaseData,
    public applicationOffer: ApplicationOffer,
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe,
    protected _calculatorService: CalculatorService,
  ) {
    this.amount = applicationOffer.amount;
    this.initSampleDraw();
    this.sampleDurationIndex = this.cards.length - 1;
    this.brokerInitialDrawCommision = (application.brokerInitialDrawCommision as number / 100) ?? 0;
  }

  get productName(): string {
    return ProductCodeLabel.get(this.applicationOffer.productCode);
  }

  get rate(): number {
    return this.applicationOffer.interestRate;
  }

  get annualRate(): number {
    return this.getAnnualRate();
  }

  protected abstract getAnnualRate(): number;

  get monthlyRate(): number {
    return this.getMonthlyRate();
  }

  protected abstract getMonthlyRate(): number;

  get term(): number {
    return this.applicationOffer.repaymentTerm;
  }

  get fee(): number {
    return this.applicationOffer.drawDownFee;
  }

  get feeType(): FeeType {
    return this.applicationOffer.drawDownFeeType;
  }

  get amount() {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
    if (this._sampleDraw > this._amount) {
      this._sampleDraw = this._amount;
    }
  }

  get sampleDraw(): number {
    return this._sampleDraw;
  }

  set sampleDraw(value: number) {
    if (value < 5000) {
      this._sampleDraw = 5000;
    } else if (value > this.amount) {
      this._sampleDraw = this.amount;
    } else {
      this._sampleDraw = value;
    }
  }

  protected abstract initSampleDraw(): void;

  get drawFeeFormatted(): string {
    return this.formatFee(this.fee, this.feeType);
  }

  protected formatFee(fee?: number, feeType?: FeeType): string {
    return feeType === FeeType.Percentage
      ? this.percentPipe.transform(fee / 100, "1.2-2")
      : this.currencyPipe.transform(fee, "USD", "symbol", "1.2-2");
  }

  get drawFee(): number {
    return this._calculatorService.calculateFeeAmount(
      this.sampleDraw,
      this.fee,
      this.feeType,
    );
  }

  get disbursedAmount(): number {
    return this.sampleDraw - this.drawFee;
  }

  get originationFee(): number {
    return this.applicationOffer.originationFee;
  }

  get originationFeeType(): FeeType {
    return this.applicationOffer.originationFeeType;
  }

  get originationFeeAmount(): number {
    return this._calculatorService.calculateFeeAmount(
      this.amount,
      this.originationFee,
      this.originationFeeType,
    );
  }

  get uccFilingFee(): number {
    return this.applicationOffer.uccFilingFee;
  }

  get uccFilingFeeType(): FeeType {
    return this.applicationOffer.uccFilingFeeType;
  }

  get uccFilingFeeAmount(): number {
    return this._calculatorService.calculateFeeAmount(
      this.amount,
      this.uccFilingFee,
      this.uccFilingFeeType,
    );
  }

  get termPayment(): number {
    return this.getTermPayment();
  }

  protected abstract getTermPayment(): number;

  get paymentFrequency(): PaymentFrequency {
    return this.applicationOffer.paymentFrequency;
  }

  get totalInterest(): number {
    return this.getTotalInterest(this.term);
  }

  protected abstract getTotalInterest(term: number): number;

  get totalNumberOfPayments(): number {
    return this._calculatorService.getTotalNumberOfPayments(
      this.paymentFrequency,
      this.term,
    );
  }

  get numberOfPaymentsPerAnnum(): number {
    return this._calculatorService.getNumberOfPaymentsPerAnnum(
      this.paymentFrequency,
    );
  }

  get totalPayback(): number {
    return this.amount + this.totalInterest;
  }

  get cards(): LoanCard[] {
    const cards = [];
    for (let cardTerm = 1; cardTerm <= this.term; cardTerm++) {
      const interest = this.getTotalInterest(cardTerm);

      cards.push({
        duration: cardTerm,
        interest,
        payback: this.sampleDraw + interest,
        rate: interest / this.sampleDraw / this.term,
      });

      if (cardTerm >= this.term) {
        break;
      }
    }

    return cards;
  }

  get sampleDuration(): LoanCard {
    return this.cards[this.sampleDurationIndex];
  }

  get sampleDurationDisplay(): string {
    if (this.sampleDuration.duration === 1) {
      return "1 month";
    }

    return `${this.sampleDuration.duration} months`;
  }

  get uiFee(): number {
    return (
      this.fee * (this.feeType === FeeType.Percentage ? this.amount / 100 : 1)
    );
  }

  get dailyInterestRate(): number {
    return this._calculatorService.getDailyInterestRate(this.rate / 100);
}
}
