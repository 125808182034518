<button mat-button [matMenuTriggerFor]="menu">
  <mat-icon>{{ this.data?.appBarDropdown?.menuIcon ?? "" }}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <mat-nav-list>
    <span *ngFor="let menuItem of this.menuItems">
      <a
        mat-menu-item
        [routerLink]="menuItem.url"
        (click)="onClickAppBarMenuItem(menuItem)"
      >
        <mat-icon mat-list-icon *ngIf="menuItem.icon">{{
          menuItem.icon
        }}</mat-icon>
        <span>{{ menuItem.label }}</span>
      </a>
    </span>
  </mat-nav-list>
</mat-menu>
