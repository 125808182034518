import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import { SubmissionService } from "../shared/submission.service";
import { ActivatedRoute } from "@angular/router";
import { hasValue } from "../../Tools";
import {
  AppBarTitleService,
  ApplicationStage,
  AppPageService,
  EntityTypeLabel,
  TimeInBusinessPeriod,
  TimeInBusinessPeriodLabel,
} from "common";
import {
  submissionStageUiMap,
} from "../shared/SharedConstants";
import {
  ApplicationFile,
  ApplicationFileCategory,
  BusinessInformationGetData,
  OwnerData,
} from "../submission/models/submission.model";
import { MatDialog } from "@angular/material/dialog";
import { DeclinedSubmissionDialogComponent } from "./declined-submission-dialog/declined-submission-dialog.component";
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from "../feature-modules/submissions/features/not-interested-action/not-interested-action.model";

@UntilDestroy()
@Component({
  selector: "ifbp-processing-details",
  templateUrl: "./processing-details.component.html",
  styleUrls: ["./processing-details.component.scss"],
})
export class ProcessingDetailsComponent implements OnInit {
  applicationId: number;
  businessInformation: BusinessInformationGetData;
  declinedReason: string | undefined;
  ownerInformation: OwnerData[];
  bankStatementFiles: ApplicationFile[] = [];
  signedApplicationFiles: ApplicationFile[] = [];
  notInterestedActionData: NotInterestedActionInputData;

  readonly EntityTypeLabel = EntityTypeLabel;
  readonly TimeInBusinessPeriod = TimeInBusinessPeriod;
  readonly TimeInBusinessPeriodLabel = TimeInBusinessPeriodLabel;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private route: ActivatedRoute,
    private submissionFormService: SubmissionService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initRouteData();
  }

  private initRouteData() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = params.get("id");
      if (hasValue(applicationId)) {
        this.applicationId = parseInt(applicationId);
        this.initBusinessInformationData();
        this.submissionFormService
          .getOwnerInformationData(this.applicationId)
          .subscribe(ownerInformationData => {
            this.ownerInformation = ownerInformationData.values;
          });
        this.submissionFormService
          .getDocumentsAndCashFlowData(this.applicationId)
          .subscribe(documentsAndCashFlowData => {
            for (const document of documentsAndCashFlowData.values) {
              if (
                document.category === ApplicationFileCategory.SignedApplication
              ) {
                this.signedApplicationFiles.push(document);
              } else if (
                document.category === ApplicationFileCategory.BankStatement
              ) {
                this.bankStatementFiles.push(document);
              }
            }
          });
      }
    });
  }

  private initBusinessInformationData() {
    this.submissionFormService
      .getBusinessInformationData(this.applicationId)
      .subscribe(businessInformationData => {
        this.businessInformation = businessInformationData;
        this.notInterestedActionData = this.initNotInterestedActionData(businessInformationData);
        this.appBarTitleService.title = `${businessInformationData.name
          } - ${submissionStageUiMap.get(businessInformationData.stage)}`;
        if (businessInformationData.stage === ApplicationStage.Declined) {
          if (!hasValue(businessInformationData.declinedReason)) {
            console.warn(
              `Application is in the declined stage but does not have a declined reason.`,
            );
          }
          this.declinedReason = businessInformationData.declinedReason;
          this.openDeclinedSubmissionDialog();
        }
      });
  }

  private initNotInterestedActionData(data: BusinessInformationGetData) {
    return {
      applicationId: this.applicationId,
      brokerStatus: data.brokerStatus,
      createOnDate: new Date(data.createdOn),
      previousStage: <ApplicationStage>data?.previousStage
    }
  }

  private openDeclinedSubmissionDialog() {
    this.dialog.open(DeclinedSubmissionDialogComponent, {
      data: { declinedReason: this.declinedReason },
    });
  }

  onFileNameClick(file: ApplicationFile) {
    this.submissionFormService
      .getDocumentsAndCashFlowFile(this.applicationId, file.id)
      .subscribe(data => {
        this.downloadFile(file, data);
      });
  }

  downloadFile(file: ApplicationFile, data: Blob) {
    // from https://stackoverflow.com/a/52165162
    const blob = new Blob([data], { type: file.mimeType });
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadURL;
    link.download = file.name;
    link.click();
    link.remove();
  }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    if (data.isSuccess) {
      this.appPageService.back();
    }
  }

  onClickBack() {
    this.appPageService.back();
  }
}
