import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from 'common';
import { DictionariesState } from '../model/dictionaries.state';
import { LostReason } from '../model/dictionaries.model';

const initialState: DictionariesState = null;

@Injectable({
  providedIn: "root",
})
export class DictionariesStateService extends StateService<DictionariesState> {
    constructor() {
    super(initialState);
  }

  getLostReasons$(): Observable<LostReason[]> {
    return this.select((state: DictionariesState) => state?.lostReasons);
  }

  setLostReasons(lostReasons: LostReason[]): void {
    const newState = {
      ...this.state,
      lostReasons: lostReasons
    };
    this.setState(newState);
  }

  isLostReasonsStateLoaded(): boolean {
    return !!this.state?.lostReasons;
  }

  clearState(): void {
    this.initState(initialState);
  }
}