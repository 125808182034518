import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RegistrationTokenStatusData, SingUpRequest } from "../../domain/model/account.model";
import { AccountFacade } from "../../domain/+state/account.facade";
import { MessageService } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SignUpForm } from "./sign-up.model";
import { FormGroup } from "@angular/forms";
import { getSignUpFormGroup } from "./sign-up.data";

@UntilDestroy()
@Injectable()
export class SignUpFacade {

  constructor(
    private readonly accountFacade: AccountFacade,
    private readonly messageService: MessageService,
  ) { }

  getRegistrationTokenStatus(email: string, token: string): Observable<RegistrationTokenStatusData> {
    return this.accountFacade.getRegistrationTokenStatus(email, token);
  }

  signUp(form: any): void {
    this.accountFacade
    .signUp(form)
    .pipe(untilDestroyed(this))
    .subscribe({
      next: () => this.signUpSuccess(form),
      error: this.signUpError.bind(this),
    });
  }

  private signUpError(response: any) {
      this.messageService.error(response);
  }

  private signUpSuccess(request: SingUpRequest) {
    this.accountFacade
      .signInByPassword(request.email, request.password, false)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  public initForm(): FormGroup<SignUpForm> {
    const form = getSignUpFormGroup();
    form.controls.email.disable();
    return form;
  }
}
