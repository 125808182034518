<h2 mat-dialog-title>Offer selection submitted</h2>
<mat-dialog-content>
  <p class="secondary-text">
    We are currently reviewing the Closing Documents and will
    reach out to you shortly. Please contact {{data.advisorFirstName}}
    at {{data.advisorEmail}} or {{data.advisorPhone | phone}} 
    if you have any questions.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button 
    mat-button 
    mat-dialog-close 
    color="primary"
  >
    OK
  </button>
</mat-dialog-actions>