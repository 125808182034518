import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { ClosedDetailsRoutingModule } from "./closed-details-routing.module";
import { ClosedDetailsComponent } from "./closed-details.component";

@NgModule({
  imports: [CommonModule, ClosedDetailsRoutingModule, SharedModule],
  declarations: [ClosedDetailsComponent],
})
export class ClosedDetailsModule {}
