import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { DeclinedSubmissionDialogComponent } from "./declined-submission-dialog/declined-submission-dialog.component";
import { ProcessingDetailsRoutingModule } from "./processing-details-routing.module";
import { ProcessingDetailsComponent } from "./processing-details.component";
import { SubmissionsModule } from "../feature-modules/submissions/submissions.module";

@NgModule({
  imports: [CommonModule, ProcessingDetailsRoutingModule, SharedModule, SubmissionsModule],
  declarations: [ProcessingDetailsComponent, DeclinedSubmissionDialogComponent],
})
export class ProcessingDetailsModule {}
