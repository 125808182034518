<div fxLayout="column"
     fxLayoutAlign="start center"
     class="processing-details-container">
  <mat-card *ngIf="declinedReason"
            class="declined-reason-card mat-elevation-z">
    <div class="header">Declined reason:</div>
    {{declinedReason}}
  </mat-card>
  <div class="section-wrapper"
       fxLayout="column">
    <span class="subheading">Business information</span>
    <div fxLayout="row"
         fxLayoutAlign="end end"
         fxLayout.lt-sm="column"
         fxLayoutGap.gt-xs="48px"
         fxFlex.gt-xs="100">
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Company name</div>
          <div>{{businessInformation?.name}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">DBA</div>
          <div>{{businessInformation?.dba}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end end"
         fxLayout.lt-sm="column"
         fxLayoutGap.gt-xs="48px"
         fxFlex.gt-xs="100">
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Ownership</div>
          <div>{{EntityTypeLabel.get(businessInformation?.entityType)}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Primary phone number</div>
          <div>{{businessInformation?.phone | phone}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end end"
         fxLayout.lt-sm="column"
         fxLayoutGap.gt-xs="48px"
         fxFlex.gt-xs="100">
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Business address</div>
          <div>{{businessInformation?.address | address}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">EIN</div>
          <div>{{businessInformation?.ein}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end end"
         fxLayout.lt-sm="column"
         fxLayoutGap.gt-xs="48px"
         fxFlex.gt-xs="100">
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Years in business</div>
          <div>{{businessInformation?.timeInBusiness | enumValueLabel:TimeInBusinessPeriod:TimeInBusinessPeriodLabel}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Business description</div>
          <div>{{businessInformation?.description}}</div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end end"
         fxLayout.lt-sm="column"
         fxLayoutGap.gt-xs="48px"
         fxFlex.gt-xs="100">
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Monthly sales</div>
          <div>{{businessInformation?.monthlySales | currency}}</div>
        </div>
      </div>
      <div fxLayout="column"
           fxFlex.gt-xs="50"
           fxFill>
        <div class="row-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="12px">
          <div class="row-title">Requested amount</div>
          <div>{{businessInformation?.requestedAmount | currency}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper"
       fxLayout="column">
    <span class="subheading">Owner information</span>
    <ng-container *ngFor="let owner of ownerInformation; let ownerIndex = index;">
      <span class="owner-subheading">Owner {{ownerIndex + 1}}</span>
      <div fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Name</div>
            <div>{{owner.firstName}} {{owner.lastName}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Email</div>
            <div>{{owner.email}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Mobile phone number</div>
            <div>{{owner.mobilePhone | phone}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Home address</div>
            <div>{{owner.address | address}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Birthdate</div>
            <div>{{owner.birthdate | date : "MMMM d, YYYY"}}</div>
          </div>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50">
        </div>
      </div>
    </ng-container>
  </div>
  <div class="section-wrapper documents-section-wrapper"
       fxLayout="column">
    <span class="subheading">Documents and cash flow</span>
    <div fxLayout="row"
         fxLayoutGap="12px">
      <div class="files-info-column"
           fxLayout="column"
           fxLayoutGap="6px">
        <span class="files-info-subheading">Last 3 months bank statement (Required)</span>
        <span class="mat-gray">Upload any PDF, JPEG, PNG copies</span>
        <div *ngFor="let bankStatementFile of bankStatementFiles"
             class="flex row"
             fxLayoutAlign="start center">
          <div (click)="onFileNameClick(bankStatementFile)"
               class="filename">{{ bankStatementFile.name }}</div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="section-wrapper"
         fxLayout="row"
         fxLayoutGap="12px">
      <div class="files-info-column"
           fxLayout="column"
           fxLayoutGap="6px">
        <span class="files-info-subheading">Signed application</span>
        <span class="mat-gray">Upload any PDF, JPEG, PNG copies</span>
        <div *ngFor="let signedApplicationFile of signedApplicationFiles"
             class="flex row"
             fxLayoutAlign="start center">
          <div (click)="onFileNameClick(signedApplicationFile)"
               class="filename">{{ signedApplicationFile.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-actions-container"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <ifbp-not-interested-action [data]="notInterestedActionData"
                                (actionComplete)="onNotInterestedActionComplete($event)">
    </ifbp-not-interested-action>
    <button mat-button
            (click)="onClickBack()">BACK</button>
  </div>
</div>
