import { Injectable } from "@angular/core";
import { MessageService, OAuthService, SignInActionsService, SignInComponentInputData, SignInFormData } from "common";
import { CookieService } from 'ngx-cookie-service';
import { EMPTY } from "rxjs";
import { AccountFacade } from "../../domain/+state/account.facade";
import { catchError, finalize } from "rxjs/operators";

@Injectable()
export class SignInFacade {
  constructor(
    private readonly cookieService: CookieService,
    private readonly accountFacade: AccountFacade,
    private readonly messageService: MessageService,
    private readonly oauthService: OAuthService,
    private readonly actionsService: SignInActionsService,
  ) { }

  initData(): SignInComponentInputData {
    const hasVisited = this.cookieService.check("hasVisitedPortalLogin");
    const title = hasVisited ? "Welcome back!" : "Partner Portal";
    const description = hasVisited ? "We're delighted <br />to see you again" : "We're delighted <br />to see you";

    if (!hasVisited) {
      this.cookieService.set("hasVisitedPortalLogin", "");
    }

    return { title, description };
  }

  signInByPassword(data: SignInFormData): void {
    this.oauthService.sessionEndLocal();
    this.accountFacade.signInByPassword(data.email, data.password, data.rememberMe)
      .pipe(finalize(() => this.actionsService.markSignInAsCompleted()))
      .subscribe();
  }

  forgotPassword(email: string): void {
    this.accountFacade.forgotPassword(email).pipe(
      catchError(() => this.forgotPasswordError()))
      .subscribe(() => this.actionsService.handleForgotPasswordSuccess(email));
  }

  private forgotPasswordError() {
    this.messageService.error("Error occurred while trying to reset password.");
    return EMPTY;
  }
}
