<div fxLayoutAlign="center start" class="profile-container">
  <form fxLayout="column" class="profile-form" (ngSubmit)="submit()" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="John" required formControlName="firstName" />
      <mat-error *ngIf="form.get('firstName')?.errors | hasControlError:['required']"> Please enter a first name. </mat-error>
      <mat-error *ngIf="form.get('firstName')?.errors | hasControlError:['maxlength']"> First name is too long. </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="Smith" required formControlName="lastName" />
      <mat-error *ngIf="form.get('lastName')?.errors | hasControlError:['required']"> Please enter a last name. </mat-error>
      <mat-error *ngIf="form.get('lastName')?.errors | hasControlError:['maxlength']"> Last name is too long. </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Mobile Phone Number</mat-label>
      <input
        matInput
        placeholder="(123) 123-1234"
        required
        formControlName="mobilePhone"
        mask="(000) 000-0000"
      />
      <mat-error>Please enter a phone number.</mat-error>
    </mat-form-field>
    <div
      fxLayout="row"
      fxLayoutAlign="flex-end center"
      class="profile-button-row center-center layout margin-top"
    >
      <button
        mat-flat-button
        type="button"
        class="profile-button"
        (click)="cancel()"
      >
        CANCEL
      </button>
      <button
        mat-flat-button
        color="primary"
        class="profile-button"
        type="submit"
        [disabled]="actionInProgress"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
