<ifc-app-scroll-container>
  <ng-container *ngIf="data$ | async as data">
    <div class="wrapper">
      <ng-container *ngIf="true">
        <header class="header">
          <h1 class="mat-headline">{{data.greetings}}</h1>
          <button mat-flat-button
                  color="primary"
                  [routerLink]="WebPaths.NewSubmission"
                  class="new-submission-action">
            + NEW SUBMISSION
          </button>
        </header>
        <section class="content">
          <article class="content__main">
            <ng-container *ngFor="let widgetData of data?.widgetsData?.mainWidgets">
              <ifbp-dashboard-widget [data]="widgetData">
              </ifbp-dashboard-widget>
            </ng-container>
          </article>
          <mat-divider class="content__divider"
                       vertical>
          </mat-divider>
          <article class="content__aside">
            <ng-container *ngFor="let widgetData of data?.widgetsData?.asideWidgets">
              <ifbp-dashboard-widget [data]="widgetData">
              </ifbp-dashboard-widget>
            </ng-container>
            <ifbp-advisor-widget-new class="advisor"
                                     [data]="data.advisorData">
            </ifbp-advisor-widget-new>
          </article>
        </section>
      </ng-container>
    </div>
  </ng-container>
</ifc-app-scroll-container>
