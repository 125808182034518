import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
  getUppyInputFromFile,
  checkDeepEmpty,
  convertToExistingFile,
} from "projects/partner/src/Tools";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { defaultIfEmpty, map, mergeMap } from "rxjs/operators";
import { SubmissionService } from "../../shared/submission.service";
import {
  ApplicationFileCategory,
  ApplicationResponse,
  BusinessInformationFormData,
  BusinessInformationGetData,
  ErrorResponse,
  UppyInput,
  ExistingSubmissionData,
  OwnerInformationGetData,
  SubmissionResponseData,
  OwnerData,
} from "../models/submission.model";
import { UppyFile } from "@uppy/core";
import { AppBarAction } from "common";
import {
  businessInformationAppBarActions,
  businessInformationIndex,
  documentsAndCashFlowFilesAppBarActions,
  documentsAndCashFlowIndex,
  ownerInformationAppBarActions,
  ownerInformationIndex,
} from "../submission.data";

@UntilDestroy()
@Injectable()
export class SubmissionFormFacade {
  constructor(private submissionService: SubmissionService) { }

  initData(applicationId: number): Observable<ExistingSubmissionData> {
    return combineLatest([
      this.submissionService.getBusinessInformationData(applicationId),
      this.submissionService.getOwnerInformationData(applicationId),
      this.submissionService.getDocumentsAndCashFlowData(applicationId),
    ])
      .pipe(
        mergeMap(
          ([
            businessInformationData,
            ownerInformationData,
            documentsAndCashFlowData,
          ]) => {
            const data = {
              businessInformationData: of(businessInformationData),
              ownerInformationData: of(ownerInformationData),
              documentsAndCashFlowData: of(documentsAndCashFlowData),
            };
            for (const applicationFile of documentsAndCashFlowData.values) {
              data[applicationFile.id] =
                this.submissionService.getDocumentsAndCashFlowFile(
                  applicationId,
                  applicationFile.id,
                );
            }
            return forkJoin(data);
          },
        ),
      )
      .pipe(
        map((data: SubmissionResponseData) => {
          const { accountId, missingInformation, businessInformationFormData } =
            this.initBusinessInformation(data.businessInformationData);
          const ownerInformationFormData = this.initOwnerInformation(
            data.ownerInformationData,
          );
          const { existingSignedApplicationFiles, existingBankStatementFiles } =
            this.initDocumentsAndCashFlow(data);
          return {
            accountId,
            missingInformation,
            businessInformationFormData,
            ownerInformationFormData,
            existingSignedApplicationFiles,
            existingBankStatementFiles,
          };
        }),
      );
  }

  private initBusinessInformation(data: BusinessInformationGetData): {
    accountId: number;
    missingInformation: string | undefined;
    businessInformationFormData: BusinessInformationFormData;
  } {
    return {
      accountId: data.accountId,
      missingInformation: data.incompleteInfo,
      businessInformationFormData: {
        ...data,
        phone: data.phone?.toString(),
        requiredToSave: {
          name: data.name,
        },
      },
    };
  }

  private initOwnerInformation(data: OwnerInformationGetData): OwnerData[] {
    return data.values.map((owner, index) => ({
      ...owner,
      ssn: owner.ssn?.replaceAll("-", ""),
      isExisting: true,
      id: data.values[index].id,
    }));
  }

  private initDocumentsAndCashFlow(data: SubmissionResponseData) {
    const existingSignedApplicationFiles = [];
    const existingBankStatementFiles = [];
    for (const document of data.documentsAndCashFlowData.values) {
      const blob = data[document.id];
      const uppyInput: UppyInput = getUppyInputFromFile(document, blob);
      if (document.category === ApplicationFileCategory.SignedApplication) {
        existingSignedApplicationFiles.push(uppyInput);
      } else if (document.category === ApplicationFileCategory.BankStatement) {
        existingBankStatementFiles.push(uppyInput);
      } else {
        console.error("Invalid application file type");
      }
    }
    return {
      existingSignedApplicationFiles,
      existingBankStatementFiles,
    };
  }

  saveBusinessInformationForm(
    applicationId: number,
    accountId: number,
    brokerId: number,
    form: UntypedFormGroup,
  ): Observable<ApplicationResponse | ErrorResponse> {
    return this.submissionService.saveBusinessInformation(
      accountId,
      applicationId,
      {
        brokerId,
        ...form.value,
        name: form.value.requiredToSave.name,
      },
    );
  }

  saveOwnerInformationForm(
    applicationId: number,
    accountId: number,
    brokerId: number,
    form: UntypedFormGroup,
  ): Observable<OwnerData[] | ErrorResponse> {
    return forkJoin(
      form.controls.owners.value.map((owner: OwnerData) => {
        owner.isExisting = undefined;
        if (checkDeepEmpty(owner)) {
          return of({});
        }
        const birthdate =
          owner.birthdate !== "" ? new Date(owner.birthdate).toISOString() : "";
        return this.submissionService.saveOwnerInformation(
          applicationId,
          accountId,
          brokerId,
          owner.id,
          {
            ...owner,
            birthdate,
            email: owner.email?.toLowerCase(),
          },
        );
      }),
    ) as Observable<OwnerData[] | ErrorResponse>;
  }

  saveDocumentsAndCashFlowForm(
    applicationId: number,
    form: UntypedFormGroup,
  ): Observable<void> {
    const deletedFilesIds: Observable<number[]> = this.deleteFiles(applicationId, form.controls.fileIdsToDelete.value);
    const uploadedBankStatements: Observable<UppyInput[]> = this.uploadBankStatements(applicationId, form.controls.bankStatementFiles.value);
    const uploadedSignedApplications: Observable<UppyInput[]> = this.uploadSignedApplication(applicationId, form.controls.signedApplicationFiles.value);

    return forkJoin([deletedFilesIds, uploadedBankStatements, uploadedSignedApplications])
      .pipe(
        map(([deletedFiles, uploadedBankStatements, uploadedSignedApplications]) => {
          this.resetFileIdsToDelete(form);
          this.patchBankStatements(form, uploadedBankStatements);
          this.patchSignedApplication(form, uploadedSignedApplications);
        })
      );
  }

  private resetFileIdsToDelete(form: UntypedFormGroup): void {
    form.patchValue({
      fileIdsToDelete: [],
    });
  }

  private patchBankStatements(form: UntypedFormGroup, uploadedBankStatements: UppyInput[]): void {
    const initialFiles: UppyInput[] = this.getFilesWithId(form.controls.bankStatementFiles.value);
    form.patchValue({
      bankStatementFiles: [...initialFiles, ...uploadedBankStatements],
    });
  }

  private patchSignedApplication(form: UntypedFormGroup, uploadedSignedApplications: UppyInput[]): void {
    const initialFiles: UppyInput[] = this.getFilesWithId(form.controls.signedApplicationFiles.value);
    form.patchValue({
      signedApplicationFiles: [...initialFiles, ...uploadedSignedApplications],
    });
  }

  private getFilesWithId(files: UppyInput[]): UppyInput[] {
    return files.filter(file => file.meta.id);
  }

  private deleteFiles(applicationId: number, fileIds: number[]): Observable<number[]> {
    const deletedIds: Observable<number>[] = fileIds.map(fileId => this.submissionService.deleteApplicationFile(applicationId, fileId).pipe(map(() => fileId)));

    return forkJoin(deletedIds).pipe(defaultIfEmpty([] as number[]));
  }

  private uploadBankStatements(applicationId: number, files: (UppyFile | UppyInput)[]): Observable<UppyInput[]> {
    const filesToSave = files.filter(
      (file: UppyFile | UppyInput) => !file.meta.id,
    );

    const newBankStatementFiles: Observable<UppyInput>[] = filesToSave.map((file: UppyFile | UppyInput) =>
      this.submissionService.saveApplicationFile(
        applicationId,
        ApplicationFileCategory.BankStatement,
        file,
      ).pipe(map(fileIds =>
        convertToExistingFile(file as UppyFile, fileIds[0] as number)
      ))
    );

    return forkJoin(newBankStatementFiles).pipe(defaultIfEmpty([] as UppyInput[]));
  }

  private uploadSignedApplication(applicationId: number, files: (UppyFile | UppyInput)[]): Observable<UppyInput[]> {
    const filesToSave = files.filter(
      (file: UppyFile | UppyInput) => !file.meta.id,
    );

    const newBankStatementFiles: Observable<UppyInput>[] = filesToSave.map((file: UppyFile | UppyInput) =>
      this.submissionService.saveApplicationFile(
        applicationId,
        ApplicationFileCategory.SignedApplication,
        file,
      ).pipe(map(fileIds =>
        convertToExistingFile(file as UppyFile, fileIds[0] as number)
      ))
    );

    return forkJoin(newBankStatementFiles).pipe(defaultIfEmpty([] as UppyInput[]));
  }

  getAppBarActions(stepIndex: number): AppBarAction[] {
    switch (stepIndex) {
      case businessInformationIndex:
        return businessInformationAppBarActions;
      case ownerInformationIndex:
        return ownerInformationAppBarActions;
      case documentsAndCashFlowIndex:
        return documentsAndCashFlowFilesAppBarActions;
      default:
        console.error("Stepper step is out of bounds.");
        return [];
    }
  }
}
