export enum ActionButtonType {
  Basic = "basic",
  Raised = "raised",
  Stroked = "stroked",
  Flat = "flat",
}

export enum ActionButtonColor {
  Basic = "basic",
  Primary = "primary",
}
