import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import { AppPageService } from "common";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BrokerUserData } from "../broker/domain/models/broker.model";
import { BrokerFacade } from "../broker/domain/+state/broker.facade";
import { MessageService } from "projects/common/src/core/message/message.service";

@UntilDestroy()
@Component({
  selector: "ifbp-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  form: UntypedFormGroup;
  actionInProgress: boolean;
  brokerUser: BrokerUserData;

  constructor(
    private appPageService: AppPageService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private brokerFacade: BrokerFacade,
    private messageService: MessageService
  ) {
    this.form = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.maxLength(64)
        ]
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.maxLength(64)
        ]
      ],
      mobilePhone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });
  }

  ngOnInit() {
    this.initRouteData();
  }

  private initRouteData() {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.brokerUser = data.brokerUser;
      this.form.patchValue({
        firstName: this.brokerUser.firstName,
        lastName: this.brokerUser.lastName,
        mobilePhone: this.brokerUser.mobilePhone,
      });
    });
  }

  cancel() {
    this.appPageService.back();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    this.actionInProgress = true;
    this.brokerFacade
      .updateBrokerUser(this.brokerUser.id, {
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        mobilePhone: this.form.value.mobilePhone,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: this.actionComplete.bind(this),
        error: this.handleUpdateBrokerUserError.bind(this)
      });
  }

  private actionComplete() {
    this.actionInProgress = false;
    this.messageService.success("Your profile has been successfully updated.");
    this.appPageService.back();
  }

  private handleUpdateBrokerUserError() {
    this.actionInProgress = false;
    this.messageService.error("There was an error updating your profile. Try again or contact your business advisor if the error keeps occurring.");
  }
}
