<h2 mat-dialog-title>Loan has been created</h2>
<mat-dialog-content>
  <p class="secondary-text" style="max-width: 470px;">
    The loan has been created and an activation email has been sent to the client.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button 
    mat-button 
    mat-dialog-close 
    color="primary"
  >
    OK
  </button>
</mat-dialog-actions>