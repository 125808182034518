<mat-dialog-content class="mat-typography">
  <h2>Set filter</h2>
  <form [formGroup]="form">

    <div fxLayout="row"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayout.xs="column"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">

      <div class="mat-form-field">
        <ifbp-submission-autocomplete-input label="Legal entity name"
                                            [fieldName]="FieldName.AccountName"
                                            [fc]="form.get('accountName')">
        </ifbp-submission-autocomplete-input>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput
                    multiple
                    formControlName="brokerStatus"
                    placeholder="Status">
          <mat-option *ngFor="let option of brokerStatusOptions"
                      [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
        <button type="button"
                *ngIf="form.get('brokerStatus')?.value"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="form.get('brokerStatus').setValue(null)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayout.xs="column"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <div class="mat-form-field">
        <ifbp-submission-autocomplete-input label="First agent"
                                            [fieldName]="FieldName.FirstAgent"
                                            [fc]="form.get('firstAgent')">
        </ifbp-submission-autocomplete-input>
      </div>

      <div class="mat-form-field">
        <ifbp-submission-autocomplete-input label="Assigned agent"
                                            [fieldName]="FieldName.AssignedAgent"
                                            [fc]="form.get('assignedAgent')">
        </ifbp-submission-autocomplete-input>
      </div>
    </div>

    <div fxLayout="row"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayout.xs="column"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px">
      <ifc-filter-param-date-range-input [fc]="form.get('createdOn')"
                                         [label]="'Created on'"
                                         (rangeSelected)="createdOnDateSelected($event)">
      </ifc-filter-param-date-range-input>

      <ifc-filter-param-date-range-input [fc]="form.get('updatedOn')"
                                         [label]="'Updated on'"
                                         (rangeSelected)="updatedOnDateSelected($event)">
      </ifc-filter-param-date-range-input>
    </div>

    <div fxLayout="row"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayout.xs="column"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px"
         *ngIf="form.get('createdOn')?.value === 'custom'">
      <ifc-datepicker class="ifc-datepicker"
                      [fc]="form.get('createdOnFrom')"
                      [max]="form.get('createdOnTo')?.value"
                      [label]="'Created date from'"></ifc-datepicker>
      <ifc-datepicker class="ifc-datepicker"
                      [fc]="form.get('createdOnTo')"
                      [min]="form.get('createdOnFrom')?.value"
                      [label]="'Created date to'"></ifc-datepicker>
    </div>

    <div fxLayout="row"
         fxLayoutAlign="start start"
         fxLayoutGap="24px"
         fxLayout.xs="column"
         fxLayoutAlign.xs="start center"
         fxLayoutGap.xs="0px"
         *ngIf="form.get('updatedOn')?.value === 'custom'">
      <ifc-datepicker class="ifc-datepicker"
                      [fc]="form.get('updatedOnFrom')"
                      [max]="form.get('updatedOnTo')?.value"
                      [label]="'Updated date from'"></ifc-datepicker>
      <ifc-datepicker class="ifc-datepicker"
                      [fc]="form.get('updatedOnTo')"
                      [min]="form.get('updatedOnFrom')?.value"
                      [label]="'Updated date to'"></ifc-datepicker>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions
     class="flex row end-center">
  <button mat-button
          [mat-dialog-close]="">Cancel</button>
  <button mat-button
          (click)="onClickOk()"
          cdkFocusInitial
          color="primary">Ok</button>
</div>
