import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SubmissionRoutingModule } from "./submission-routing.module";
import { BusinessInformationStepComponent } from "./business-information-step/business-information-step.component";
import { SharedModule } from "../shared/shared.module";
import { SubmissionFormComponent } from "./submission-form/submission-form.component";
import { OwnerInformationStepComponent } from "./owner-information-step/owner-information-step.component";
import { DocumentsAndCashFlowStepComponent } from "./documents-and-cash-flow-step/documents-and-cash-flow-step.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { ThankYouDialogComponent } from "./thank-you-dialog/thank-you-dialog.component";
import { IncompleteSubmissionDialogComponent } from "./incomplete-submission-dialog/incomplete-submission-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    SubmissionRoutingModule,
    SharedModule,
    UppyAngularDashboardModule,
  ],
  declarations: [
    BusinessInformationStepComponent,
    OwnerInformationStepComponent,
    DocumentsAndCashFlowStepComponent,
    SubmissionFormComponent,
    ThankYouDialogComponent,
    IncompleteSubmissionDialogComponent,
  ],
})
export class SubmissionModule {}
