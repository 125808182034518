import { NgModule } from "@angular/core";
import { CoreModule } from "common";
import { initApiPaths } from "./const/api-paths";

@NgModule({
  imports: [CoreModule],
})
export class PartnerCoreModule {
  constructor() {
    initApiPaths();
  }
}
