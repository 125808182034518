<h2 mat-dialog-title>Incomplete submission</h2>
<mat-dialog-content *ngIf="data.missingInformation">
  <p class="secondary-text">
    Please provide the requested information below so we can move forward with this submission.
  </p>
  <p>
    {{data.missingInformation}}
  </p>
</mat-dialog-content>
<mat-dialog-content *ngIf="!data.missingInformation">
  <p class="secondary-text">
    Please provide the requested information to move forward with this submission.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button 
    mat-button 
    mat-dialog-close 
    color="primary"
  >
    OK
  </button>
</mat-dialog-actions>