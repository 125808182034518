<h2 mat-dialog-title>Bank linking</h2>
<mat-dialog-content>
  <p class="secondary-text">
    Idea Financial uses <a href="https://plaid.com/">Plaid</a> to securely link banks. Email your customer the bank link for faster access to funds.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button 
    mat-button 
    mat-dialog-close 
    color="primary"
  >
    OK
  </button>
</mat-dialog-actions>