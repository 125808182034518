import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import {
  UserData,
  UsersQueryResult,
} from "../domain/models/users.model";
import { take } from "rxjs/operators";
import { UsersFacade } from "../domain/+state/users.facade";
import { parseNumber } from "common";

@Injectable({ providedIn: "root" })
export class UsersResolver implements Resolve<UsersQueryResult> {
  constructor(private facade: UsersFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UsersQueryResult> {
    return this.facade
      .getUsers$({ active: true, ...route.queryParams })
      .pipe(take(1));
  }
}

@Injectable({ providedIn: "root" })
export class UserResolver implements Resolve<UserData> {
  constructor(private facade: UsersFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UserData> {
    return this.facade.getUser$(parseNumber(route.params.id)).pipe(take(1));
  }
}
