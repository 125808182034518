import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AppPageOptions, AppSidenavItem, AppSidenavSubItem } from "common";
import { forkJoin, Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { addDefaultAppSidenavItemOptions } from "../Tools";
import { baseAppSidenavItems } from "./app.data";
import {
  BrokerUserData,
  UserDbRole,
} from "./broker/domain/models/broker.model";
import {
  BrokerCurrentResolver,
  BrokerDataDetailsResolver,
} from "./broker/infrastructure/broker.resolver";
import {
  BrokerStatus,
  TeamSidenavFilters,
  userRoleUiMap,
  WebPaths,
} from "./shared/SharedConstants";
import { SubmissionService } from "./shared/submission.service";

@Injectable({
  providedIn: "root",
})
export class AppPageResolver implements Resolve<AppPageOptions> {
  constructor(
    private brokerUserResolver: BrokerCurrentResolver,
    private brokerDataDetailsResolver: BrokerDataDetailsResolver,
  ) {}

  resolve(): Observable<AppPageOptions> {
    return forkJoin([
      this.brokerUserResolver.resolve(),
      this.brokerDataDetailsResolver.resolve(),
    ]).pipe(
      map(([brokerUser, brokerDataDetails]) => ({
        contentWithoutPadding: true,
        contentClass: "fill default-app-page-content",
        contentAlignment: "start-start",
        sidenavEnabled: true,
        sidenavHeaderSticky: true,
        hideSidenavToggle: true,
        sidenavTitle: `${brokerUser.firstName} ${brokerUser.lastName}`,
        sidenavSubtitle: `${brokerDataDetails.name} ${userRoleUiMap.get(
          brokerUser.role,
        )}`,
      })),
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class AppSidenavItemsResolver implements Resolve<any> {
  constructor(
    private brokerUserResolver: BrokerCurrentResolver,
    private submissionService: SubmissionService,
  ) {}

  resolve(): Observable<any> {
    return this.brokerUserResolver.resolve().pipe(
      mergeMap((brokerUser: BrokerUserData) =>
        forkJoin([
          of(brokerUser),
          this.submissionService.getSubmissions({
            brokerStatus: BrokerStatus.CONDITIONAL_OFFER,
            brokerId: brokerUser.brokerId,
          }),
        ]),
      ),
      map(([brokerUser, submissionsQueryResult]) => {
        const hasConditionalOfferSubmissions =
          submissionsQueryResult.totalCount !== 0;
        let appSidenavItems = baseAppSidenavItems({
          showConditionalOfferTab: hasConditionalOfferSubmissions,
        });
        if (brokerUser.role === UserDbRole.ADMIN) {
          const teamSidenavItems = [
            {
              url: WebPaths.Team,
              label: TeamSidenavFilters.TEAM,
              icon: "people",
              subitems: [
                {
                  url: WebPaths.Team,
                  query: { roles: UserDbRole.ADMIN },
                  label: TeamSidenavFilters.ADMINS,
                },
                {
                  url: WebPaths.Team,
                  query: { roles: UserDbRole.USER },
                  label: TeamSidenavFilters.TEAM_MEMBERS,
                },
                {
                  url: WebPaths.Team,
                  query: { active: false },
                  label: TeamSidenavFilters.DEACTIVATED_USERS,
                },
              ],
            },
          ];
          appSidenavItems = appSidenavItems.concat(
            addDefaultAppSidenavItemOptions(teamSidenavItems, {
              query: { sort: "lastName" },
            }),
          );
        }
        return {
          items: appSidenavItems,
          trackByFn: (
            _index: number,
            item: AppSidenavItem | AppSidenavSubItem,
          ) => {
            return item.label;
          },
        };
      }),
    );
  }
}
