<h2 mat-dialog-title>Submission declined</h2>
<mat-dialog-content>
  <p class="secondary-text">
    Declined reason:
  </p>
  {{data.declinedReason}}
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button 
    mat-button 
    mat-dialog-close 
    color="primary"
  >
    OK
  </button>
</mat-dialog-actions>