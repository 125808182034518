<div fxLayout="column"
     fxLayoutAlign="start center"
     class="offer-details-container">
  <div>
    <p class="secondary-text info-text">
      {{businessInformation?.name}} has been approved for funding. Please select a product, set the term,
      review the payment schedule and upload any Closing Documents needed. After customizing the offer,
      press the Select This Offer button and Submit for review.
    </p>
    <div *ngIf="locOfferBundleList?.length > 0 && termOfferBundleList?.length > 0"
         class="loan-type-toggle">
      <button [ngClass]="{'selected': currentLoanType === ProductCode.LOC}"
              mat-flat-button
              color="toggle"
              (click)="setCurrentLoanType(ProductCode.LOC)">
        <mat-icon>data_saver_off</mat-icon>
        <div>
          LINE OF CREDIT
        </div>
      </button>
      <button [ngClass]="{'selected': currentLoanType === ProductCode.Term}"
              mat-flat-button
              color="toggle"
              (click)="setCurrentLoanType(ProductCode.Term)">
        <mat-icon>assessment</mat-icon>
        <div>
          TERM LOAN
        </div>
      </button>
    </div>
    <div *ngIf="locOfferBundleList?.length > 0 && termOfferBundleList?.length === 0"
         class="header-wrapper">
      <mat-icon>data_saver_off</mat-icon>
      <div>
        LINE OF CREDIT
      </div>
    </div>
    <div *ngIf="locOfferBundleList?.length === 0 && termOfferBundleList?.length > 0"
         class="header-wrapper">
      <mat-icon>assessment</mat-icon>
      <div>
        TERM LOAN
      </div>
    </div>
  </div>
  <div class="scroll-container">
    <mat-card class="slider-card">
      <div class="slider-section">
        <div class="slider-info">
          <div>
            Loan amount
          </div>
          <div>
            {{currentOfferBundle?.offers[0].amount | currency : 'USD' : 'symbol' : '1.0-0'}}
          </div>
        </div>
        <ngx-slider *ngIf="sliderSets?.[currentLoanType].loanAmountOptions.stepsArray?.length > 1"
                    [value]="currentOfferBundle?.offers[0].amount"
                    [options]="sliderSets?.[currentLoanType].loanAmountOptions"
                    (userChange)="onSliderChange($event.value, SliderType.AMOUNT)"></ngx-slider>
      </div>
      <div class="slider-section">
        <div class="slider-info">
          <div>
            Term
          </div>
          <div>
            {{currentOfferBundle?.offers[0].repaymentTerm}}
            {{
                currentOfferBundle?.offers[0].repaymentTerm > 1
                  ? " months"
                  : " month"
              }}
          </div>
        </div>
        <ngx-slider *ngIf="sliderSets?.[currentLoanType].termOptions.stepsArray?.length > 1"
                    [value]="currentOfferBundle?.offers[0].repaymentTerm"
                    [options]="sliderSets?.[currentLoanType].termOptions"
                    (userChange)="onSliderChange($event.value, SliderType.REPAYMENT_TERM)"></ngx-slider>
      </div>
    </mat-card>
    <div class="section-wrapper"
         fxLayout="column">
      <span class="subheading">Details</span>
      <div fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">{{currentLoanType === ProductCode.LOC ? "Credit limit" : "Loan amount"}}</div>
            <div>{{offerCalculator?.amount | currency}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill
             fxFlex.lt-sm="100">
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Disbursed Amount</div>
            <div>{{offerCalculator?.disbursedAmount | currency:'USD':'symbol':'1.2-2'}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Fee ({{offerCalculator?.drawFeeFormatted}})</div>
            <div>
              {{offerCalculator?.uiFee | currency}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Finance charges paid</div>
            <div>{{offerCalculator?.sampleDuration.interest | currency}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">{{offerCalculator?.paymentFrequency | paymentFrequencyLabel}} payment</div>
            <div>{{offerCalculator?.termPayment | currency}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Payback</div>
            <div>{{offerCalculator?.sampleDuration.payback | currency}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div *ngIf="currentLoanType === ProductCode.Term"
           fxLayout="row"
           fxLayoutAlign="start start"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Factor rate</div>
            <div>{{offerCalculator?.rate / 100 + 1 | number:'1.4-4'}}</div>
          </div>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Commissions ({{offerCalculator?.applicationOffer?.maxUpsell / 100 | percent:'1.2-2'}})</div>
            <div>{{ offerCalculator?.getCommision() | currency:'USD':'symbol':'1.2-2'}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="currentLoanType === ProductCode.LOC"
           fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Daily finance charges</div>
            <div>{{offerCalculator?.dailyInterestRate | percent:'1.4-4'}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Monthly finance cost*</div>
            <div>{{offerCalculator?.monthlyRate | percent:'1.2-2'}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div *ngIf="currentLoanType === ProductCode.LOC"
           fxLayout="row"
           fxLayoutAlign="end end"
           fxLayout.lt-sm="column"
           fxLayoutGap.gt-xs="48px"
           fxFlex.gt-xs="100">
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Annual finance cost*</div>
            <div>{{ offerCalculator?.annualRate | percent:'1.2-2'}}</div>
          </div>
        </div>
        <div fxLayout="column"
             fxFlex.gt-xs="50"
             fxFill>
          <div class="row-container"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="12px">
            <div class="row-title">Commissions ({{offerCalculator.brokerInitialDrawCommision | percent:'1.2-2'}})</div>
            <div>{{ offerCalculator?.getCommision() | currency:'USD':'symbol':'1.2-2' }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="currentLoanType === ProductCode.LOC"
           class="offer-details-info">
        * The cost of finance is calculated based on the finance charges paid over the life of the loan, assuming an organic repayment.
      </div>
    </div>

    <div *ngIf="currentCheckoutRequirements?.length > 0">
      <div [class.documents-container-error]="!isCurrentClosingDocumentsValid && submitTouched"
           class="section-wrapper"
           fxLayout="column"
           fxLayoutGap="12px">
        <span class="subheading">Closing Documents</span>
        <ng-container *ngFor="let checkoutRequirement of currentCheckoutRequirements; let checkoutIndex = index">
          <mat-divider *ngIf="checkoutIndex !== 0"></mat-divider>
          <div class="documents-section-wrapper"
               fxLayout="row"
               fxLayoutGap="12px">
            <mat-icon *ngIf="checkoutRequirement.status === CheckoutRequirementStatus.Approved"
                      class="mat-green">
              check_circle
            </mat-icon>
            <mat-icon *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved &&
              !checkoutRequirement.isFileUploaded && !checkoutRequirement.documents?.length"
              class="mat-gray" fontSet="material-icons-outlined">
              circle
            </mat-icon>
            <mat-icon *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved &&
              (checkoutRequirement.isFileUploaded || checkoutRequirement.documents.length)" class="mat-orange">
              sticky_note_2
            </mat-icon>
            <div class="files-info-column"
                 fxLayout="column"
                 fxLayoutGap="6px">
              <span>{{checkoutRequirement.requiredDocument}} {{checkoutRequirement.status === CheckoutRequirementStatus.Required ? "(Required)" : ""}}</span>
              <span *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved &&
                (!checkoutRequirement.isFileUploaded || checkoutRequirement.documents?.length)"
                class="mat-gray">Upload any PDF, JPEG, PNG copies
                {{checkoutRequirement.notes ? ("of most recent month ending: " + checkoutRequirement.notes) : ""}}
              </span>
              <span *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved &&
                (checkoutRequirement.isFileUploaded && !checkoutRequirement.documents?.length)"
                class="mat-gray">
                Received via alternative method outside Broker Portal
              </span>
              <span *ngIf="checkoutRequirement.notes && checkoutRequirement.status === CheckoutRequirementStatus.Approved"
                class="mat-gray">{{checkoutRequirement.notes}}
              </span>
              <ng-container
                            *ngFor="let closingDocument of closingDocumentsForm?.get([currentOfferBundle.id, checkoutRequirement.id])?.value; let documentIndex = index">
                <div class="flex row"
                     fxLayoutAlign="start center">
                  <button mat-icon-button
                          type="button"
                          [ngClass]="{
                      'hidden': closingDocument?.meta?.isExisting
                    }"
                          (click)="removeDocument(checkoutRequirement.id, documentIndex)">
                    <mat-icon class="mat-gray">cancel</mat-icon>
                  </button>
                  <div (click)="onClosingDocumentClick(closingDocument)" class="filename">{{closingDocument.name}}</div>
                </div>
              </ng-container>
            </div>
            <button *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved"
                    mat-button
                    class="upload-button"
                    (click)="openUploadDialog(checkoutRequirement.id)">UPLOAD</button>
          </div>
        </ng-container>
      </div>
      <div class="documents-error-label"
           *ngIf="!isCurrentClosingDocumentsValid && submitTouched">Upload the required documents</div>
    </div>
    <div *ngIf="currentNextStepRequirements?.length > 0"
         class="section-wrapper"
         fxLayout="column"
         fxLayoutGap="12px">
      <span class="subheading">Next step requirements</span>
      <ng-container *ngFor="let nextStepRequirement of currentNextStepRequirements; let checkoutIndex = index">
        <mat-divider *ngIf="checkoutIndex !== 0"></mat-divider>
        <div class="documents-section-wrapper"
             fxLayout="row"
             fxLayoutGap="12px">
          <mat-icon class="mat-gray"
                    fontSet="material-icons-outlined">
            circle
          </mat-icon>
          <div class="files-info-column"
               fxLayout="column"
               fxLayoutGap="6px">
            <span>{{nextStepRequirement.requiredDocument}}</span>
            <span *ngIf="nextStepRequirement.notes"
                  class="mat-gray">{{nextStepRequirement.notes}}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <mat-expansion-panel class="mat-elevation-z payment-schedule-expansion">
      <mat-expansion-panel-header>
        <mat-panel-title class="secondary-text">
          PAYMENT SCHEDULE
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column"
           class="ifc-card-elevation"
           ngClass.lt-md="payment-schedule-sm">
        <ifbp-payment-schedule-table *ngIf="currentOfferBundle && offerCalculator"
                                     [offerDetail]="currentOfferBundle?.offers[0]"
                                     [sampleDraw]="offerCalculator?.sampleDraw"></ifbp-payment-schedule-table>
      </div>
    </mat-expansion-panel>
    <ifbp-share-offer *ngIf="businessInformation && currentOfferBundle"
                      [uuid]="businessInformation.uuid"
                      [offer]="currentOfferBundle.offers[0]"
                      [bundleId]="currentOfferBundle.id"
                      [showPlaidLink]="true"
                      [showCustomerRegistration]="false"></ifbp-share-offer>
    <div class="select-offer-container"
         [class.wobble]="highlightedFieldsRequiredToSubmit && currentOfferBundle?.status !== OfferBundleStatus.Accepted">
      <button mat-flat-button
              class="select-offer-button"
              [ngClass]="{'selected': currentOfferBundle?.status === OfferBundleStatus.Accepted}"
              (click)="onSelectOfferChange()">
        <span *ngIf="currentOfferBundle?.status !== OfferBundleStatus.Accepted">SELECT THIS OFFER</span>
        <span *ngIf="currentOfferBundle?.status === OfferBundleStatus.Accepted">
          <mat-icon class="mat-green">check_circle</mat-icon>
          SELECTED
        </span>
      </button>
      <div class="documents-error-label"
           *ngIf="submitTouched && currentOfferBundle?.status !== OfferBundleStatus.Accepted">Press Select This Offer to submit</div>
    </div>

    <div class="bottom-actions-container"
         fxLayout="row"
         fxLayoutAlign="space-between center">
      <ifbp-not-interested-action [data]="notInterestedActionData"
                                  (actionComplete)="onNotInterestedActionComplete($event)">
      </ifbp-not-interested-action>
      <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
    </div>
  </div>
</div>
