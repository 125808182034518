import { Injectable } from "@angular/core";
import {
  MfaActionsService,
  MfaErrorCodeType,
  MfaPhoneStatus,
  MfaService,
  VerifyMfaCodeData,
} from "common";
import { AccountSignInService } from "../../../domain/+state/account-sign-in.service";
import { SignInErrorResponse, SignInExtras, SignInMfaRequest, SignInResponse } from "../../../domain/model/account.model";

@Injectable()
export class MfaLoginService {
  constructor(
    private mfaActions: MfaActionsService,
    private accountSignInService: AccountSignInService,
    private mfaService: MfaService,
  ) {}

  verify(data: VerifyMfaCodeData) {
    const request: SignInMfaRequest = {
      mfa_token: data.mfaData.mfaToken,
      mfa_code: data.mfaCode,
      trust_device: data.trustDevice,
    };
    const extras: SignInExtras = {
      username: data.mfaData.contextData?.username,
      rememberMe: data.mfaData.contextData?.rememberMe,
      loginType: data.mfaData.contextData?.loginType,
    };

    switch (data.mfaData.phoneStatus) {
      case MfaPhoneStatus.Verified:
        return this.verifyMfaLoginVerifiedPhone(request, extras);
      case MfaPhoneStatus.Unverified:
        return this.verifyMfaLoginUnverifiedPhone(request, extras);
      default:
        this.mfaService.handleMfaError("Invalid phoneStatus to verify");
        break;
    }
  }

  private verifyMfaLoginVerifiedPhone(
    request: SignInMfaRequest,
    extras: SignInExtras,
  ) {
    this.accountSignInService
      .signInByMfa(request, extras)
      .subscribe((data: SignInResponse & SignInErrorResponse) => {
        this.handleMfaLoginResponse(data);
      });
  }

  private verifyMfaLoginUnverifiedPhone(
    request: SignInMfaRequest,
    extras: SignInExtras,
  ) {
    this.accountSignInService
      .confirmPhoneAndSignIn(request, extras)
      .subscribe((data: SignInResponse & SignInErrorResponse) => {
        this.handleMfaLoginResponse(data);
      });
  }

  private handleMfaLoginResponse(data: SignInResponse & SignInErrorResponse) {
    if (!data?.hasError) {
      this.mfaService.handleMfaSuccess();
      return;
    }

    if (data?.errorCode === MfaErrorCodeType.InvalidMfaCode) {
      this.mfaActions.invalidCode();
      return;
    }

    this.mfaService.handleMfaError();
  }
}
