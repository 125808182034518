import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccountFacade } from "../../domain/+state/account.facade";
import { RegistrationTokenStatus } from "../../domain/model/account.model";
import { ErrorActionType, ErrorDescriptionType, ErrorsService } from "common";

@Injectable({
  providedIn: "root",
})
export class SignUpGuard implements CanActivate {
  constructor(
    private accountFacade: AccountFacade,
    private router: Router,
    private errorsService: ErrorsService
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {
    const email = _next.queryParams['email'];
    const registrationToken = _next.queryParams['registrationToken'];

    if (!email || !registrationToken) {
      return this.router.createUrlTree(['error']);
    }

    return this.accountFacade.getRegistrationTokenStatus(email, registrationToken)
    .pipe(
      map(result => {
        switch(result.status) {
          case RegistrationTokenStatus.None:{
            this.errorsService.showCustomErrorPage(
              "For assistance, Admins should contact their Idea Financial Business Advisor and team members should contact their company admin.",
              "Unable to activate account",
              ErrorActionType.BackToLogin
            )
            return false;
          }
          case RegistrationTokenStatus.Invalid:{
            this.errorsService.showCustomErrorPage(
              `We care about security and want to ensure everyone's information is kept safe. Registration links expire ${result.tokenExpirationHours} hours after being created. <br /><br />For a new registration link, Admins should contact their Idea Financial Business Advisor and team members should contact their company admin.`,
              "This link has expired",
              ErrorActionType.BackToLogin,
              ErrorDescriptionType.HTML
            )
            return false;
          }
          default:{
            return true;
          }
        }
      })
    )
  }
}
