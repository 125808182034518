<mat-dialog-content class="mat-typography">
  <h2>Email bank link</h2>
  <h4 class="secondary-text">
    {{data.application.accountName}} has been approved for funding from {{companyName}}. 
    Send bank link details to the merchant’s email below.
  </h4>
  <form [formGroup]="form">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input #inlineInput matInput formControlName="email" type="text" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              <span>{{option}}</span>
            </mat-option>
        </mat-autocomplete>
        <mat-error>Please enter a valid email address.</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="undefined">CANCEL</button>
  <button 
    mat-button 
    [mat-dialog-close]="form.value.email" 
    [disabled]="!form.valid" 
    color="primary" 
    (click)="sendBankLinkEmail()"
  >
    SEND
  </button>
</div>