import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-loan-created-dialog",
  templateUrl: "./loan-created-dialog.component.html",
  styleUrls: ["./loan-created-dialog.component.scss"],
})
export class LoanCreatedDialogComponent {}
