import { Pipe, PipeTransform } from '@angular/core';
import { BooleanHelper } from '../../helpers/boolean.helper';

@Pipe({
  name: 'booleanValueLabel'
})
export class BooleanValueLabelPipe implements PipeTransform {

  transform(value: boolean, labelsMap: Map<boolean, string>): string {
    if(!labelsMap)
      return value as any;

    return BooleanHelper.getMappedNameFromValue(value, labelsMap);
  }
}
