import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { LostReason, LostReasonsQueryResult } from "../model/dictionaries.model";
import { DictionariesRemote } from "../../infrastructure/dictionaries.remote";
import { DictionariesStateService } from "./dictionaries.state.service";

@Injectable({
  providedIn: "root"
})
export class DictionariesFacade {
  constructor(
    private readonly stateService: DictionariesStateService,
    private remote: DictionariesRemote,
  ) { }

  getLostReasons$(): Observable<LostReason[]> {
    if (this.stateService.isLostReasonsStateLoaded()) {
      return this.stateService.getLostReasons$();
    }
    return this.refreshLostReasons$();
  }

  private refreshLostReasons$(): Observable<LostReason[]> {
    return this.remote.getAllLostReasons().pipe(
      tap((resons: LostReasonsQueryResult) =>
        this.stateService.setLostReasons(resons.values)),
      switchMap(() => this.stateService.getLostReasons$())
    );
  }
}