<article>
  <header class="header">
    <h1 class="heading">Submissions</h1>
    <button mat-flat-button
            color="primary"
            class="new-submission-button"
            [routerLink]="'/submission/new'">
      <span class="new-submission-button__full">
        + NEW SUBMISSION
      </span>
      <span class="new-submission-button__short">
        +
      </span>
    </button>
  </header>
  <section class="skeleton-loader"
           *ngIf="!isDataLoaded">
    <ngx-skeleton-loader count="5"
                         appearance="line">
    </ngx-skeleton-loader>
  </section>
  <ng-container *ngIf="viewData">
    <ng-container *ngIf="viewData.totalCount === 0 && (!viewData.filtersChipList || viewData.filtersChipList.length === 0); else table">
      <ifbp-no-results-found [data]="noResultsFoundData"
                             (actionClicked)="onNoResultsActionClicked($event)">
      </ifbp-no-results-found>
    </ng-container>
    <ng-template #table>
      <section class="filters-toolbar">
        <ifbp-filters-chip-list [filters]="viewData.filtersChipList"
                                (filterRemoved)="onFilterRemoved($event)">
        </ifbp-filters-chip-list>
        <div class="filter-actions">
          <div class="filter-actions__standard">
            <button mat-icon-button
                    (click)="filterDialog()"
                    class="secondary-text"
                    matTooltip="Filter">
              <mat-icon>filter_list</mat-icon>
            </button>
          </div>
          <div class="filter-actions__menu">
            <button mat-icon-button
                    [matMenuTriggerFor]="actionsMenu"
                    class="secondary-text more-actions-menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu">
              <button mat-menu-item
                      (click)="filterDialog()">
                <mat-icon>filter_list</mat-icon>
                <span>Filter</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </section>
      <section>
        <div class="table-wrapper">
          <table class="table"
                 *ngIf="viewData.submissionSummaryList?.length > 0"
                 mat-table
                 matSort
                 [matSortActive]="viewData.sortActive"
                 [matSortDirection]="viewData.sortDirection"
                 [dataSource]="viewData.submissionSummaryList"
                 (matSortChange)="onSort($event)">

            <ng-container matColumnDef="createdOn">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="createdOn">Created on</th>
              <td mat-cell
                  *matCellDef="let data">{{data.submission.createdOn | date}}</td>
            </ng-container>
            <ng-container matColumnDef="updatedOn">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="updatedOn">Updated on</th>
              <td mat-cell
                  *matCellDef="let data">{{data.submission.updatedOn | date}}</td>
            </ng-container>
            <ng-container matColumnDef="accountName">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="accountName">Legal entity name</th>
              <td mat-cell
                  *matCellDef="let data">{{data.submission.accountName}}</td>
            </ng-container>
            <ng-container matColumnDef="stage">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="stage">Status</th>
              <td mat-cell
                  *matCellDef="let data">
                <ifbp-submission-status [brokerStatus]="data.submission.brokerStatus"
                                        [loanId]="data.submission.loanId">
                </ifbp-submission-status>
              </td>
            </ng-container>
            <ng-container matColumnDef="information">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="information">Information</th>
              <td mat-cell
                  *matCellDef="let data">
                <span [matTooltip]="data.submission.information"
                      matTooltipShowDelay="300">
                  {{ data.submission.information | truncate : 60 }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="loanNumber">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="loanNumber">Loan number</th>
              <td mat-cell
                  *matCellDef="let data">{{data.submission.loanNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="firstAgent">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="firstAgent">First agent</th>
              <td mat-cell
                  *matCellDef="let data">{{data.submission.firstAgent}}</td>
            </ng-container>
            <ng-container matColumnDef="assignedAgent">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="assignedAgent">Assigned agent</th>
              <td mat-cell
                  *matCellDef="let data">{{data.submission.assignedAgent}}</td>
            </ng-container>
            <ng-container matColumnDef="overflow">
              <th mat-header-cell
                  *matHeaderCellDef></th>
              <td mat-cell
                  *matCellDef="let data">
                <ng-container>
                  <button class="overflow-button"
                          mat-icon-button
                          [matMenuTriggerFor]="submissionMenu"
                          #menuTrigger="matMenuTrigger"
                          [ngClass]="{'show-overflow-button': menuTrigger.menuOpen}"
                          (click)="$event.stopPropagation()"
                          (menuOpened)="menuOpenedRowId = data.submission.id"
                          (menuClosed)="menuOpenedRowId = null">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #submissionMenu="matMenu">
                    <button mat-menu-item
                            class="menu-item"
                            (click)="goToSubmissionDetails(data.submission)">
                      <span>Details</span>
                    </button>
                    <button *ngIf="data.submission.brokerStatus === BrokerStatus.CONTRACT_READY"
                            mat-menu-item
                            class="menu-item"
                            (click)="goToSubmissionDetails(data.submission)">
                      <span>Send checkout</span>
                    </button>
                    <ifbp-not-interested-action class="menu-item"
                                                [isMenuItem]="true"
                                                [data]="data.notInterestedActionData"
                                                (actionComplete)="onNotInterestedActionComplete($event)">
                    </ifbp-not-interested-action>
                  </mat-menu>
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let data; columns: displayedColumns;"
                [ngClass]="{
         'selected-row': data.submission.id === menuOpenedRowId
       }"
                (click)="goToSubmissionDetails(data.submission)">
            </tr>
          </table>

          <ifbp-no-results-found *ngIf="viewData.totalCount === 0"
                                 [data]="noResultsForFiltersFoundData"
                                 (actionClicked)="onNoResultsActionClicked($event)">
          </ifbp-no-results-found>
        </div>
        <div [hidden]="viewData.totalCount <= 10">
          <mat-paginator #matPaginator
                         showFirstLastButtons
                         [pageSize]="viewData.filters.pageSize"
                         [pageIndex]="viewData.filters.pageNumber - 1"
                         [length]="viewData.totalCount"
                         [pageSizeOptions]="[10, 20, 50]"
                         (page)="onPage($event)">
          </mat-paginator>
        </div>
      </section>
    </ng-template>
  </ng-container>
</article>
