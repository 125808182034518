import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-declined-submission-dialog",
  templateUrl: "./declined-submission-dialog.component.html",
  styleUrls: ["./declined-submission-dialog.component.scss"],
})
export class DeclinedSubmissionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { declinedReason: string },
  ) {}
}
