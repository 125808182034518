import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { CheckoutReadyRoutingModule } from "./checkout-ready-routing.module";
import { CheckoutReadyComponent } from "./checkout-ready.component";
import { LoanCreatedDialogComponent } from "./loan-created-dialog/loan-created-dialog.component";
import { SubmissionsModule } from "../feature-modules/submissions/submissions.module";
@NgModule({
  imports: [CommonModule, CheckoutReadyRoutingModule, SharedModule, SubmissionsModule],
  declarations: [CheckoutReadyComponent, LoanCreatedDialogComponent],
})
export class CheckoutReadyModule { }
