// based on service in application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/shared/application.service.ts
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import {
  ConfigService,
  ApplicationBaseService,
  ApplicationBaseData,
} from "common";

@Injectable({
  providedIn: "root",
})
export class ApplicationService extends ApplicationBaseService<ApplicationBaseData> {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService);
  }

  getByUuid(uuid: string): Observable<ApplicationBaseData> {
    return this.http.get<ApplicationBaseData>(
      `${this.baseUrl}/uuid/${uuid}?bundleStatus=pending&bundleStatus=accepted`,
    );
  }
}
