import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "hide",
})
export class HiddenPipe implements PipeTransform {
  transform(value: string): any {
    return "*".repeat(value?.length);
  }
}
