import {
  ApplicationOfferBundleData,
  ApplicationStage,
  EntityType,
  TimeInBusinessPeriod,
} from "common";
import { BrokerStatus } from "../../shared/SharedConstants";

export interface ErrorResponse {
  hasError?: boolean;
  message?: string;
}

interface AddressData {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface BusinessInformationBaseData {
  dba?: string;
  entityType?: EntityType;
  phone?: string;
  address?: AddressData;
  timeInBusiness?: TimeInBusinessPeriod;
  description?: string;
  monthlySales?: number;
  requestedAmount?: number;
  ein?: string;
  stage?: ApplicationStage;
}
export interface BusinessInformationRequestData
  extends BusinessInformationBaseData {
  brokerId: number;
  name: string;
}

interface BusinessInformationRequiredToSave {
  name: string;
}
export interface BusinessInformationFormData
  extends BusinessInformationBaseData {
  requiredToSave: BusinessInformationRequiredToSave;
}

export type AccountRequestData = Exclude<
  BusinessInformationRequestData,
  "requestedAmount"
> & {
  status: string;
};

export type ApplicationRequestData = Pick<
  BusinessInformationRequestData,
  "requestedAmount" | "brokerId"
> & {
  accountId: number;
  stage?: ApplicationStage;
};

export interface ApplicationResponse {
  id: number;
  customerIds: number[];
  accountId: number;
  accountName: string;
  requestedAmount?: number;
  incompleteInfo?: string;
  declinedReason?: string;
  createdOn: string;
  previousStage?: ApplicationStage;
  loanId?: number;
  offerBundles?: ApplicationOfferBundleData[];
  brokerStatus: BrokerStatus;
  uuid: string;
  stage: ApplicationStage;
}

export interface OwnerData {
  mobilePhone?: string;
  address: AddressData;
  firstName?: string;
  lastName?: string;
  email?: string;
  ssn?: string;
  birthdate: string;
  isExisting?: boolean;
  id?: number;
}

export enum ApplicationFileCategory {
  BankStatement = "bank-statement",
  SignedApplication = "application",
}

export interface AccountResponse {
  name: string;
  dba?: string;
  ein?: string;
  phone?: number;
  address: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  entityType?: EntityType;
  timeInBusiness?: TimeInBusinessPeriod;
  monthlySales?: number;
  description?: string;
  stage: ApplicationStage;
  advisorFirstName?: string;
  advisorLastName?: string;
  advisorName?: string;
  advisorEmail?: string;
  advisorPhone?: string;
  advisorCalendarLinkUrl: string;
  advisorAvatar: string;
}

export type BusinessInformationGetData = ApplicationResponse & AccountResponse;

export interface OwnerInformationGetData {
  values: OwnerData[];
  totalCount: number;
}

export interface ApplicationFile {
  filename: string;
  category: ApplicationFileCategory;
  mimeType: string;
  name: string;
  id: number;
}

export interface UppyInput {
  data: Blob;
  size: number;
  name: string;
  type: string;
  meta: {
    isExisting: boolean;
    id: number | null;
  };
}

export interface SubmissionResponseData {
  businessInformationData: BusinessInformationGetData;
  ownerInformationData: OwnerInformationGetData;
  documentsAndCashFlowData: ApplicationFilesResponse;
  [id: number]: Blob;
}

export interface ExistingSubmissionData {
  accountId: number;
  missingInformation?: string;
  businessInformationFormData: BusinessInformationFormData;
  ownerInformationFormData: OwnerData[];
  existingSignedApplicationFiles: UppyInput[];
  existingBankStatementFiles: UppyInput[];
}

export interface ApplicationFilesResponse {
  values: ApplicationFile[];
  totalCount: number;
}

export interface NewSubmissionResponse {
  accountId: number;
  stage: ApplicationStage;
}

interface MatStepOptions {
  state: string;
  hasError: boolean;
  completed: boolean;
}

export interface MatHeaderOptions {
  [key: number]: MatStepOptions;
}
