export enum AppBarMenuItemAction {
  LOG_OUT = "log-out",
}

export enum MenuItemType {
  LINK = "link",
  ACTION = "action",
}
export interface AppBarMenuItem {
  isHidden?: boolean;
  label: string;
  icon?: string;
  typeOptions:
    | { type: MenuItemType.LINK; url: string }
    | { type: MenuItemType.ACTION; action: AppBarMenuItemAction };
}

export interface AppBarDropdownObject {
  menuIcon: string;
  menuItems: AppBarMenuItem[];
}
