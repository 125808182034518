import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "ifbp-bank-link-sent",
  templateUrl: "./bank-link-sent.component.html",
  styleUrls: ["./bank-link-sent.component.scss"],
})
export class BankLinkSentComponent {
  email: string;

  constructor(
    public dialogRef: MatDialogRef<BankLinkSentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      email: string;
    },
  ) {}
}
