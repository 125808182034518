import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "common";
import { AccountData, BrokerData } from "../model/account.model";

export interface AccountState {
  account: AccountData;
  broker: BrokerData;
}

const initialState: AccountState = null;

@Injectable({
  providedIn: "root",
})
export class AccountStateService extends StateService<AccountState> {
  constructor() {
    super(initialState);
  }

  getCurrentAccount$(): Observable<AccountData> {
    return this.select((state: AccountState) => state?.account);
  }

  getBroker$(): Observable<BrokerData> {
    return this.select((state: AccountState) => state?.broker);
  }

  setCurrentAccount(account: AccountData): void {
    const newState = {
      ...this.state,
      account,
    };
    this.setState(newState);
  }

  setBroker(broker: BrokerData): void {
    const newState = {
      ...this.state,
      broker,
    };
    this.setState(newState);
  }

  isCurrentAccountStateLoaded(): boolean {
    return !!this.state?.account;
  }

  isBrokerStateLoaded(id: number): boolean {
    return !!this.state?.broker && this.state.broker.id === id;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
