import { QueryParams, QueryResult } from "common";
import { hasValueFn } from "projects/partner/src/Tools";

export enum UserDbRole {
  ADMIN = "administrator",
  USER = "user",
}

export interface UserData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: UserDbRole;
  brokerId: number;
  mobilePhone: string;
  active: boolean;
  status: UserStatus;
}

export enum UserStatus {
  None = "none",
  Invited = "invited",
  Active = "active",
  Disabled = "disabled"
}

export const UserStatusLabel = new Map<UserStatus, string>([
  [UserStatus.None, "None"],
  [UserStatus.Invited, "Invited"],
  [UserStatus.Active, "Active"],
  [UserStatus.Disabled, "Disabled"],
]);


export type EditUserData = Omit<UserData, "id" | "status">;

export interface UserDataDetails {
  advisorFirstName: string;
  advisorLastName: string;
  advisorName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorCalendarLinkUrl: string;
  advisorAvatar: string;
  name: string;
}

export interface UsersQueryResult extends QueryResult<UserData> {}

interface UserQueryParamOptions extends QueryParams {
  active?: boolean;
  roles?: string;
}

export class UserQueryParams extends QueryParams {
  active?: boolean;
  roles?: string;

  constructor(params?: UserQueryParamOptions) {
    super(params);
    params = params ?? {};
    this.active = params.active ?? true;
    this.roles = params.roles ?? undefined;
  }
}

interface UserFilterOptions {
  active?: boolean | string;
  roles?: string;
}

export class UserFilters {
  active: boolean | undefined;
  roles: string | undefined;

  constructor(filters?: UserFilterOptions) {
    filters = filters ?? {};
    this.roles = filters.roles ?? undefined;
    this.active =
      hasValueFn(filters.active, v => v.toString() === "true") ?? true;
  }
}

export enum UserOverflowActions {
  DISABLE = "Disable",
  DELETE = "Delete",
}
