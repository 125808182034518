import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubmissionDetailsViews } from "../../../../shared/SharedConstants";
import { ApplicationStage, parseNumber } from 'common';
import { SubmissionDetailsService } from "../../domain/+state/submission-details.service";
import { SubmissionsBridgeService } from "../../domain/+state/submissions-bridge.service";

@Injectable({
  providedIn: "root",
})
export class SubmissionDetailsGuard implements CanActivate {
  constructor(
    private readonly submissionDetailsService: SubmissionDetailsService,
    private readonly bridgeService: SubmissionsBridgeService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const detailsViewString: string = route.url?.[route.url?.length - 1]?.path;
    const hasExistingDetailsView: boolean = (<any>Object).values(SubmissionDetailsViews).includes(detailsViewString);
    const applicationId = parseNumber(route.params?.["id"]);
    if (!applicationId || !hasExistingDetailsView) {
      console.error(`Incorrect data to show submission details view, id: ${applicationId}, details view name: ${hasExistingDetailsView}`);
      return false;
    }

    return this.bridgeService.getApplicationData(applicationId).pipe(
      map(data => {
        if (this.submissionDetailsService.isDetailsViewCorrectForBrokerStatus(<SubmissionDetailsViews>detailsViewString, data.brokerStatus, <ApplicationStage>data.stage)) {
          return true
        }
        this.submissionDetailsService.goToSubmissionDetails(<number>data.id, data.brokerStatus, <ApplicationStage>data.stage);
        return false;
      }),
    );
  }
}
