import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ShareOfferComponent } from "./share-offer.component";
import { NgChartsModule } from "ng2-charts";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { SendOfferEmailComponent } from "./send-offer-email/send-offer-email.component";
import { PlaidInfoComponent } from "./plaid-info/plaid-info.component";
import { SendBankLinkEmailComponent } from "./send-bank-link-email/send-bank-link-email.component";
import { BankLinkSentComponent } from "./bank-link-sent/bank-link-sent.component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, NgChartsModule],
  declarations: [
    BankLinkSentComponent,
    ShareOfferComponent,
    SendOfferEmailComponent,
    SendBankLinkEmailComponent,
    PlaidInfoComponent,
  ],
  exports: [
    BankLinkSentComponent,
    ShareOfferComponent,
    SendOfferEmailComponent,
    SendBankLinkEmailComponent,
    PlaidInfoComponent,
  ],
  providers: [PercentPipe, CurrencyPipe],
})
export class ShareOfferModule {}
