  <span class="business-information-form-greeting" fxLayoutAlign="center">Hello {{this.brokerUser.firstName}}, tell us about your client</span>
  <form class="flex column" [formGroup]="form">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
      <mat-form-field formGroupName="requiredToSave" appearance="outline" fxFlex="100" fxFlex.gt-xs="50" [class.wobble]="missingRequiredToSaveValues">
        <mat-label>Company Name (legal entity name)</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="form.get('requiredToSave.name')?.errors | hasControlError:['required']">Company name is required.</mat-error>
        <mat-error *ngIf="form.get('requiredToSave.name')?.errors | hasControlError:['maxlength']">Company name is too long.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>EIN</mat-label>
        <input
          matInput
          formControlName="ein"
          mask="00-0000000"
        />
        <mat-error *ngIf="form.get('ein')?.errors | hasControlError:['required']">EIN is required.</mat-error>
        <mat-error *ngIf="form.get('ein')?.errors | hasControlError:['maxlength']">EIN is too long.</mat-error>
        <mat-error *ngIf="form.get('ein')?.errors | hasControlError:['minlength']">EIN is too short.</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>Ownership</mat-label>
        <mat-select formControlName="entityType">
          <mat-option *ngFor="let option of ownershipOptions" [value]="option">
            {{option | enumValueLabel : EntityType : EntityTypeLabel}}
          </mat-option>
        </mat-select>
        <mat-error>Ownership is required.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>Primary phone number</mat-label>
        <input
          matInput
          formControlName="phone"
          mask="(000) 000-0000"
        />
        <mat-error *ngIf="form.get('phone')?.errors | hasControlError:['required']">Primary phone number is required.</mat-error>
        <mat-error *ngIf="form.get('phone')?.errors | hasControlError:['pattern']">Please enter a valid phone number.</mat-error>
      </mat-form-field>
    </div>
    <span class="business-information-form-subheader">Business address</span>
    <ifbp-address-form [form]="form.controls.address"></ifbp-address-form>
    <span class="business-information-form-subheader">Other business information</span>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>DBA</mat-label>
        <input
          matInput
          formControlName="dba"
        />
        <mat-error *ngIf="form.get('dba')?.errors | hasControlError:['maxlength']">DBA is too long.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>Time in business</mat-label>
        <mat-select
          matInput
          formControlName="timeInBusiness"
        >
          <mat-option *ngFor="let selectOption of timeInBusinessOptions"
                      [value]="selectOption.value">
            {{ selectOption.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('timeInBusiness')?.errors | hasControlError:['required']">Time in business is required.</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>Business description</mat-label>
        <input matInput formControlName="description" />
        <mat-error *ngIf="form.get('description')?.errors | hasControlError:['required']">Description is required.</mat-error>
        <mat-error *ngIf="form.get('description')?.errors | hasControlError:['maxlength']">Description is too long.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex.gt-xs="50">
        <mat-label>Monthly sales</mat-label>
        <input
          matInput
          formControlName="monthlySales"
          [prefix]="form.get('monthlySales').value ? '$' : ''"
          mask="separator.2"
          thousandSeparator=","
        />
        <mat-error *ngIf="form.get('monthlySales')?.errors | hasControlError:['max']">Monthly sales is too large.</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
      <mat-form-field appearance="outline" fxFlex.gt-xs="50" >
        <mat-label>Requested amount</mat-label>
        <input
          matInput
          formControlName="requestedAmount"
          [prefix]="form.controls.requestedAmount.value ? '$' : ''"
          mask="separator.2"
          thousandSeparator=","
        />

        <mat-error *ngIf="form.get('requestedAmount')?.errors | hasControlError:['max']">Requested amount is too large.</mat-error>
      </mat-form-field>
    </div>
  </form>
