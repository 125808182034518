import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { v1 as uuidv1 } from 'uuid';
import { OAuthService } from '../../oauth/oauth.service';
import { ConfigService } from '../../../config/config.service';
import { NavigationService } from '../../../navigation/navigation.service';

@Component({
  selector: 'ifc-saml-form',
  templateUrl: './saml-form.component.html'
})
export class SamlFormComponent implements OnInit, OnDestroy, AfterViewInit {

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private oauthService: OAuthService,
    private configService: ConfigService,
    private navigationService: NavigationService) {
    this.identityProviderUrl = configService.environment.auth.saml.identityProviderUrl;
  }

  identityProviderUrl: string;

  @ViewChild('form', { static: true })
  form: ElementRef;

  @ViewChild('samlRequestInput', { static: true })
  samlRequestInput: ElementRef;

  @ViewChild('relayStateInput', { static: true })
  relayStateInput: ElementRef;

  queryParams: SamlFormComponentQueryParams;

  ngOnInit() {
    this.subs = [
      this.route.queryParams.subscribe((it: SamlFormComponentQueryParams) => {
        this.queryParams = it;
      })
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(it => it.unsubscribe());
  }

  ngAfterViewInit() {
    if (this.queryParams && this.queryParams.oauthToken) {
      this.sessionBegin();
    }

    if (this.oauthService.authenticated) {
      this.redirect();
    } else {
      this.submit();
    }
  }

  private redirect() {
    this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true });
  }

  private get redirectUrl() {
    return this.navigationService.urlPrevious || '/';
  }

  private get requestXml() {
// eslint-disable-next-line max-len
    return `<samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" ID="ID-${uuidv1()}" Version="2.0" IssueInstant="${new Date().toJSON()}">
  <saml:Issuer>${this.configService.environment.auth.saml.serviceProviderEntityId}</saml:Issuer>
  <samlp:NameIDPolicy AllowCreate="true" Format="urn:oasis:names:tc:SAML:2.0:nameid-format:transient"/>
</samlp:AuthnRequest>`;
  }

  private sessionBegin() {
    this.oauthService.sessionBegin(
      '#$saml',
      this.queryParams.oauthToken,
      this.queryParams.expiresIn,
      this.queryParams.refreshToken);
  }

  private submit() {
    this.samlRequestInput.nativeElement.value = btoa(this.requestXml);
    this.relayStateInput.nativeElement.value = window.location.href;
    this.form.nativeElement.submit();
  }
}

export interface SamlFormComponentQueryParams {
  currentUrl?: string;
  oauthToken?: string;
  expiresIn?: number;
  refreshToken?: string;
}
