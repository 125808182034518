import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AppBarComponent,
  AppBarActionsComponent,
  AppBarCloseAction,
  AppBarTitleComponent,
  AppFooterComponent,
  AppPageComponent,
} from "common";
import { WebPaths } from "../shared/SharedConstants";
import { SubmissionFormComponent } from "./submission-form/submission-form.component";
import {
  BrokerCurrentResolver,
  BrokerDataDetailsResolver,
} from "../broker/infrastructure/broker.resolver";
import { SubmissionDetailsGuard } from "../feature-modules/submissions/infrastructure/guards/submission-details.guard";

const routes: Routes = [
  {
    path: "",
    component: AppPageComponent,
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-start",
        contentWithoutPadding: true,
      },
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.BACK,
        logoHidden: true,
        containerClass: "start-center",
      },
      appBarTitle: {
        content: "New submission",
      },
      appBarActions: {
        menuMobile: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: WebPaths.NewSubmission.slice(1),
        title: "New Submission – Idea Broker Portal",
        component: SubmissionFormComponent,
        resolve: {
          brokerDataDetails: BrokerDataDetailsResolver,
          brokerUser: BrokerCurrentResolver,
        },
      },
      {
        path: "submission/:id/edit",
        title: "Edit Submission – Idea Broker Portal",
        component: SubmissionFormComponent,
        canActivate: [SubmissionDetailsGuard],
        resolve: {
          brokerDataDetails: BrokerDataDetailsResolver,
          brokerUser: BrokerCurrentResolver,
        },
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubmissionRoutingModule {}
