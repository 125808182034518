import { Params } from "@angular/router";
import { SubmissionQueryParams } from "../../domain/models/submissions.model";
import { FilterChip } from "../../shared/filters-chip-list/filters-chip.model";
import { ObjectHelper } from "common";
import { BrokerStatusLabel } from "projects/partner/src/app/shared/SharedConstants";
import moment from "moment";
import { ActionButtonColor, ActionButtonType } from "projects/partner/src/app/shared/models/action-button/action-button.model";
import { SubmissionNoResultsFoundActionName } from "./submission-list.model";
import { NoResultsFoundData } from "../../shared/no-results-found/no-results-found.model";

export const submissionListDisplayedColumns = [
  "createdOn",
  "updatedOn",
  "accountName",
  "stage",
  "information",
  "loanNumber",
  "firstAgent",
  "assignedAgent",
  "overflow",
];

export const noResultsFoundData: NoResultsFoundData = {
  title: "No submissions found",
  actions: [
    {
      label: "+ NEW SUBMISSION",
      buttonColor: ActionButtonColor.Primary,
      buttonType: ActionButtonType.Flat,
      actionName: SubmissionNoResultsFoundActionName.NewSubmission
    }
  ]
};

export const noResultsForFiltersFoundData: NoResultsFoundData = {
  title: "No submissions found",
  body: "No results match the filter criteria. Clear filters to show results.",
  actions: [
    {
      label: "CLEAR FILTERS",
      buttonColor: ActionButtonColor.Basic,
      buttonType: ActionButtonType.Basic,
      actionName: SubmissionNoResultsFoundActionName.ClearFilters
    }
  ]
};

export const adaptSubmissionFilters = (data: Params, isFirstLoad?: boolean): SubmissionQueryParams => {
  return {
    pageNumber: data?.pageNumber || 1,
    pageSize: (!data?.pageSize || data.pageSize <= 0) ? 20 : data.pageSize,
    sort: data?.sort ?? (isFirstLoad ? "-updatedOn" : undefined),
    accountName: data?.accountName || undefined,
    brokerStatus: data?.brokerStatus?.length > 0 ? data?.brokerStatus : undefined,
    firstAgent: data?.firstAgent || undefined,
    assignedAgent: data?.assignedAgent || undefined,
    createdOn: data?.createdOn || undefined,
    createdOnFrom: data?.createdOnFrom ? moment(data.createdOnFrom).format('L') : undefined,
    createdOnTo: data?.createdOnTo ? moment(data.createdOnTo).format('L') : undefined,
    updatedOn: data?.updatedOn || undefined,
    updatedOnFrom: data?.updatedOnFrom ? moment(data.updatedOnFrom).format('L') : undefined,
    updatedOnTo: data?.updatedOnTo ? moment(data.updatedOnTo).format('L') : undefined,
  };
}

export const adaptFilterChips = (filters: SubmissionQueryParams): FilterChip[] => {
  if (!filters) {
    return [];
  }

  const chips: FilterChip[] = [];

  if (filters.accountName) {
    chips.push({
      id: 'accountName', label: 'Account name', value: filters.accountName.trim()
    });
  }

  if (filters.brokerStatus) {
    chips.push({
      id: 'brokerStatus', label: 'Status',
      value: ObjectHelper.makeArray(filters.brokerStatus).map(i => BrokerStatusLabel.get(i)).join(', ')
    });
  }

  if (filters.firstAgent) {
    chips.push({
      id: 'firstAgent', label: 'First agent', value: filters.firstAgent.trim()
    });
  }

  if (filters.assignedAgent) {
    chips.push({
      id: 'assignedAgent', label: 'Assigned agent', value: filters.assignedAgent.trim()
    });
  }

  if (filters.createdOnFrom) {
    chips.push({
      id: 'createdOn', label: 'Date created on',
      value: `${moment(filters.createdOnFrom).format('L')} - ${moment(filters.createdOnTo).format('L')}`
    });
  }

  if (filters.updatedOnFrom) {
    chips.push({
      id: 'updatedOn', label: 'Date updated on',
      value: `${moment(filters.updatedOnFrom).format('L')} - ${moment(filters.updatedOnTo).format('L')}`
    });
  }

  return chips;
}

export const removeFiltersByChips = <T>(filters: T, removedChips: FilterChip[]): T => {
  if (!removedChips?.length) {
    return filters;
  };

  const filtersAny: any = { ...filters };
  removedChips.forEach((item) => {
    delete filtersAny[item.id];
    if (item.id === 'createdOn' || item.id === 'createdOnFrom') {
      delete filtersAny.createdOn;
      delete filtersAny.createdOnFrom;
      delete filtersAny.createdOnTo;
    } else if (item.id === 'updatedOn' || item.id === 'updatedOnFrom') {
      delete filtersAny.updatedOn;
      delete filtersAny.updatedOnFrom;
      delete filtersAny.updatedOnTo;
    }
  });
  return filtersAny as T;
}

