<!-- from application project (release 4.0)
IdeaFinancial.Web/projects/application/src/app/shared/payment-schedule-table -->
<div fxLayout="column" class="full-width" *ngIf="dataSource.data?.length">

    <div fxLayout="row" fxLayoutAlign="end center" fxFlexFill class="secondary-text layout padding-8">
        <button mat-icon-button (click)="exporter.exportTable('csv', {fileName: 'Payment schedule'})" matTooltip="Download">
            <mat-icon>get_app</mat-icon>
        </button>
    </div>

    <ifc-app-scroll-container [default]="true">
       <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter">

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let payment" class="table-cell"> {{payment.date | date: 'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Amount </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align">
                {{payment.principalAmount + payment.interestAmount | currency:'$'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="principalAmount">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Principal payment </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment.principalAmount |
                currency:'$'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="interestAmount">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Interest payment </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment?.interestAmount |
                currency:'$'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Balance </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align"> {{payment?.principalBalance | currency:'$'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="totalInterestCost">
            <th mat-header-cell *matHeaderCellDef class="right-align"> Total interest cost </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align">
                {{payment.interestPaid | currency:'$'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="capitalRepayment">
            <th mat-header-cell *matHeaderCellDef class="right-align" style="display:none;"> Capital repayment </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align" style="display:none;"> 
                {{payment.capitalRepayment | currency:'$'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="totalPayback">
            <th mat-header-cell *matHeaderCellDef class="right-align" style="display:none;"> Total payback </th>
            <td mat-cell *matCellDef="let payment" class="table-cell right-align" style="display:none;">
                {{payment.totalPayback | currency:'$'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>

    </table>
    </ifc-app-scroll-container>
    
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 50]"></mat-paginator>

</div>