import { ApplicationStage } from "common";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";

export interface NotInterestedActionInputData {
  applicationId: number;
  brokerStatus: BrokerStatus;
  createOnDate: Date;
  previousStage: ApplicationStage;
}

export enum NotInterestedActionType {
  MarkAsNotInterested = "MarkAsNotInterested",
  Reactivate = "Reactivate"
}

export interface NotInterestedActionCompletedData {
  actionType: NotInterestedActionType;
  isSuccess: boolean;
}