import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes, RunGuardsAndResolvers } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarComponent,
  AppBarTitleComponent,
  AppPageComponent,
  AppSidenavComponent,
} from "common";

import { AppPageResolver, AppSidenavItemsResolver } from "../../app.resolver";
import { WebPaths } from "../../shared/SharedConstants";
import { AppBarDropdownComponent } from "../../shared/app-bar-dropdown/app-bar-dropdown.component";
import { UserListComponent } from "./features/user-list/user-list.component";
import { UserGuard } from "./infrastructure/user.guard";
import { UserDetailsComponent } from "./features/user-details/user-details.component";
import { UserDetailsGuard } from "./infrastructure/user-details.guard";
import {
  UserResolver,
  UsersResolver,
} from "./infrastructure/user.resolver";
import { appBarData, appPageData, appUserBarData } from "./infrastructure/users-page-data";
import { appBarDropdownData } from "../../app.data";
import { BrokerCurrentResolver } from "../../broker/infrastructure/broker.resolver";

const routes: Routes = [
  {
    path: "",
    component: AppPageComponent,
    data: {
      appBar: appBarData(),
      appBarDropdown: appBarDropdownData(),
    },
    resolve: {
      appPage: AppPageResolver,
      appSidenav: AppSidenavItemsResolver,
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarDropdownComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "sidenav-content",
        component: AppSidenavComponent,
      },
      {
        path: WebPaths.Team.slice(1),
        title: "Team – Idea Financial Partner",
        canActivate: [UserGuard],
        component: UserListComponent,
        runGuardsAndResolvers:
          "always" as RunGuardsAndResolvers,
        resolve: {
          brokerUsersQueryResult: UsersResolver,
          brokerUser: BrokerCurrentResolver,
        },
      },
    ]
  },
  {
    path: WebPaths.NewUser.slice(1),
    title: "New User - Idea Broker Portal",
    component: AppPageComponent,
    data: {
      appPage: appPageData(),
      appBar: appUserBarData(),
      appBarActions: {
        menuMobile: true,
      },
      appBarTitle: {
        content: "New User",
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: "",
        component: UserDetailsComponent,
        resolve: {
          currentBrokerUser: BrokerCurrentResolver,
        },
      },
    ],
  },
  {
    path: "user/:id/edit",
    title: "Edit User - Idea Broker Portal",
    component: AppPageComponent,
    canActivate: [UserDetailsGuard],
    data: {
      appPage: appPageData(),
      appBar: appUserBarData(),
      appBarActions: {
        menuMobile: true,
      },
      appBarTitle: {
        content: "Edit User",
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: "",
        component: UserDetailsComponent,
        resolve: {
          brokerUser: UserResolver,
          currentBrokerUser: BrokerCurrentResolver,
        },
      },
    ],
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
