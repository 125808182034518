import { Injectable } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { DashboardFacade } from "../../domain/+state/dashboard.facade";
import { HomeData } from "./home.model";

@Injectable()
export class HomeFacade {
  constructor(
    private readonly dashboardFacade: DashboardFacade,
  ) { }

  getHomeData$(): Observable<HomeData> {
    return combineLatest([
      this.dashboardFacade.getCustomerGreeting$(),
      this.dashboardFacade.getAdvisorData$(),
      this.dashboardFacade.getDashboardWidgets(true)
    ])
      .pipe(map(([greetings, advisorData, widgetsData]) => {
        return {
          greetings,
          advisorData,
          widgetsData
        }
      }))
  }
}
