import { Injectable } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { UserFilters, UserQueryParams } from "../../domain/models/users.model";
import { MatSort } from "@angular/material/sort";
import { hasValue, setQueryVarsFromPaginator } from "projects/partner/src/Tools";
import { MatTableHelper } from "common";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root"
})
export class UserListFacade { 

  constructor(private router: Router) {}
  
  filter(filters: UserFilters, paginator?: MatPaginator, sort?: MatSort) {
    const query = new UserQueryParams(filters);

    query.skip = undefined;

    if (hasValue(paginator)) {
      setQueryVarsFromPaginator(query, paginator);
    }

    if (hasValue(sort)) {
      query.sort = MatTableHelper.getSort(query.sort ?? "", sort);
    }

    void this.router.navigate([], {
      queryParams: query,
    });
  }
}
