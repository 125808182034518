import { Injectable } from "@angular/core";
import {
  MfaActionsService,
  MfaErrorCodeType,
  MfaService,
  VerifyMfaCodeData,
} from "common";
import { AccountFacade } from "../../../domain/+state/account.facade";
import { MfaTokenPayload, ResetPasswordRequest, ResetPasswordResponse } from "../../../domain/model/account.model";

@Injectable()
export class MfaResetPasswordService {
  constructor(
    private mfaActions: MfaActionsService,
    private mfaService: MfaService,
    private accountFacade: AccountFacade
  ) {}

  verify(data: VerifyMfaCodeData) {
    const request: ResetPasswordRequest = {
      token: data.mfaData.contextData?.token ?? "",
      confirmPassword: data.mfaData.contextData?.confirmPassword ?? "",
      newPassword: data.mfaData.contextData?.password ?? "",
    };
    const token: MfaTokenPayload = {
      mfaToken: data.mfaData.mfaToken,
      mfaCode: data.mfaCode,
    };
    return this.accountFacade
      .resetPassword(request, token)
      .subscribe((data: ResetPasswordResponse) => {
        this.handleMfaChangePasswordResponse(data);
      });
  }

  private handleMfaChangePasswordResponse(data: ResetPasswordResponse) {
    if (!data?.hasError) {
      this.mfaService.handleMfaSuccess();
      return;
    }

    if (data?.message === MfaErrorCodeType.InvalidMfaCode) {
      this.mfaActions.invalidCode();
      return;
    }

    this.mfaService.handleMfaError();
  }
}
