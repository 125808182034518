<span class="documents-and-cash-flow-form-greeting" fxLayoutAlign="center">Hello {{this.brokerUser.firstName}}, tell us about your client</span>
<div fxLayout="column" fxLayoutGap="12px">
  <div class="section-wrapper" fxLayout="row" fxLayoutGap="12px">
    <div class="files-info-column" fxLayout="column" fxLayoutGap="6px">
      <div class="files-info-header">
        <div fxLayout="column" fxLayoutGap="6px">
          <div>Last 3 months bank statement (Required)</div>
          <div class="mat-gray">Upload any PDF, JPEG, PNG copies (up to {{maxBankStatementCount}} files)</div>
        </div>
        <button mat-button class="upload-button" *ngIf="remainingBankStatementCount > 0" (click)="openBankStatementsDialog()">UPLOAD</button>
      </div>
      <div *ngFor="let bankStatementFile of form.controls.bankStatementFiles.value; let fileIndex = index" class="flex row file-row" fxLayoutAlign="start center">
        <div>{{ bankStatementFile?.name }}</div>
        <span *ngIf="!bankStatementFile.meta.id" class="unsaved-indicator">Unsaved</span>
        <button mat-icon-button class="cancel-button" type="button" *ngIf="allowedFileDeletion || !bankStatementFile.meta.id" (click)="removeBankStatementFile(fileIndex, bankStatementFile)">
          <mat-icon class="mat-gray">cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="section-wrapper" fxLayout="row" fxLayoutGap="12px">
    <div class="files-info-column" fxLayout="column" fxLayoutGap="6px">
      <div class="files-info-header">
        <div fxLayout="column" fxLayoutGap="6px">
          <div>Signed application (Optional)</div>
          <div class="mat-gray">Upload any PDF, JPEG, PNG copies (up to {{maxSignedApplicationCount}} files)</div>
        </div>
        <button mat-button class="upload-button" *ngIf="remainingSignedApplicationCount > 0" (click)="openSignedApplicationDialog()">UPLOAD</button>
      </div>
      <div *ngFor="let signedApplicationFile of form.controls.signedApplicationFiles.value; let fileIndex = index" class="flex row file-row" fxLayoutAlign="start center">
        <div>{{ signedApplicationFile?.name }}</div>
        <span *ngIf="!signedApplicationFile.meta.id" class="unsaved-indicator">Unsaved</span>
        <button mat-icon-button class="cancel-button" type="button" *ngIf="allowedFileDeletion || !signedApplicationFile.meta.id" (click)="removeSignedApplicationFile(fileIndex, signedApplicationFile)">
          <mat-icon class="mat-gray">cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>