<article class="container">
  <header class="header">
    <img src="../../../../../assets/img/empty_icon.png"
         alt="Empty icon">
    <h2 class="heading">{{data?.title || 'No results found'}}</h2>
  </header>
  <section *ngIf="data?.body">
    <div class="body"
         [innerHTML]="data.body | safePureHtml"></div>
  </section>
  <footer class="actions"
          *ngIf="data?.actions">
    <button *ngFor="let action of data.actions"
            class="actions__item"
            (click)="onClickAction(action)"
            [class.mat-raised-button]="action.buttonType === ButtonType.Raised"
            [class.mat-stroked-button]="action.buttonType === ButtonType.Stroked"
            [class.mat-flat-button]="action.buttonType === ButtonType.Flat"
            mat-button
            [color]="action.buttonColor">
      {{action.label}}
    </button>
  </footer>
</article>
