import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NotInterestedActionCompletedData, NotInterestedActionInputData, NotInterestedActionType } from './not-interested-action.model';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';
import { NotInterestedActionFacade } from './not-interested-action.facade';
import { DialogActionComponent, DialogActionData } from 'common';
import { markAsNotInterestedSuccessMessage, reactivateSubmissionDialogData, reactivateSubmissionSuccessMessage } from './not-interested-action-data';
import { MatDialog } from '@angular/material/dialog';
import { NotInterestedDialogComponent } from '../not-interested-dialog/not-interested-dialog.component';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'ifbp-not-interested-action',
  templateUrl: './not-interested-action.component.html',
  providers: [NotInterestedActionFacade]
})
export class NotInterestedActionComponent implements OnChanges {
  isActionAvailable: boolean;
  readonly BrokerStatus = BrokerStatus;

  @Input()
  isMenuItem?: boolean;

  @Input()
  data: NotInterestedActionInputData;

  @Output()
  actionComplete = new EventEmitter<NotInterestedActionCompletedData>();

  constructor(
    private readonly facade: NotInterestedActionFacade,
    private readonly dialog: MatDialog) { }

  ngOnChanges(): void {
    this.isActionAvailable = this.facade.checkIfIsActionAvailable(this.data);
  }

  onClickMarkAsNotInterested() {
    this.facade.getNotInterestedDialogData().pipe(
      take(1),
      switchMap(data =>  NotInterestedDialogComponent.show(this.dialog, data)),
      filter(result => !!result),
      switchMap(result => this.facade.updateApplicationStage(this.data.applicationId,
        this.facade.prepareUpdateApplicationPayloadFromDialogResult(result),
        markAsNotInterestedSuccessMessage)),
      tap(updateResult =>
        this.emitActionComplete(updateResult as NotInterestedActionCompletedData, NotInterestedActionType.MarkAsNotInterested))
    ).subscribe();
  }

  onClickReactivate() {
    const dialogData: DialogActionData = reactivateSubmissionDialogData;
    DialogActionComponent.show(this.dialog, dialogData).subscribe((result) => {
      if (result) {
        this.facade.updateApplicationStage(this.data.applicationId, { stage: this.data.previousStage }, reactivateSubmissionSuccessMessage)
          .subscribe((data: NotInterestedActionCompletedData) => {
            this.emitActionComplete(data, NotInterestedActionType.Reactivate);
          })
      }
    });
  }

  private emitActionComplete(data: NotInterestedActionCompletedData, actionType: NotInterestedActionType) {
    this.actionComplete.emit({
      ...data,
      actionType
    });
  }
}
