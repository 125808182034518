import { NgModule } from "@angular/core";
import { ResetPasswordRoutingModule } from "./reset-password-routing.module";
import { ResetPasswordFormComponent } from "./reset-password-form/reset-password-form.component";
import { ResetPasswordDoneComponent } from "./reset-password-done/reset-password-done.component";
import { SharedModule } from "projects/partner/src/app/shared/shared.module";

@NgModule({
  imports: [SharedModule, ResetPasswordRoutingModule],
  declarations: [ResetPasswordFormComponent, ResetPasswordDoneComponent],
})
export class ResetPasswordModule {}
