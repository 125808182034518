<div fxLayout="column" fxLayoutAlign="start start" style="width: 100%" class="layout fill team-container">
    <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%">
      <span class="mat-headline">Team</span>
      <button mat-flat-button color="primary" class="new-team-member-button" [routerLink]="WebPaths.NewUser">
        + TEAM MEMBER
      </button>
    </div>
    <div class="table-container">
      <table *ngIf="brokerUsers.values.length > 0"
        mat-table 
        matSort
        [matSortActive]="defaultSortActive" 
        [matSortDirection]="defaultSortDirection"
        class="team-table" 
        [dataSource]="brokerUsers.values"
        (matSortChange)="filter()"
      >
  
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">First name</th>
          <td mat-cell *matCellDef="let brokerUser">{{brokerUser.firstName}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">Last name</th>
          <td mat-cell *matCellDef="let brokerUser">{{brokerUser.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="mobilePhone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="mobilePhone">Phone</th>
          <td mat-cell *matCellDef="let brokerUser">{{brokerUser.mobilePhone | phone}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
          <td mat-cell *matCellDef="let brokerUser">{{brokerUser.email}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
          <td mat-cell *matCellDef="let brokerUser">{{brokerUser.status | enumValueLabel : UserStatus : UserStatusLabel}}</td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="role">Role</th>
          <td mat-cell *matCellDef="let brokerUser">{{teamUserRoleUiMap.get(brokerUser.role)}}</td>
        </ng-container>
        <ng-container matColumnDef="overflow">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let brokerUser">
            <button 
              class="overflow-button" 
              mat-icon-button [matMenuTriggerFor]="userMenu" 
              #menuTrigger="matMenuTrigger" 
              [ngClass]="{'show-overflow-button': menuTrigger.menuOpen}" 
              (click)="$event.stopPropagation()"
              (menuOpened)="menuOpenedRow = brokerUser"
              (menuClosed)="menuOpenedRow = null"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item (click)="goToUserDetails(brokerUser.id)">
                <span>Edit</span>
              </button>
              <button *ngIf="brokerUser.active" mat-menu-item (click)="openConfirmDialog(UserOverflowActions.DISABLE, brokerUser)">
                <span>Disable</span>
              </button>
              <button *ngIf="brokerUser.status === UserStatus.Invited" mat-menu-item (click)="resendInvitation(brokerUser)">
                <span>Resend activation email</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr 
          mat-row 
          *matRowDef="let row; columns: displayedColumns;" 
          [ngClass]="{
            'logged-in-user-row': row.id === currentBrokerUser.id, 
            'selected-row': row.id === menuOpenedRow?.id
          }" 
          (click)="goToUserDetails(row.id)"
          ></tr>
  
      </table>
      <div *ngIf="brokerUsers.values.length === 0">
        <span class="no-data-text">No data</span>
      </div>
    </div>
    <mat-paginator 
      #paginator 
      showFirstLastButtons 
      [length]="brokerUsers.totalCount"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="filter()"
    >
    </mat-paginator>
  </div>
  