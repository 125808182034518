import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-incomplete-submission-dialog",
  templateUrl: "./incomplete-submission-dialog.component.html",
  styleUrls: ["./incomplete-submission-dialog.component.scss"],
})
export class IncompleteSubmissionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { missingInformation: string },
  ) {}
}
