<div class="wrapper">
  <span class="label label--heading">Filters: </span>
  <span *ngIf="!filters.length"
        class="label">None</span>
  <span *ngIf="filters.length"
        class="label label--applied">Applied</span>
  <mat-chip-list class="filters">
    <mat-chip *ngFor="let filter of filters"
              (removed)="remove(filter)">
      {{filter.label}}: {{filter.value}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <button *ngIf="filters.length"
          mat-button
          (click)="clear()">CLEAR</button>
</div>
