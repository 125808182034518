import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { AppBarDropdownComponent } from "./app-bar-dropdown/app-bar-dropdown.component";
import { AddressFormComponent } from "./address-form/address-form.component";
import { UploadFileDialogComponent } from "./upload-file-dialog/upload-file-dialog.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { SubmissionStatusComponent } from "./submission-status/submission-status.component";
import { ShareOfferModule } from "./share-offer/share-offer.module";
import { InArrayPipe } from "./pipes/in-array.pipe";
import { UploadFileDialogImprovedComponent } from "./upload-file-dialog-improved/upload-file-dialog-improved.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    UppyAngularDashboardModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    AddressFormComponent,
    UploadFileDialogComponent,
    AppBarDropdownComponent,
    SubmissionStatusComponent,
    ShareOfferModule,
    InArrayPipe,
    NgxSkeletonLoaderModule
  ],
  declarations: [
    AddressFormComponent,
    UploadFileDialogComponent,
    UploadFileDialogImprovedComponent,
    AppBarDropdownComponent,
    SubmissionStatusComponent,
    InArrayPipe,
  ],
})
export class SharedModule {}
