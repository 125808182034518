import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppSettings } from "projects/partner/src/app.settings";
import { ApiUrls } from "../../SharedConstants";

@Injectable({
  providedIn: "root",
})
export class BankLinkEmailService {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  sendBankLinkEmail(applicationId: number, email: string): Observable<any> {
    return this.http.post(
      ApiUrls(this.settings).SendBankLinkEmail({
        application: { id: applicationId },
      }),
      {
        email,
      },
    );
  }
}
