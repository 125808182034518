import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppBarComponent, AppBarContactComponent, AppFooterComponent, AppPageComponent } from "common";
import { SignInGuard } from "../../infrastructure/guards/sign-in.guard";
import { SignInComponent } from "./sign-in.component";

const routes: Routes = [
  {
    path: "sign-in",
    component: AppPageComponent,
    canActivate: [SignInGuard],
    title: "Login – Idea Financial Partner",
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: "simple",
      },
      appBar: {
        logoSize: "logo-s",
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        component: SignInComponent,
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignInRoutingModule { }
