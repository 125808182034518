import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { OpenDetailsRoutingModule } from "./open-details-routing.component";
import { OpenDetailsComponent } from "./open-details.component";

@NgModule({
  imports: [CommonModule, OpenDetailsRoutingModule, SharedModule],
  declarations: [OpenDetailsComponent],
})
export class OpenDetailsModule {}
