import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { Competitor, CompetitorsQueryResult } from "../model/competitors.model";
import { CompetitorsRemote } from "../../infrastructure/competitors.remote";
import { CompetitorsStateService } from "./competitors.state.service";

@Injectable({
    providedIn: "root"
})
export class CompetitorsFacade {
  constructor( 
    private readonly competitorsStateService: CompetitorsStateService,
    private competitorsService: CompetitorsRemote,
  ){}

  getCompetitors$(): Observable<Competitor[]> {
    if (this.competitorsStateService.isCompetitorsStateLoaded()) {
      return this.competitorsStateService.getCompetitors$();
    }
    return this.refreshCompetitors$();
  }

  private refreshCompetitors$(): Observable<Competitor[]> {
    return this.competitorsService.getAllCompetitors().pipe(
      tap((competitors: CompetitorsQueryResult) =>
        this.competitorsStateService.setCompetitors(competitors.values)),
      switchMap(() => this.competitorsStateService.getCompetitors$())
    );
  }
}