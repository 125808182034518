<!-- based on component in application project (release 4.0)
IdeaFinancial.Web/projects/application/src/app/shared/share-offer -->
<div fxLayout="column" fxLayoutGap="24px" class="share-offer-container">
  <div fxLayout="row">
    <div class="link-container">
      <span class="primary-text">Share offer:</span>
      <button mat-icon-button type="button" (click)="sendEmailOffer()" class="mat-green-bg small-icon-button"  >
        <mat-icon class="mat-white">send</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="copy()" class="mat-green-bg  small-icon-button">
        <mat-icon class="mat-white " svgIcon="">content_copy</mat-icon>
      </button>
    </div>
    <div class="link-container">
      <span>Customer view:</span>
      <button mat-icon-button type="button" class="mat-green-bg small-icon-button" (click)="customerView()">
        <mat-icon class="mat-white">visibility</mat-icon>
      </button>
    </div>
    <div *ngIf="showPlaidLink" class="link-container">
      <span>
        Plaid link
        <span class="help-button-wrapper">
          <button mat-icon-button class="help-button" (click)="openPlaidInfoDialog()">
            <mat-icon>help</mat-icon>
          </button>
        </span>
        :
      </span>
      <button 
        mat-icon-button 
        type="button" 
        class="mat-green-bg small-icon-button"
        (click)="openSendBankLinkEmailDialog()"
      >
        <mat-icon class="mat-white">account_balance</mat-icon>
      </button>
    </div>
    <div *ngIf="showCustomerRegistration" class="link-container">
      <span>
        Customer registration
        <span class="help-button-wrapper">
          <button mat-icon-button class="help-button">
            <mat-icon>help</mat-icon>
          </button>
        </span>
        :
      </span>
      <button mat-icon-button type="button" class="mat-green-bg small-icon-button">
        <mat-icon class="mat-white">how_to_reg</mat-icon>
      </button>
    </div>
  </div>
</div>
