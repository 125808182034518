import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ApplicationStage, ApplicationStatus, MessageService, OfferBundleStatus } from "common";
import { AppSettings } from "../../app.settings";
import {
  ApplicationFileCategory,
  ApplicationFilesResponse,
  BusinessInformationGetData,
  AccountResponse,
  OwnerInformationGetData,
  ApplicationRequestData,
  ApplicationResponse,
  BusinessInformationRequestData,
  AccountRequestData,
  ErrorResponse,
  NewSubmissionResponse,
  UppyInput,
  OwnerData,
} from "../submission/models/submission.model";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ApiUrls } from "./SharedConstants";
import { UppyFile } from "@uppy/core";
import { AccountData, SubmissionQueryParams, SubmissionsQueryResult } from "./submissions.model";
import {
  defaultObservableError,
  defaultObservableErrorHandler,
  hasValue,
} from "../../Tools";

@Injectable({
  providedIn: "root",
})
export class SubmissionService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private settings: AppSettings,
  ) {}

  getSubmissions(
    params: SubmissionQueryParams,
  ): Observable<SubmissionsQueryResult> {
    const url = ApiUrls(this.settings).GetSubmissions;
    return this.http.get<SubmissionsQueryResult>(url, { params: <any>params });
  }

  saveBusinessInformation(
    accountId: number | undefined,
    applicationId: number | undefined,
    body: BusinessInformationRequestData,
  ): Observable<ApplicationResponse | ErrorResponse> {
    const { requestedAmount, ...otherFields } = body;
    return this.saveAccountData(accountId, {
      ...otherFields,
      status: "active",
    }).pipe(
      mergeMap((data: any) =>
        {
          let request: ApplicationRequestData = {
            requestedAmount,
            accountId: accountId ?? data.id,
            brokerId: body.brokerId
          };

          if (!applicationId) {
            request.stage = ApplicationStage.New;
          }

          return this.saveApplicationData(applicationId, request);
        }
      ),
      catchError(defaultObservableErrorHandler(this.messageService)),
    );
  }

  saveAccountData(
    idToEdit: number | undefined,
    body: AccountRequestData,
  ): Observable<AccountRequestData | ErrorResponse> {
    const ein = hasValue(body.ein) && body.ein.length > 0 ? body.ein : null;
    const requestBody = { ...body, ein, active: true };
    const { httpClientFunction, url } = hasValue(idToEdit)
      ? {
          httpClientFunction: this.http.patch.bind(this.http),
          url: ApiUrls(this.settings).SaveExistingAccount({
            account: { id: idToEdit },
          }),
        }
      : {
          httpClientFunction: this.http.post.bind(this.http),
          url: ApiUrls(this.settings).SaveNewAccount,
        };
    return httpClientFunction<AccountRequestData>(url, requestBody).pipe(
      catchError(defaultObservableErrorHandler(this.messageService)),
    );
  }

  saveApplicationData(
    idToEdit: number | undefined,
        body: ApplicationRequestData,
  ): Observable<ApplicationResponse | ErrorResponse> {
    const { httpClientFunction, url } = hasValue(idToEdit)
      ? {
          httpClientFunction: this.http.patch.bind(this.http),
          url: ApiUrls(this.settings).SaveExistingApplication({
            application: { id: idToEdit },
          }),
        }
      : {
          httpClientFunction: this.http.post.bind(this.http),
          url: ApiUrls(this.settings).SaveNewApplication,
        };
    return httpClientFunction<ApplicationResponse>(url, {
      ...body,
      active: true,
    }).pipe(
      map(response => ({
        ...response,
        accountId: body.accountId,
      })),
      catchError(defaultObservableErrorHandler(this.messageService)),
    );
  }

  saveOwnerInformation(
    applicationId: number,
    accountId: number,
    brokerId: number,
    idToEdit: number | undefined,
    body: OwnerData,
  ): Observable<OwnerData | ErrorResponse> {
    const email =
      hasValue(body.email) && body.email.length > 0 ? body.email : null;
    const requestBody = {
      ...body,
      email,
      active: true,
      accountId,
      applicationId,
      brokerId,
    };
    const { httpClientFunction, url } = hasValue(idToEdit)
      ? {
          httpClientFunction: this.http.patch.bind(this.http),
          url: ApiUrls(this.settings).SaveExistingOwner({
            owner: { id: idToEdit },
          }),
        }
      : {
          httpClientFunction: this.http.post.bind(this.http),
          url: ApiUrls(this.settings).SaveNewOwner,
        };
    return httpClientFunction<OwnerData>(url, requestBody).pipe(
      catchError(defaultObservableErrorHandler(this.messageService)),
    );
  }

  saveApplicationFile(
    applicationId: number,
    category: ApplicationFileCategory,
    file: UppyFile | UppyInput,
  ): Observable<number[] | ErrorResponse> {
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("type", file.type as string);
    formData.append("file", file.data);
    return this.http
      .post(
        ApiUrls(this.settings).SaveApplicationFile({
          application: { id: applicationId },
          category,
        }),
        formData,
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  submitNewSubmission(
    applicationId: number,
  ): Observable<NewSubmissionResponse | ErrorResponse> {
    return this.http
      .patch(
        ApiUrls(this.settings).SubmitNewApplication({
          application: { id: applicationId },
        }),
        { stage: ApplicationStage.ApplicationCreated },
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getBusinessInformationData(
    applicationId: number,
  ): Observable<BusinessInformationGetData> {
    return this.getApplicationData(applicationId).pipe(
      mergeMap((applicationData: ApplicationResponse) =>
        this.getAccountData(applicationData.accountId).pipe(
          map((accountData: AccountResponse) => ({
            ...applicationData,
            ...accountData,
          })),
        ),
      ),
    );
  }

  getApplicationData(id: number): Observable<ApplicationResponse> {
    return this.http
      .get<ApplicationResponse>(
        ApiUrls(this.settings).GetApplication({
          application: { id },
        }),
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getAccountData(id?: number): Observable<AccountResponse> {
    if (!hasValue(id)) {
      const message = "Error occured while getting entity information.";
      return defaultObservableError(message, this.messageService);
    }
    return this.http
      .get<AccountResponse>(
        ApiUrls(this.settings).GetAccount({ account: { id } }),
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getOwnerInformationData(
    applicationId: number,
  ): Observable<OwnerInformationGetData> {
    return this.http
      .get<OwnerInformationGetData>(
        ApiUrls(this.settings).GetOwners({
          application: { id: applicationId },
        }),
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getDocumentsAndCashFlowData(
    id: number,
  ): Observable<ApplicationFilesResponse> {
    return this.http
      .get<ApplicationFilesResponse>(
        ApiUrls(this.settings).GetApplicationFiles({ application: { id } }),
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getDocumentsAndCashFlowFile(
    applicationId: number,
    fileId: number,
  ): Observable<Blob> {
    return this.http
      .get(
        ApiUrls(this.settings).GetApplicationFile({
          application: { id: applicationId },
          file: { id: fileId },
        }),
        { responseType: "blob" },
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  updateApplicationStage(
    applicationId: number,
    stage?: ApplicationStage,
  ): Observable<ApplicationRequestData> {
    return this.http
      .patch<ApplicationRequestData>(
        ApiUrls(this.settings).UpdateApplicationStage({
          application: { id: applicationId },
        }),
        { stage },
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getAccountDetails(id: number): Observable<AccountData> {
    const url = ApiUrls(this.settings).GetAccountDetails({ loan: { id } });
    return this.http.get<AccountData>(url);
  }

  sendCheckout(applicationId: number) {
    return this.http
      .post(
        ApiUrls(this.settings).SendCheckout({
          application: { id: applicationId },
        }),
        {},
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  resendCheckout(applicationId: number) {
    return this.http
      .post(
        ApiUrls(this.settings).ResendCheckout({
          application: { id: applicationId },
        }),
        {},
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getCheckoutRequirementFile(
    checkoutRequirementId: number,
    fileId: number,
  ): Observable<Blob> {
    return this.http
      .get(
        ApiUrls(this.settings).GetCheckoutRequirementFile({
          checkoutRequirement: { id: checkoutRequirementId },
          file: { id: fileId },
        }),
        { responseType: "blob" },
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  saveCheckoutRequirementFile(
    checkoutRequirementId: number,
    category: string,
    file: UppyFile | UppyInput,
  ): Observable<ErrorResponse> {
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("type", file.type as string);
    formData.append("file", file.data);
    return this.http
      .post(
        ApiUrls(this.settings).SaveCheckoutRequirementFile({
          checkoutRequirement: { id: checkoutRequirementId },
          category,
        }),
        formData,
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  submitOfferBundle(offerBundleId: number) {
    return this.http.patch(
      ApiUrls(this.settings).SubmitOfferBundle({
        offerBundle: { id: offerBundleId },
      }),
      { status: OfferBundleStatus.Accepted },
    );
  }

  deleteApplicationFile(applicationId: number, fileId: number): Observable<void> {
    return this.http.delete<void>(ApiUrls(this.settings).DeleteApplicationFile(applicationId, fileId))
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }
}
