<ng-container *ngIf="isActionAvailable">
  <ng-container *ngIf="isMenuItem; else standardAction">
    <button *ngIf="data?.brokerStatus === BrokerStatus.NOT_INTERESTED"
            mat-menu-item
            (click)="onClickReactivate()">Reactivate</button>
    <button *ngIf="data?.brokerStatus !== BrokerStatus.NOT_INTERESTED"
            mat-menu-item
            (click)="onClickMarkAsNotInterested()">Mark as not interested</button>
  </ng-container>

  <ng-template #standardAction>
    <button *ngIf="data?.brokerStatus === BrokerStatus.NOT_INTERESTED"
            mat-button
            (click)="onClickReactivate()">REACTIVATE</button>
    <button *ngIf="data?.brokerStatus !== BrokerStatus.NOT_INTERESTED"
            mat-button
            (click)="onClickMarkAsNotInterested()">MARK AS NOT INTERESTED</button>
  </ng-template>
</ng-container>
