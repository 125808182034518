import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountFacade } from "../../../domain/+state/account.facade";
import { WebPaths, passwordValidatorRegex } from "projects/partner/src/app/shared/SharedConstants";
import { CookieService } from "ngx-cookie-service";

@UntilDestroy()
@Component({
  selector: "ifbp-reset-password-form",
  templateUrl: "./reset-password-form.component.html",
  styleUrls: ["./reset-password-form.component.scss"],
})
export class ResetPasswordFormComponent implements OnInit {
  token: string;
  loading: boolean;
  hasVisited: boolean;
  form: UntypedFormGroup;

  readonly WebPaths = WebPaths;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly accountFacade: AccountFacade,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly cookieService: CookieService
  ) {
    this.initData();
    this.initForm();
  }

  ngOnInit() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.token = params.token;
    });
  }

  private initData() {
    this.loading = false;
    const hasVisited = this.cookieService.check("hasVisitedPortalLogin");

    if (!hasVisited) {
      this.cookieService.set("hasVisitedPortalLogin", "");
    }
  }

  private initForm() {
    this.form = this.formBuilder.group(
      {
        newPassword: new UntypedFormControl("", [Validators.required, Validators.pattern(passwordValidatorRegex)]),
        confirmPassword: new UntypedFormControl("", Validators.required),
      }
    );
    this.form.setValidators(this.passwordMatchingValidator())
  }

  private passwordMatchingValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const newPasswordControl = group.controls['newPassword'];
      const confirmPasswordControl = group.controls['confirmPassword'];

      if (confirmPasswordControl?.errors) {
        return null;
      }

      if (newPasswordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ mustMatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }

      return null;
    };
  }

  resetPassword() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.accountFacade
      .resetPassword({
        token: this.token,
        newPassword: this.form.get("newPassword")?.value,
        confirmPassword: this.form.get("confirmPassword")?.value,
      })
      .pipe(untilDestroyed(this))
      .subscribe(_ => (this.loading = false));
  }
}
