import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  BrokerDataDetails,
  BrokerEditUserData,
  BrokerPasswordChangeRequest,
  BrokerProfileData,
  BrokerUserData,
  BrokerUsersQueryResult,
} from "../domain/models/broker.model";
import { HttpClient } from "@angular/common/http";
import { AppSettings } from "projects/partner/src/app.settings";
import { ApiUrls } from "../../shared/SharedConstants";

@Injectable({ providedIn: "root" })
export class BrokerRemote {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  getCurrent(): Observable<BrokerUserData> {
    const url = ApiUrls(this.settings).GetCurrentUserDetails;
    return this.http.get<BrokerUserData>(url);
  }

  getBrokerDataDetails(brokerId: number): Observable<BrokerDataDetails> {
    const url = ApiUrls(this.settings).GetBrokerDetails({
      user: { brokerId },
    });
    return this.http.get<BrokerDataDetails>(url);
  }

  getBrokerUsers(
    brokerId: number,
    params: any,
  ): Observable<BrokerUsersQueryResult> {
    const url = ApiUrls(this.settings).GetBrokerUsers;
    return this.http.get<BrokerUsersQueryResult>(url, {
      params: { ...params, brokerId },
    });
  }

  getBrokerUser(id: number): Observable<BrokerUserData> {
    const url = ApiUrls(this.settings).GetBrokerUser({ user: { id } });
    return this.http.get<BrokerUserData>(url);
  }

  createBrokerUser(data: BrokerEditUserData): Observable<BrokerUserData> {
    const url = ApiUrls(this.settings).CreateBrokerUser;
    return this.http.put<BrokerUserData>(url, { ...data, active: true });
  }

  updateBrokerUser(
    id: number,
    data: BrokerProfileData,
  ): Observable<BrokerUserData> {
    const url = ApiUrls(this.settings).EditBrokerUser({ user: { id } });
    return this.http.patch<BrokerUserData>(url, data);
  }

  resendInvitation(brokerUserId: number): Observable<any> {
    return this.http.post(ApiUrls(this.settings).ResendInvitation(brokerUserId), {});
  }

  deleteBrokerUser(id: number): Observable<BrokerUserData> {
    const url = ApiUrls(this.settings).DeleteBrokerUser({ user: { id } });
    return this.http.delete<BrokerUserData>(url);
  }

  updateBrokerPassword(
    id: number,
    data: BrokerPasswordChangeRequest,
  ): Observable<void> {
    return this.http.put<void>(
      ApiUrls(this.settings).UpdatePassword({ user: { id } }),
      data,
    );
  }
}
