import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserDbRole } from "../../../broker/domain/models/broker.model";
import { WebPaths } from "../../../shared/SharedConstants";
import { UsersBridgeService } from "../domain/+state/users-bridge.service";

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  constructor(private bridgeService: UsersBridgeService, private router: Router) { }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.bridgeService.getCurrentBroker$().pipe(
      take(1),
      map(brokerUser => {
        if (brokerUser.role === UserDbRole.ADMIN) {
          return true;
        }
        return this.router.createUrlTree([WebPaths.Dashboard]);
      }),
    );
  }
}
