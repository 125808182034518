// from application project (release 4.0)
// IdeaFinancial.Web/projects/application/src/app/shared/payment-schedule-table
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonModule } from "common";
import { PaymentScheduleTableComponent } from "./payment-schedule-table.component";
import { MatTableExporterModule } from "mat-table-exporter";

@NgModule({
  declarations: [PaymentScheduleTableComponent],
  exports: [PaymentScheduleTableComponent],
  imports: [CommonModule, FlexLayoutModule, MatTableExporterModule],
})
export class PaymentScheduleTableModule {}
