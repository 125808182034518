import { Component, Input, OnInit } from "@angular/core";
import { UppyFile } from "@uppy/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ApplicationStage, } from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UntypedFormGroup } from "@angular/forms";
import {
  BrokerDataDetails,
  BrokerUserData,
} from "../../broker/domain/models/broker.model";
import { UploadFileDialogImprovedComponent } from "../../shared/upload-file-dialog-improved/upload-file-dialog-improved.component";

@UntilDestroy()
@Component({
  selector: "ifbp-documents-and-cash-flow-step",
  templateUrl: "./documents-and-cash-flow-step.component.html",
  styleUrls: ["./documents-and-cash-flow-step.component.scss"],
})
export class DocumentsAndCashFlowStepComponent implements OnInit {
  @Input() brokerUser: BrokerUserData;
  @Input() brokerDataDetails: BrokerDataDetails;
  @Input() form: UntypedFormGroup; //definition is in submission.data.ts as getDocumentsAndCashFlowForm

  readonly MAX_BANK_STATEMENT_COUNT_ON_NEW: number = 6;
  readonly MAX_SIGNED_APPLICATION_COUNT_ON_NEW: number = 4;
  readonly MAX_BANK_STATEMENT_COUNT_AFTER_NEW: number = 24;
  readonly MAX_SIGNED_APPLICATION_COUNT_AFTER_NEW: number = 24;

  maxBankStatementCount: number;
  maxSignedApplicationCount: number;
  remainingBankStatementCount: number;
  remainingSignedApplicationCount: number;
  allowedFileDeletion: boolean;

  constructor(private dialogService: MatDialog) { }

  ngOnInit(): void {
    this.initApplicationStageControl();
    this.initBankStatementsControl();
    this.initSignedApplicationsControl();
  }

  private initApplicationStageControl(): void {
    const formControl = this.form.get('applicationStage')!;
    this.setAllowedFileDeletion(formControl.value);
    this.configMaxFileCount(formControl.value)

    formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: ApplicationStage) => {
      this.setAllowedFileDeletion(value);
      this.configMaxFileCount(value);
    });
  }

  private setAllowedFileDeletion(stage: ApplicationStage): void {
    this.allowedFileDeletion = stage === ApplicationStage.New;
  }

  private configMaxFileCount(stage: ApplicationStage): void {
    this.maxBankStatementCount = stage === ApplicationStage.New 
      ? this.MAX_BANK_STATEMENT_COUNT_ON_NEW
      : this.MAX_BANK_STATEMENT_COUNT_AFTER_NEW;

    this.maxSignedApplicationCount = stage === ApplicationStage.New
      ? this.MAX_SIGNED_APPLICATION_COUNT_ON_NEW
      : this.MAX_SIGNED_APPLICATION_COUNT_AFTER_NEW;
  }

  private initBankStatementsControl(): void {
    const formControl = this.form.get('bankStatementFiles')!;
    this.updateRemainingBankStatementCount(formControl.value.length);

    formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.updateRemainingBankStatementCount(value.length);
    });
  }

  private updateRemainingBankStatementCount(fileCount: number): void {
    this.remainingBankStatementCount = this.maxBankStatementCount - fileCount;
  }

  private initSignedApplicationsControl(): void {
    const formControl = this.form.get('signedApplicationFiles')!;
    this.updateRemainingSignedApplicationCount(formControl.value.length);

    formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.updateRemainingSignedApplicationCount(value.length);
    });
  }

  private updateRemainingSignedApplicationCount(fileCount: number): void {
    this.remainingSignedApplicationCount = this.maxSignedApplicationCount - fileCount;
  }

  removeBankStatementFile(index: number, file: UppyFile): void {
    const currentBankStatementFiles = this.form.controls.bankStatementFiles.value;

    this.form.patchValue({
      bankStatementFiles: currentBankStatementFiles
        .slice(0, index)
        .concat(currentBankStatementFiles.slice(index + 1)),
    });

    this.markForDeletionIfUploaded(file);
  }

  removeSignedApplicationFile(index: number, file: UppyFile): void {
    const currentSignedApplicationFiles = this.form.controls.signedApplicationFiles.value;

    this.form.patchValue({
      signedApplicationFiles: currentSignedApplicationFiles
        .slice(0, index)
        .concat(currentSignedApplicationFiles.slice(index + 1)),
    });
    
    this.markForDeletionIfUploaded(file);
  }

  openBankStatementsDialog(): void {
    const dialogRef = this.openFileDialog(this.remainingBankStatementCount);

    dialogRef.afterClosed().subscribe((uploadedFiles) => {
      if (!uploadedFiles)
        return;

      this.form.patchValue({
        bankStatementFiles: [...this.form.controls.bankStatementFiles.value, ...uploadedFiles],
      });
    });
  }

  openSignedApplicationDialog(): void {
    const dialogRef = this.openFileDialog(this.remainingSignedApplicationCount);

    dialogRef.afterClosed().subscribe((uploadedFiles) => {
      if (!uploadedFiles)
        return;

      this.form.patchValue({
        signedApplicationFiles: [...this.form.controls.signedApplicationFiles.value, ...uploadedFiles],
      });
    });
  }

  private openFileDialog(maxFileCount: number): MatDialogRef<UploadFileDialogImprovedComponent, UppyFile[]> {
    return UploadFileDialogImprovedComponent.open(this.dialogService, { maxFileCount });
  }

  private markForDeletionIfUploaded(file: UppyFile): void {
    if (file.meta.id && this.allowedFileDeletion) {
      const currentIds: number[] = this.form.get('fileIdsToDelete')!.value;
      const updatedIds = [...currentIds, file.meta.id];
      this.form.get('fileIdsToDelete')!.setValue(updatedIds);
    }
  }
}
