import { UntilDestroy } from "@ngneat/until-destroy";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormArray, UntypedFormGroup } from "@angular/forms";
import { BrokerUserData } from "../../broker/domain/models/broker.model";

@UntilDestroy()
@Component({
  selector: "ifbp-owner-information-step",
  templateUrl: "./owner-information-step.component.html",
  styleUrls: ["./owner-information-step.component.scss"],
})
export class OwnerInformationStepComponent implements OnInit {
  @Input() brokerUser: BrokerUserData;
  @Input() form: UntypedFormGroup;

  @Output() addOwner = new EventEmitter<any>();
  @Output() removeOwner = new EventEmitter<number>();

  minDate: Date;
  maxDate: Date;

  constructor() {
    const today = new Date();
    this.maxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate(),
    );
    this.minDate = new Date(
      today.getFullYear() - 100,
      today.getMonth(),
      today.getDate(),
    );
  }

  ngOnInit() {
    if ((this.form.controls.owners as FormArray).length === 0) {
      this.addOwner.emit();
    }
  }

  onClickAddOwner(): void {
    this.addOwner.emit();
  }

  onClickRemoveOwner(ownerIndex: number): void {
    this.removeOwner.emit(ownerIndex);
  }
}
