import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserListComponent } from "./features/user-list/user-list.component";
import { UserDetailsComponent } from "./features/user-details/user-details.component";
import { SharedModule } from "../../shared/shared.module";
import { UsersRoutingModule } from "./users-routing.module";

@NgModule({
  declarations: [
    UserListComponent,
    UserDetailsComponent
  ],
  imports: [CommonModule, UsersRoutingModule, SharedModule]
})
export class UsersModule {}
