import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import {
  AppBarTitleService,
  AppPageService,
  ProductCode,
  TransactionStatus,
  TransactionStatusLabel,
} from "common";
import { ActivatedRoute } from "@angular/router";
import { hasValue } from "../../Tools";
import { SubmissionService } from "../shared/submission.service";
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { AccountData } from "../shared/submissions.model";
import { brokerStatusUiMap } from "../shared/SharedConstants";
import { ApplicationResponse } from "../submission/models/submission.model";

@UntilDestroy()
@Component({
  selector: "ifbp-open-details",
  templateUrl: "./open-details.component.html",
  styleUrls: ["./open-details.component.scss"],
})
export class OpenDetailsComponent implements OnInit {
  applicationId: number;
  applicationInformation: ApplicationResponse;
  accountData: AccountData;

  readonly TransactionStatus = TransactionStatus;
  readonly TransactionStatusLabel = TransactionStatusLabel;
  readonly ProductCode = ProductCode;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private route: ActivatedRoute,
    private submissionService: SubmissionService,
  ) {}

  ngOnInit() {
    this.initRouteData();
  }

  private initRouteData() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = params.get("id");
      if (hasValue(applicationId)) {
        this.applicationId = parseInt(applicationId);
        this.submissionService
          .getApplicationData(this.applicationId)
          .pipe(
            mergeMap((data: ApplicationResponse) => {
              this.applicationInformation = data;
              this.appBarTitleService.title = `${
                data.accountName
              } - ${brokerStatusUiMap.get(data.brokerStatus)}`;
              if (hasValue(data.loanId)) {
                return this.submissionService.getAccountDetails(data.loanId);
              }
              return of(null);
            }),
          )
          .subscribe((accountData: AccountData | null) => {
            if (hasValue(accountData)) {
              this.accountData = accountData;
            }
          });
      }
    });
  }

  back() {
    this.appPageService.back();
  }

  adjustStatus(status?: TransactionStatus): TransactionStatus | undefined {
    if(status === TransactionStatus.OnHold) {
      return TransactionStatus.Pending;
    }
    else {
      return status;
    }
  }
}
