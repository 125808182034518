import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { SignUpModule } from "./features/sign-up/sign-up.module";
import { SignInModule } from "./features/sign-in/sign-in.module";
import { ResetPasswordModule } from "./features/reset-password/reset-password.module";
import { MfaModule } from "./features/mfa/mfa.module";

@NgModule({
  imports: [SharedModule, SignUpModule, SignInModule, ResetPasswordModule, MfaModule],
})
export class AccountModule { }
