<div fxLayoutAlign="center start" class="user-details-container">
    <form fxLayout="column" class="user-details-form" [formGroup]="form">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="12px">
        <mat-form-field appearance="outline" fxFlex.gt-xs="50">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="John" required formControlName="firstName" />
          <mat-error *ngIf="form.get('firstName').errors | hasControlError:['required']">First name is required.</mat-error>
          <mat-error *ngIf="form.get('firstName').errors | hasControlError:['maxlength']">First name is too long.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex.gt-xs="50">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Smith" required formControlName="lastName" />
          <mat-error *ngIf="form.get('lastName').errors | hasControlError:['required']">Last name is required.</mat-error>
          <mat-error *ngIf="form.get('lastName').errors | hasControlError:['maxlength']">Last name is too long.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="12px">
        <mat-form-field appearance="outline" fxFlex.gt-xs="50">
          <mat-label>Mobile Phone Number</mat-label>
          <input
            matInput
            placeholder="(123) 123-1234"
            required
            formControlName="mobilePhone"
            mask="(000) 000-0000"
          />
          <mat-error>Please enter a phone number.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex.gt-xs="50">
          <mat-label>Email</mat-label>
          <input matInput required formControlName="email" />
          <mat-error *ngIf="form.get('email').errors | hasControlError:['required']">Email is required.</mat-error>
          <mat-error *ngIf="form.get('email').errors | hasControlError:['maxlength']">Email is too long.</mat-error>
          <mat-error *ngIf="form.get('email').errors | hasControlError:['email']">Please enter a valid email.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="12px">
        <mat-form-field appearance="outline" fxFlex.gt-xs="50">
          <mat-label>Role</mat-label>
          <mat-select required formControlName="role">
            <mat-option *ngFor="let option of roleOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
          <mat-error>Please select a role.</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="brokerUser !== undefined" appearance="outline" fxFlex.gt-xs="50">
          <mat-label>Status</mat-label>
          <mat-select required formControlName="status">
            <mat-option *ngFor="let option of statusOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
          <mat-error>Please select a status.</mat-error>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="flex-end center"
        class="user-details-button-row center-center layout margin-top"
      >
        <button
          mat-flat-button
          type="button"
          class="user-details-button"
          (click)="cancel()"
        >
          CANCEL
        </button>
        <button
          mat-flat-button
          color="primary"
          class="green-button user-details-button"
          type="button"
          [disabled]="form.pristine || !form.valid || actionInProgress"
          (click)="save()"
        >
          SAVE
        </button>
      </div>
    </form>
  </div>
  