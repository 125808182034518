import { NgModule } from "@angular/core";
import { SubmissionsSharedModule } from "../../shared/submissions-shared.module";
import { NotInterestedDialogComponent } from "./not-interested-dialog.component";


@NgModule({
  imports: [SubmissionsSharedModule],
  declarations: [NotInterestedDialogComponent],
  exports: [NotInterestedDialogComponent]
})
export class NotInterestedDialogModule { }
