import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BrandingService, CompanyBranding, MetaService } from "common";
import { AppSettings } from "../app.settings";
import { catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";

declare global {
  interface Window {
    analytics: any;
  }
}

@UntilDestroy()
@Component({
  selector: "ifbp-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: MetaService,
    private brandingService: BrandingService,
    private settings: AppSettings,
  ) { }

  public ngOnInit(): void {
    this.brandingService.getBranding$()
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(data => {
        this.setDOMElements(data);
      });
  }

  private setDOMElements(brandingData: CompanyBranding): void {
    if (this.router.url === "/sign-in")
      this.titleService.setTitle(`Login – Idea Financial Partner`);
    else
      this.titleService.setTitle(
        `Business Financing | ${brandingData?.dba ?? "Idea Financial"}`,
      );

    this.metaService.createMetaTag(
      "description",
      `Welcome to your ${brandingData?.dba} account. You can access information about your business with us here.`,
    );
    this.metaService.createAlternateURL(
      (brandingData?.domains?.length ?? -1) > 0
        ? brandingData?.domains?.[2]
        : this.settings.rootSite.url,
    );
  }
}
