import { Injectable } from "@angular/core";
import { StateService } from "common";
import { Observable } from "rxjs";
import { Competitor } from "../model/competitors.model";
import { CompetitorsState } from "../model/competitors.state";

const initialState: CompetitorsState = null;

@Injectable({
  providedIn: "root",
})
export class CompetitorsStateService extends StateService<CompetitorsState> {
    constructor() {
    super(initialState);
  }
  
  getCompetitors$(): Observable<Competitor[]> {
    return this.select((state: CompetitorsState) => state?.competitors);
  }

  setCompetitors(competitors: Competitor[]): void {
    const newState = {
      ...this.state,
      competitors,
    };
    this.setState(newState);
  }

  isCompetitorsStateLoaded(): boolean {
    return !!this.state?.competitors;
  }

  clearState(): void {
    this.initState(initialState);
  }
}