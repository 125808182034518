import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import { AccountData } from "../shared/submissions.model";
import { ApplicationResponse } from "../submission/models/submission.model";
import {
  AppBarTitleService,
  ApplicationOffer,
  ApplicationStage,
  AppPageService,
  OfferBundleStatus,
  ProductCode,
} from "common";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { SubmissionService } from "../shared/submission.service";
import { hasValue } from "../../Tools";
import { mergeMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { LoanCreatedDialogComponent } from "./loan-created-dialog/loan-created-dialog.component";
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from "../feature-modules/submissions/features/not-interested-action/not-interested-action.model";

@UntilDestroy()
@Component({
  selector: "ifbp-checkout-ready",
  templateUrl: "./checkout-ready.component.html",
  styleUrls: ["./checkout-ready.component.scss"],
})
export class CheckoutReadyComponent implements OnInit {
  accountData: AccountData;
  actionInProgress: boolean;
  applicationId: number;
  bundleId: number | undefined;
  applicationInformation: ApplicationResponse;
  isContractOut: boolean;
  selectedOffer: ApplicationOffer | undefined;
  notInterestedActionData: NotInterestedActionInputData;

  readonly ProductCode = ProductCode;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private route: ActivatedRoute,
    private router: Router,
    private submissionService: SubmissionService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initRouteData();
  }

  private initRouteData() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.dataInit(this.applicationId);
      }
    });
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = params["id"];
      if (hasValue(applicationId)) {
        this.dataInit(parseInt(applicationId));
      }
    });
  }

  private dataInit(applicationId: number) {
    this.applicationId = applicationId;
    this.submissionService
      .getApplicationData(this.applicationId)
      .pipe(
        mergeMap((data: ApplicationResponse) => {
          this.applicationInformation = data;
          this.notInterestedActionData = this.initNotInterestedActionData(data);
          const acceptedOfferBundle = this.applicationInformation.offerBundles
            ?.filter(
              offerBundle => offerBundle.status === OfferBundleStatus.Accepted,
            )
            ?.at(0);
          this.selectedOffer = acceptedOfferBundle?.offers?.at(0);
          this.bundleId = acceptedOfferBundle?.id;
          if (!hasValue(data.loanId)) {
            return forkJoin([of(data), of(null)]);
          } else {
            return forkJoin([
              of(data),
              this.submissionService.getAccountDetails(data.loanId),
            ]);
          }
        }),
      )
      .subscribe(
        ([applicationInformation, accountData]: [
          ApplicationResponse,
          AccountData | null,
        ]) => {
          this.isContractOut = false;
          if (hasValue(accountData)) {
            this.accountData = accountData;
            this.isContractOut =
              this.accountData.offer.uniqueId === this.selectedOffer?.uniqueId;
          }
          this.appBarTitleService.title = `${applicationInformation.accountName
            } - Contract ${this.isContractOut ? "out" : "ready"}`;
        },
      );
  }

  private initNotInterestedActionData(data: ApplicationResponse) {
    return {
      applicationId: this.applicationId,
      brokerStatus: data.brokerStatus,
      createOnDate: new Date(data.createdOn),
      previousStage: <ApplicationStage>data?.previousStage
    }
  }

  onSendCheckoutClick() {
    this.actionInProgress = true;
    if (this.isContractOut) {
      this.submissionService.resendCheckout(this.applicationId).subscribe({
        complete: () => {
          this.actionInProgress = false;
          this.openLoanCreatedDialog();
        },
      });
    } else {
      this.submissionService.sendCheckout(this.applicationId).subscribe({
        complete: () => {
          this.actionInProgress = false;
          this.openLoanCreatedDialog();
        },
      });
    }
  }

  private openLoanCreatedDialog() {
    this.dialog
      .open(LoanCreatedDialogComponent)
      .afterClosed()
      .subscribe(() => {
        void this.router.navigate([], {
          skipLocationChange: true,
          queryParamsHandling: "merge",
        });
      });
  }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    if (data.isSuccess) {
      this.appPageService.back();
    }
  }

  onClickBack() {
    this.appPageService.back();
  }
}
