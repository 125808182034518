import { ValidatorFn, Validators } from '@angular/forms';
import { CommonValidators } from './common-validators';

export class ValidatorProvider {
  static salesforceEmailValidators(): ValidatorFn[] {
    return [
      Validators.maxLength(80),
      CommonValidators.salesForceEmail,
    ];
  }
}
