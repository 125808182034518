import { NgModule } from "@angular/core";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { HomeComponent } from "./features/home/home.component";
import { DashboardWidgetComponent } from './features/home/components/dashboard-widget/dashboard-widget.component';
import { SharedModule } from "../../shared/shared.module";
import { AdvisorWidgetComponent } from './features/home/components/advisor-widget/advisor-widget.component';

@NgModule({
  imports: [SharedModule, DashboardRoutingModule],
  declarations: [HomeComponent, DashboardWidgetComponent, AdvisorWidgetComponent]
})
export class DashboardModule { }
