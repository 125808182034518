import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AccountRemote } from "../../infrastructure/account.remote";
import { AccountData, AccountSummary, BrokerData, MfaTokenPayload, RegistrationTokenStatusData, ResetPasswordRequest, ResetPasswordResponse, SignInErrorResponse, SignInExtras, SignInMfaRequest, SignInResponse, SingUpRequest } from "../model/account.model";
import { AccountSignInService } from "./account-sign-in.service";
import { AccountResetPasswordService } from "./account-reset-password.service";
import { AccountStateService } from "./account.state.service";
import { map, switchMap, tap, withLatestFrom } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AccountFacade {
  constructor(
    private readonly remote: AccountRemote,
    private readonly signInService: AccountSignInService,
    private readonly resetPasswordService: AccountResetPasswordService,
    private readonly stateService: AccountStateService,
  ) { }

  getCurrentAccount$(): Observable<AccountData> {
    if (this.stateService.isCurrentAccountStateLoaded()) {
      return this.stateService.getCurrentAccount$();
    }
    return this.refreshCurrentAccount$();
  }

  private refreshCurrentAccount$(): Observable<AccountData> {
    return this.remote.getCurrentAccount().pipe(
      tap((account: AccountData) => this.stateService.setCurrentAccount(account)),
      switchMap(() => this.stateService.getCurrentAccount$()),
    );
  }

  getCurrentAccountSummary$(): Observable<AccountSummary> {
    return this.getCurrentAccount$().pipe(
      withLatestFrom((account: AccountData) => this.getBroker$(account.brokerId)),
      map(([account, broker]: any) => ({ account, broker })),
    )
  }

  private getBroker$(id: number): Observable<BrokerData> {
    if (this.stateService.isBrokerStateLoaded(id)) {
      return this.stateService.getBroker$();
    }

    return this.refreshBroker$(id);
  }

  private refreshBroker$(id: number): Observable<BrokerData> {
    return this.remote.getBroker(id).pipe(
      tap((broker: BrokerData) =>
        this.stateService.setBroker(broker),
      ),
      switchMap(() => this.stateService.getBroker$()),
    );
  }

  getRegistrationTokenStatus(email: string, token: string): Observable<RegistrationTokenStatusData> {
    return this.remote.getRegistrationTokenStatus(email, token);
  }

  signUp(request: SingUpRequest): Observable<RegistrationTokenStatusData> {
    return this.remote.signUp(request);
  }

  signInByPassword(
    username: string,
    password: string,
    rememberMe: boolean,
  ): Observable<SignInResponse | SignInErrorResponse> {
    return this.signInService.signInByPassword(username, password, rememberMe);
  }

  signInByMfa(
    request: SignInMfaRequest,
    extras: SignInExtras,
  ): Observable<SignInResponse | SignInErrorResponse> {
    return this.signInService.signInByMfa(request, extras);
  }

  confirmPhoneAndSignIn(
    request: SignInMfaRequest,
    extras: SignInExtras,
  ): Observable<SignInResponse | SignInErrorResponse> {
    return this.signInService.confirmPhoneAndSignIn(request, extras);
  }

  forgotPassword(email: string): Observable<void> {
    return this.remote.forgotPassword(email);
  }

  resetPassword(
    request: ResetPasswordRequest,
    tokenPayload?: MfaTokenPayload,
  ): Observable<ResetPasswordResponse> {
    return this.resetPasswordService.resetPassword(request, tokenPayload);
  }
}
