import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EntityType, EntityTypeLabel, EnumHelper, EnumValue, TimeInBusinessPeriod, TimeInBusinessPeriodLabel } from "common";
import { BrokerUserData } from "../../broker/domain/models/broker.model";
import { SubmissionFormService } from "../submission-form/submission-form.service";

@UntilDestroy()
@Component({
  selector: "ifbp-business-information-step",
  templateUrl: "./business-information-step.component.html",
  styleUrls: ["./business-information-step.component.scss"],
})
export class BusinessInformationStepComponent implements OnInit {
  @Input() brokerUser: BrokerUserData;
  @Input() form: UntypedFormGroup;

  ownershipOptions: EntityType[];
  timeInBusinessOptions: EnumValue[] = EnumHelper.getMappedNamesAndValues(TimeInBusinessPeriod, TimeInBusinessPeriodLabel);
  missingRequiredToSaveValues: boolean;

  readonly EntityType = EntityType;
  readonly EntityTypeLabel = EntityTypeLabel;

  constructor(private submissionFormService: SubmissionFormService) {
    this.ownershipOptions = [
      EntityType.SoleProprietorship,
      EntityType.LimitedLiabilityCompany,
      EntityType.Corporation,
      EntityType.LimitedPartnership,
      EntityType.GeneralPartnership,
      EntityType.NotForProfit,
      EntityType.Public,
      EntityType.LegalPartnership,
      EntityType.Other
    ];
  }

  ngOnInit(): void {
    this.submissionFormService.missingRequiredToSaveValues$
      .pipe(untilDestroyed(this)).subscribe(() => {
        this.highlightFieldsRequiredToSave();
      })
  }

  private highlightFieldsRequiredToSave() {
    this.missingRequiredToSaveValues = true;
    setTimeout(() => {
      this.missingRequiredToSaveValues = false;
    }, 500);
  }
}
