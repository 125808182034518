import {
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';

export class CommonValidators {
  /**
   * @description
   * Validator that expands the functionality of `Validators.email()` implementation to be compliant with SalesForce validation.
   * It performs validation with `Validators.email()` and if it passes, it performs validation for SalesForce email.
   * For that, it uses a RegEx pattern to check, if the domain section of email address has at least one `.` after `@` and that it has any additional character after the `.`.
   *
   * @usageNotes
   *
   * ### Validate that the field matches a valid email pattern
   *
   * ```typescript
   * const control = new FormControl('bad@bad', CommonValidators.salesForceEmail);
   *
   * console.log(control.errors); // {email: true}
   * ```
   *
   * @returns An error map with the `email` property
   * if the validation check fails, otherwise `null`.
   *
   */
  static salesForceEmail(control: AbstractControl): ValidationErrors | null {
    let error: ValidationErrors | null = Validators.email(control);

    if (error) return error;

    error = CommonValidators.salesforceEmailValidator(control);

    if (error) return error;

    return null;
  }

  private static salesforceEmailValidator(control: AbstractControl): ValidationErrors | null {
    // match if there is at least one . after @
    const salesForceEmailPattern = /@[^\s]*\.[^\s]*/;
    const regex = new RegExp(salesForceEmailPattern);

    if (!regex.test(control.value)) {
      return { 'email': true }
    }

    return null;
  }
}
