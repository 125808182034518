import { ButtonColor, DialogActionData } from "common";

export const reactivateSubmissionDialogData: DialogActionData = {
  body: "Reactivating will allow the submission to complete the underwriting process.",
  title: "Reactivate submission?",
  buttons: [
    {
      label: "CANCEL",
      buttonColor: ButtonColor.Default,
      action: false,
    },
    {
      label: "CONFIRM",
      buttonColor: ButtonColor.Primary,
      action: true,
    }
  ]
}

export const markAsNotInterestedSuccessMessage = "Submission has been successfully marked as not interested.";
export const reactivateSubmissionSuccessMessage = "Submission has been successfully reactivated.";
export const actionErrorMessage = "There was an error while updating the submission status. Try again or contact your business advisor if the error keeps occurring.";
