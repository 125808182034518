import { NgModule } from "@angular/core";
import { NotInterestedActionComponent } from "./not-interested-action.component";
import { SubmissionsSharedModule } from "../../shared/submissions-shared.module";

@NgModule({
  imports: [SubmissionsSharedModule],
  declarations: [NotInterestedActionComponent],
  exports: [NotInterestedActionComponent]
})
export class NotInterestedActionModule { }
