import { Component, OnInit } from "@angular/core";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  DialogActionComponent,
  MessageService,
} from "common";
import { EditUserData, UserData, UserDbRole } from "../../domain/models/users.model";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserUiRole, WebPaths } from "../../../../shared/SharedConstants";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { hasValue } from "projects/partner/src/Tools";
import { finalize } from "rxjs/operators";
import { UsersFacade } from "../../domain/+state/users.facade";
import { getNotificationSentDialogData } from "./user-details.data";
import { MatDialog } from "@angular/material/dialog";


@UntilDestroy()
@Component({
  selector: "ifbp-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit {
  actionInProgress: boolean;
  appBarActions: AppBarAction[];
  brokerUser: UserData | undefined;
  currentBrokerUser: UserData;
  form: UntypedFormGroup;
  roleOptions: { label: UserUiRole; value: UserDbRole }[];
  statusOptions: { label: string; value: boolean }[];
  constructor(
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private userFacade: UsersFacade,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.form = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.maxLength(40)]],
      lastName: ["", [Validators.required, Validators.maxLength(64)]],
      mobilePhone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
      role: ["", Validators.required],
    });
    this.roleOptions = [
      { label: UserUiRole.ADMIN, value: UserDbRole.ADMIN },
      { label: UserUiRole.USER, value: UserDbRole.USER },
    ];
    this.statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    this.appBarActions = [
      {
        id: "cancel",
        label: "CANCEL",
        buttonType: "button",
      },
      {
        id: "save",
        label: "SAVE",
        buttonType: "button",
        buttonAppearance: "flat",
        buttonColor: "primary",
        disabled: true,
      },
    ];
  }

  ngOnInit() {
    this.initRouteData();
    this.initAppBarActions();
  }

  private initRouteData() {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.currentBrokerUser = data.currentBrokerUser;
      if (hasValue(data.brokerUser)) {
        this.brokerUser = data.brokerUser as UserData;
        this.appBarTitleService.title = `${this.brokerUser.firstName} ${this.brokerUser.lastName}`;
        this.form.addControl(
          "status",
          new FormControl(this.brokerUser.active, Validators.required),
        );
        this.form.patchValue({
          firstName: this.brokerUser.firstName,
          lastName: this.brokerUser.lastName,
          mobilePhone: this.brokerUser.mobilePhone,
          email: this.brokerUser.email,
          role: this.brokerUser.role,
        });
      }
    });
  }

  private initAppBarActions() {
    this.appBarActionsService.actions = this.appBarActions;
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
    this.form.statusChanges.subscribe((newStatus: string) => {
      const isEnabled = newStatus === "VALID" && this.form.dirty;
      this.appBarActionsService.enable("save", isEnabled);
    });
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  cancel() {
    this.appPageService.back();
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    this.actionInProgress = true;
    if (hasValue(this.brokerUser)) {
      this.userFacade
        .updateUser(this.brokerUser.id, this.prepSubmissionData())
        .pipe(finalize(() => this.actionComplete()))
        .pipe(untilDestroyed(this))
        .subscribe({
          next: this.handleSuccessUpdateBrokerUser.bind(this),
          error: this.handleErrorUpdateBrokerUser.bind(this),
        });
    } else {
      this.userFacade
        .createUser(this.prepSubmissionData())
        .pipe(finalize(() => this.actionComplete()))
        .subscribe((user) => {
          this.openNotificationSentDialog(user);
        });
    }
  }

  private openNotificationSentDialog(user: UserData) {
    DialogActionComponent.show(this.dialog, getNotificationSentDialogData(user)).subscribe(() => {
      void this.router.navigate([WebPaths.Team], {queryParams: { sort : 'lastName' }} );
    });
  }

  private handleSuccessUpdateBrokerUser() {
    this.messageService.success("Team member has been successfully updated.");
    void this.router.navigate([WebPaths.Team], {queryParams: { sort : 'lastName' }} );
  }

  private handleErrorUpdateBrokerUser() {
    this.messageService.error("Team member cannot be updated.");
  }

  private prepSubmissionData(): EditUserData {
    return {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      mobilePhone: this.form.value.mobilePhone,
      email: this.form.value.email,
      role: this.form.value.role,
      active: this.form.value.status,
      brokerId: hasValue(this.brokerUser)
        ? this.brokerUser.brokerId
        : this.currentBrokerUser.brokerId,
    };
  }

  private actionComplete() {
    this.actionInProgress = false;
  }
}
