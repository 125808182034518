// from portal project
// IdeaFinancial.Web/projects/portal/src/app/app-routing.module.ts
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WebPaths } from "./shared/SharedConstants";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: WebPaths.SignIn.slice(1),
  },
  {
    path: "login",
    redirectTo: WebPaths.SignIn.slice(1),
  },
  {
    path: "sign-out",
    redirectTo: WebPaths.SignIn.slice(1),
  },
  {
    path: "home",
    pathMatch: "full",
    redirectTo: WebPaths.Dashboard.slice(1),
  },
  {
    path: "register",
    redirectTo: WebPaths.SignUp.slice(1),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
