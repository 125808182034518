import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class SubmissionFormService {
  private missingRequiredToSaveValuesSubject = new Subject();
  missingRequiredToSaveValues$ = this.missingRequiredToSaveValuesSubject.asObservable();

  missingRequiredToSaveValues() {
    this.missingRequiredToSaveValuesSubject.next();
  }
}
