import { Injectable } from '@angular/core';
import { BrokerFacade } from 'projects/partner/src/app/broker/domain/+state/broker.facade';
import { BrokerDataDetails, BrokerUserData } from 'projects/partner/src/app/broker/domain/models/broker.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersBridgeService {

  constructor(
    private readonly brokerFacade: BrokerFacade
  ) {  }

  getCurrentBroker$(): Observable<BrokerUserData> {
    return this.brokerFacade.getCurrentBroker$().pipe();
  }

  getBrokerDataDetails$(): Observable<BrokerDataDetails> {
    return this.brokerFacade.getBrokerDataDetails$().pipe();
  }
}
