<ifc-app-scroll-container class="ifbp-submission-stepper-container" fxLayoutAlign="center">
  <mat-card *ngIf="missingInformation" class="missing-information-card mat-elevation-z">
    <div class="header">Missing information:</div>
    {{missingInformation}}
  </mat-card>
  <mat-stepper
    [orientation]="(stepperOrientation | async)!"
    (selectionChange)="stepChanged($event.selectedIndex)"
    [selectedIndex]="selectedStep"
    [linear]="false"
  >
    <mat-step
      #businessInformationStep
      [state]="matHeaderOptions?.[businessInformationIndex]?.state"
      label="Business information"
      errorMessage="Information required"
      [completed]="true"
      [hasError]="matHeaderOptions?.[businessInformationIndex]?.hasError"
    >
      <ifbp-business-information-step
        [brokerUser]="brokerUser"
        [form]="businessInformationForm"
      ></ifbp-business-information-step>
    </mat-step>
    <mat-step
      #ownerInformationStep
      [state]="matHeaderOptions?.[ownerInformationIndex]?.state"
      label="Owner information"
      errorMessage="Information required"
      [completed]="matHeaderOptions?.[ownerInformationIndex]?.completed"
      [hasError]="matHeaderOptions?.[ownerInformationIndex]?.hasError"
    >
      <ifbp-owner-information-step
        [brokerUser]="brokerUser"
        [form]="ownerInformationForm"
        (addOwner)="addOwner()"
        (removeOwner)="removeOwner($event)"
      ></ifbp-owner-information-step>
    </mat-step>
    <mat-step
      #documentsAndCashFlowStep
      [state]="matHeaderOptions?.[documentsAndCashFlowIndex]?.state"
      label="Documents and cash flow"
      errorMessage="Information required"
      [completed]="matHeaderOptions?.[documentsAndCashFlowIndex]?.completed"
      [hasError]="matHeaderOptions?.[documentsAndCashFlowIndex]?.hasError"
    >
      <ifbp-documents-and-cash-flow-step
        [brokerUser]="brokerUser"
        [brokerDataDetails]="brokerDataDetails"
        [form]="documentsAndCashFlowForm"
      ></ifbp-documents-and-cash-flow-step>
    </mat-step>
  </mat-stepper>
  <div
    fxLayout="row"
    fxLayoutAlign="flex-end center"
    class="bottom-action-button-row center-center layout margin-top"
  >
    <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
  </div>
</ifc-app-scroll-container>
