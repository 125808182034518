// modified old file from
// https://dev.azure.com/ideafinancial/RYNO/_git/RYNO/pullrequest/1953?_a=files&path=/IdeaFinancial.Web/projects/portal/src/app/user/user.module.ts
// (following portal's old password flow login method instead of the current sso method of signing in)
import { NgModule } from "@angular/core";
import { CommonModule } from "common";

import { RouterModule } from "@angular/router";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
  imports: [CommonModule, RouterModule, GooglePlaceModule],
})
export class UserModule {}
