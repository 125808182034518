import { Injectable } from "@angular/core";
import { EMPTY, Observable, of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import {
  InitMfaData,
  MessageService,
  MfaApiResponse,
  MfaConfig,
  MfaContextData,
  MfaContextType,
  MfaErrorCodeType,
  MfaErrorType,
  MfaService,
  OAuthService,
} from "common";
import { Router } from "@angular/router";
import { hasValue } from "projects/partner/src/Tools";
import { LoginType, SignInErrorResponse, SignInExtras, SignInMfaRequest, SignInRequest, SignInResponse } from "../model/account.model";
import { AccountRemote } from "../../infrastructure/account.remote";
import { isExcludedLoginRedirectPath } from "projects/partner/src/app/shared/SharedConstants";

@Injectable({
  providedIn: "root",
})
export class AccountSignInService {
  constructor(
    private readonly remote: AccountRemote,
    private readonly messageService: MessageService,
    private readonly mfaService: MfaService,
    private readonly oauthService: OAuthService,
    private readonly router: Router,
  ) { }

  signInByPassword(
    username: string,
    password: string,
    rememberMe: boolean,
  ): Observable<SignInResponse | SignInErrorResponse> {
    const request = {
      grant_type: "password",
      client_id: LoginType.Partner,
      username,
      password,
    };
    const extras: SignInExtras = {
      username,
      rememberMe,
      loginType: LoginType.Partner,
    };
    return this.signInBase(request, extras);
  }

  signInByMfa(
    request: SignInMfaRequest,
    extras: SignInExtras,
  ): Observable<SignInResponse | SignInErrorResponse> {
    const requestBody = {
      grant_type: "mfa_otp",
      client_id: extras?.loginType,
      mfa_token: request.mfa_token,
      mfa_code: request.mfa_code,
      trust_device: request.trust_device,
    };
    return this.signInBase(requestBody, extras);
  }

  confirmPhoneAndSignIn(
    request: SignInMfaRequest,
    extras: SignInExtras,
  ): Observable<SignInResponse | SignInErrorResponse> {
    return this.remote.confirmPhoneAndSignIn(request).pipe(
      tap((data: SignInResponse) => this.handleSignInSuccess(data, extras)),
      catchError(error => this.handleSignInError(error, extras)),
    );
  }

  private signInBase(
    request: SignInRequest,
    extras: SignInExtras,
  ): Observable<SignInResponse | SignInErrorResponse> {
    return this.remote.signIn(request).pipe(
      tap((data: SignInResponse) => this.handleSignInSuccess(data, extras)),
      catchError(error => this.handleSignInError(error, extras)),
    );
  }

  private handleSignInSuccess(response: SignInResponse, extras: SignInExtras) {
    if (response?.access_token)
      this.oauthService.sessionBegin(
        extras.loginType,
        response.access_token,
        response.expires_in,
        response.refresh_token,
        response.refresh_token_expires_in,
      );

    this.handleLocalLoginData(extras?.rememberMe, extras?.username);
    window.analytics.identify(extras?.username);
    let navigateTo = sessionStorage.getItem("navigation.history");
    if (!hasValue(navigateTo) || isExcludedLoginRedirectPath(navigateTo)) {
      navigateTo = "home";
    }
    this.router.navigate([navigateTo]);
  }

  private handleSignInError(
    response: HttpErrorResponse,
    extras: SignInExtras,
  ): Observable<SignInErrorResponse> {
    if (response.status === 401) {
      this.messageService.error(
        "The email and password provided did not match our records. Double check this info and try again.",
      );
      return of({ hasError: true });
    }

    if (response.status === 400 && response?.error?.error === "invalid_grant") {
      this.messageService.error(
        "The email and password provided did not match our records. Double check this info and try again."
      );
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }

    if (response.status === 400) {
      this.messageService.error(response);
      return of({ hasError: true });
    }

    if (response.status === 403 && this.hasMfaError(response?.error?.error)) {
      return this.getMfaError(response?.error, extras);
    }

    this.messageService.error("Error occurred while trying to log in.");
    return of({ hasError: true });
  }

  private hasMfaError(errorType: HttpErrorResponse["error"]) {
    return Object.values(MfaErrorType).includes(errorType);
  }

  private getMfaError(
    mfaResponse: MfaApiResponse,
    extras: SignInExtras,
  ): Observable<SignInErrorResponse> {
    switch (mfaResponse.error) {
      case MfaErrorType.MfaRequired:
        return this.getMfaRequiredError(mfaResponse, extras);
      case MfaErrorType.MfaCodeError:
        return this.getMfaCodeError(mfaResponse);
      default:
        console.error(`Mfa error: ${mfaResponse.error}`);
        return of({ hasError: true });
    }
  }

  private getMfaRequiredError(
    mfaResponse: MfaApiResponse,
    extras: SignInExtras,
  ): Observable<SignInErrorResponse> {
    const contextData: MfaContextData = {
      username: extras.username,
      rememberMe: extras.rememberMe,
      loginType: extras.loginType,
    };

    const config: MfaConfig = {
      canAddPhoneNumberIfMissing: false,
      canChangePhoneNumberIfUnverified: false,
      isTrustingDeviceAllowed: true
    };

    const data: InitMfaData = {
      mfaContextType: MfaContextType.Login,
      mfaResponse,
      contextData,
      config,
    };
    this.mfaService.initMfa(data);
    return of({ hasError: true });
  }

  private getMfaCodeError(
    mfaResponse: MfaApiResponse,
  ): Observable<SignInErrorResponse> {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.InvalidMfaCode
    ) {
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }
    return EMPTY;
  }

  private handleLocalLoginData(remember: boolean, email: string) {
    if (remember) {
      localStorage.setItem("loginData", JSON.stringify({ email }));
    } else {
      localStorage.removeItem("loginData");
    }
  }
}
