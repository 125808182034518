import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({ name: "safeResourceUrl" })
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(content);
  }
}
