import { AppSettings } from "../../app.settings";
import { ApplicationFileCategory } from "../submission/models/submission.model";

export const ApiUrls = (appSettings: AppSettings) => {
    return {
      SignIn: `${appSettings.oauth.url}/token`,
      PhoneNumberConfirm: `${appSettings.oauth.url}/api/phone-number/confirm`,
      ForgotPassword: `${appSettings.brokers.url}/api/user/password/forgot`,
      GetCurrentUserDetails: `${appSettings.brokers.url}/api/user/current`,
      GetBrokerDetails: ({ user }: { user: { brokerId: number } }) =>
        `${appSettings.brokers.url}/api/broker/${user.brokerId}`,
      GetUsers: `${appSettings.brokers.url}/api/user`,
      GetUser: ({ user }: { user: { id: number } }) =>
        `${appSettings.brokers.url}/api/user/${user.id}`,
      CreateUser: `${appSettings.brokers.url}/api/user/team-invitation`,
      EditUser: ({ user }: { user: { id: number } }) =>
        `${appSettings.brokers.url}/api/user/${user.id}`,
      DeleteUser: ({ user }: { user: { id: number } }) =>
        `${appSettings.brokers.url}/api/user/${user.id}`,
      UpdatePassword: ({ user }: { user: { id: number } }) =>
        `${appSettings.brokers.url}/api/user/${user.id}/password`,
      SaveNewAccount: `${appSettings.applications.url}/api/account`,
      SaveExistingAccount: ({ account }: { account: { id: number } }) =>
        `${appSettings.applications.url}/api/account/${account.id}`,
      SaveNewApplication: `${appSettings.applications.url}/api/application`,
      SaveExistingApplication: ({
        application,
      }: {
        application: { id: number };
      }) => `${appSettings.applications.url}/api/application/${application.id}`,
      SaveNewOwner: `${appSettings.applications.url}/api/contact`,
      SaveExistingOwner: ({ owner }: { owner: { id: number } }) =>
        `${appSettings.applications.url}/api/contact/${owner.id}`,
      SaveApplicationFile: ({
        application,
        category,
      }: {
        application: { id: number };
        category: ApplicationFileCategory;
      }) =>
        `${appSettings.applications.url}/api/application/${application.id}/documents/${category}`,
      SubmitNewApplication: ({ application }: { application: { id: number } }) =>
        `${appSettings.applications.url}/api/application/${application.id}`,
      GetApplications: `${appSettings.applications.url}/api/application`,
      GetApplication: ({ application }: { application: { id: number } }) =>
        `${appSettings.applications.url}/api/application/${application.id}`,
      GetAccount: ({ account }: { account: { id: number } }) =>
        `${appSettings.applications.url}/api/account/${account.id}`,
      GetOwners: ({ application }: { application: { id: number } }) =>
        `${appSettings.applications.url}/api/contact?applicationId=${application.id}`,
      GetApplicationFiles: ({ application }: { application: { id: number } }) =>
        `${appSettings.applications.url}/api/application/${application.id}/documents`,
      GetApplicationFile: ({
        application,
        file,
      }: {
        application: { id: number };
        file: { id: number };
      }) =>
        `${appSettings.applications.url}/api/application/${application.id}/documents/${file.id}`,
      GetAccountDetails: ({ loan }: { loan: { id: number } }) =>
        `${appSettings.loans.url}/api/account/${loan.id}`,
      UpdateApplicationStage: ({
        application,
      }: {
        application: { id: number };
      }) => `${appSettings.applications.url}/api/application/${application.id}`,
      SendCheckout: ({ application }: { application: { id: number } }) =>
        `${appSettings.applications.url}/api/application/${application.id}/create-loans`,
      ResendCheckout: ({ application }: { application: { id: number } }) =>
        `${appSettings.applications.url}/api/application/${application.id}/resend-checkout`,
      GetCheckoutRequirementFile: ({
        checkoutRequirement,
        file,
      }: {
        checkoutRequirement: { id: number };
        file: { id: number };
      }) =>
        `${appSettings.applications.url}/api/checkout-requirements/${checkoutRequirement.id}/files/${file.id}`,
      SaveCheckoutRequirementFile: ({
        checkoutRequirement,
        category,
      }: {
        checkoutRequirement: { id: number };
        category: string;
      }) =>
        `${appSettings.applications.url}/api/checkout-requirements/${checkoutRequirement.id}/files/${category}`,
      SubmitOfferBundle: ({ offerBundle }: { offerBundle: { id: number } }) =>
        `${appSettings.applications.url}/api/offer-bundle/${offerBundle.id}/status`,
      SendBankLinkEmail: ({ application }: { application: { id: number } }) =>
        `${appSettings.banking.url}/api/banking/linking-invitation/${application.id}`,
      DeleteApplicationFile: (applicationId: number, fileId: number) => `${appSettings.applications.url}/api/application/${applicationId}/documents/${fileId}/partner`,
      ResendInvitation: (userId: number) => `${appSettings.brokers.url}/api/user/resend-invitation/${userId}`,
    };
  };