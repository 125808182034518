import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FilterChip } from './filters-chip.model';

@Component({
  selector: 'ifbp-filters-chip-list',
  templateUrl: './filters-chip-list.component.html',
  styleUrls: ['./filters-chip-list.component.scss']
})
export class FiltersChipListComponent  {
  @Input()
  filters: FilterChip[] = [];

  @Output()
  filterRemoved = new EventEmitter<FilterChip[]>();

  remove(filter: FilterChip): void {
    this.filterRemoved.emit([filter]);
  }

  clear(): void {
    this.filterRemoved.emit(this.filters);
  }
}
