import { Component, Input } from '@angular/core';
import { DashboardActionType, DashboardWidgetActionData, DashboardWidgetData } from '../../../../domain/model/dashboard.model';
import { Router } from '@angular/router';
import { ActionButtonColor, ActionButtonType } from 'projects/partner/src/app/shared/models/action-button/action-button.model';

@Component({
  selector: 'ifbp-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss']
})
export class DashboardWidgetComponent {
  @Input()
  data: DashboardWidgetData;

  readonly ButtonType = ActionButtonType;
  readonly ButtonColor = ActionButtonColor;
  readonly ActionType = DashboardActionType;

  constructor(private router: Router) { }

  onClickAction(data: DashboardWidgetActionData) {
    switch (data.type) {
      case DashboardActionType.RedirectExternal:
        return window.open(data.redirectUrl, "_blank");

      case DashboardActionType.RedirectInternal:
        return this.router.navigate([data.redirectUrl]);

      default:
        break;
    }
  }
}
