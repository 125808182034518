import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AppBarActionsComponent,
  AppBarCloseAction,
  AppBarComponent,
  AppBarTitleComponent,
  AppFooterComponent,
  AppPageComponent,
} from "common";
import { BrokerCurrentResolver } from "../broker/infrastructure/broker.resolver";
import { ProcessingDetailsComponent } from "./processing-details.component";
import { SubmissionDetailsGuard } from "../feature-modules/submissions/infrastructure/guards/submission-details.guard";

const routes: Routes = [
  {
    path: "submission/:id/details",
    title: "Processing Details – Idea Financial Partner",
    component: AppPageComponent,
    canActivate: [SubmissionDetailsGuard],
    data: {
      appPage: {
        contentClass: "simple",
        contentAlignment: "center-start",
        contentWithoutPadding: true,
      },
      appBar: {
        stickyDisabled: true,
        closeAction: AppBarCloseAction.BACK,
        logoHidden: true,
        containerClass: "start-center",
      },
    },
    resolve: {
      brokerUser: BrokerCurrentResolver,
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
          {
            path: "",
            outlet: "right",
            component: AppBarActionsComponent,
          },
        ],
      },
      {
        path: "",
        component: ProcessingDetailsComponent,
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProcessingDetailsRoutingModule {}
