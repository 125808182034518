import { Injectable } from "@angular/core";
import { LocalStorageCachingStorageService, OAuthService } from "common";
import { BrokerFacade } from "../broker/domain/+state/broker.facade";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private oauthService: OAuthService,
    private brokerFacade: BrokerFacade,
    private cache: LocalStorageCachingStorageService,
  ) {}

  logout() {
    this.oauthService.sessionEndLocal();
    this.brokerFacade.clearBrokerCache();
    this.cache.clear();
  }
}
