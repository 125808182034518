import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { SubmissionListFacade } from './submission-list.facade';
import { SubmissionListComponentData, SubmissionNoResultsFoundActionName } from './submission-list.model';
import { filter, startWith } from 'rxjs/operators';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FilterChip } from '../../shared/filters-chip-list/filters-chip.model';
import { SubmissionData, SubmissionQueryParams } from '../../domain/models/submissions.model';
import { noResultsForFiltersFoundData, noResultsFoundData, submissionListDisplayedColumns } from './submission-list.data';
import { SubmissionListFilterDialogComponent } from './components/submission-list-filter-dialog/submission-list-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NoResultsFoundActionData } from '../../shared/no-results-found/no-results-found.model';
import { NotInterestedActionCompletedData } from '../not-interested-action/not-interested-action.model';

@UntilDestroy()
@Component({
  selector: 'ifbp-submission-list',
  templateUrl: './submission-list.component.html',
  styleUrls: ['./submission-list.component.scss'],
  providers: [SubmissionListFacade]
})
export class SubmissionListComponent implements OnInit {
  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  viewData: SubmissionListComponentData;
  isDataLoaded: boolean;
  private queryParams: Params;

  displayedColumns = submissionListDisplayedColumns;
  noResultsFoundData = noResultsFoundData;
  noResultsForFiltersFoundData = noResultsForFiltersFoundData;
  readonly BrokerStatus = BrokerStatus;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly facade: SubmissionListFacade,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.watchQueryParams();
  }

  private watchQueryParams() {
    const routerEvent$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd), startWith(this.router));
    const queryParams$ = this.route.queryParams;
    combineLatest([routerEvent$, queryParams$]).pipe(untilDestroyed(this))
      .subscribe(([_, queryParams]: [any, Params]) => {
        this.queryParams = queryParams;
        this.refreshComponentData(queryParams);
      });
  }

  private refreshComponentData(queryParams: Params) {
    this.facade.getComponentData(queryParams, !this.isDataLoaded).subscribe(data => {
      this.viewData = data;
      this.isDataLoaded = true;
    })
  }

  goToSubmissionDetails(submission: SubmissionData) {
    this.facade.goToSubmissionDetails(submission);
  }

  filterDialog() {
    SubmissionListFilterDialogComponent.show(this.dialog, this.viewData.filters)
      .subscribe((filters: SubmissionQueryParams) => {
        if (filters) {
          this.facade.filter({ filters });
        }
      });
  }

  onFilterRemoved(removedChips: FilterChip[]) {
    this.removeFiltersByChips(removedChips);
  }

  onSort(sortData: Sort) {
    this.facade.filter({ filters: this.viewData.filters, sortData });
  }

  onPage(pageData: PageEvent) {
    this.facade.filter({ filters: this.viewData.filters, pageData });
  }

  onNoResultsActionClicked(data: NoResultsFoundActionData) {
    switch (data?.actionName) {
      case SubmissionNoResultsFoundActionName.NewSubmission:
        return this.router.navigate(['/submission/new']);
      case SubmissionNoResultsFoundActionName.ClearFilters:
        return this.removeFiltersByChips(this.viewData.filtersChipList);
    }
  }

  private removeFiltersByChips(chips: FilterChip[]) {
    this.facade.removeFiltersByChips(this.viewData.filters, chips);
  }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    if (data.isSuccess) {
      this.refreshComponentData(this.queryParams);
    }
  }
}
