import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppBarComponent, AppPageComponent, AppSidenavComponent } from "common";
import { appBarData, appBarDropdownData } from "../../app.data";
import { AppPageResolver, AppSidenavItemsResolver } from "../../app.resolver";
import { AppBarDropdownComponent } from "../../shared/app-bar-dropdown/app-bar-dropdown.component";
import { HomeComponent } from "./features/home/home.component";

const routes: Routes = [
  {
    path: "dashboard",
    component: AppPageComponent,
    data: {
      appBar: appBarData(),
      appBarDropdown: appBarDropdownData(),
    },
    resolve: {
      appPage: AppPageResolver,
      appSidenav: AppSidenavItemsResolver,
    },
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarDropdownComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "sidenav-content",
        component: AppSidenavComponent,
      },
      {
        path: "",
        title: "Dashboard – Idea Financial Partner",
        component: HomeComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
