import { Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  ApplicationStage,
  MessageService,
} from "common";
import {
  MatStep,
  MatStepper,
  StepperOrientation,
} from "@angular/material/stepper";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {
  businessInformationAppBarActions,
  businessInformationIndex,
  documentsAndCashFlowIndex,
  getBusinessInformationForm,
  getDocumentsAndCashFlowForm,
  getOwnerForm,
  getOwnerInformationForm,
  ownerInformationIndex,
} from "../submission.data";
import {
  BrokerDataDetails,
  BrokerUserData,
} from "../../broker/domain/models/broker.model";
import {
  BehaviorSubject,
  combineLatest,
  concat,
  defer,
  EMPTY,
  Observable,
  of,
  throwError,
} from "rxjs";
import { finalize, map, mergeMap, tap } from "rxjs/operators";
import {
  hasValue,
  hasValueFn,
} from "projects/partner/src/Tools";
import { MatDialog } from "@angular/material/dialog";
import { ThankYouDialogComponent } from "../thank-you-dialog/thank-you-dialog.component";
import { SubmissionService } from "../../shared/submission.service";
import {
  ApplicationResponse,
  ErrorResponse,
  OwnerData,
  MatHeaderOptions,
} from "../models/submission.model";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { SubmissionFormFacade } from "./submission-form.facade";
import { IncompleteSubmissionDialogComponent } from "../incomplete-submission-dialog/incomplete-submission-dialog.component";
import { ObjectHelper } from "common";
import { SubmissionFormService } from "./submission-form.service";

@UntilDestroy()
@Component({
  selector: "ifbp-submission-form",
  templateUrl: "./submission-form.component.html",
  styleUrls: ["./submission-form.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true, displayDefaultIndicatorType: false },
    },
    SubmissionFormFacade,
    SubmissionFormService
  ],
})
export class SubmissionFormComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;
  @ViewChild("businessInformationStep") businessInformationStep: MatStep;
  @ViewChild("ownerInformationStep") ownerInformationStep: MatStep;
  @ViewChild("documentsAndCashFlowStep") documentsAndCashFlowStep: MatStep;

  applicationId: number;
  businessInformationForm: UntypedFormGroup;
  brokerDataDetails: BrokerDataDetails;
  brokerUser: BrokerUserData;
  currentFormNonEmptyStatuses: BehaviorSubject<Observable<string>[]>;
  documentsAndCashFlowForm: UntypedFormGroup;
  accountId: number;
  isInProgressBs: BehaviorSubject<boolean>;
  isInProgress$: Observable<boolean>;
  missingInformation: string | undefined;
  ownerInformationForm: UntypedFormGroup;
  selectedStep: number;
  showCurrentFormErrorsBs: BehaviorSubject<boolean>;
  showCurrentFormErrors$: Observable<boolean>;
  stepperOrientation: Observable<StepperOrientation>;
  isSubmitClickedBs: BehaviorSubject<boolean>;
  isSubmitClicked$: Observable<boolean>;
  matHeaderOptions: MatHeaderOptions = {};
  formAlreadySubmitted: boolean;

  readonly businessInformationIndex = businessInformationIndex;
  readonly ownerInformationIndex = ownerInformationIndex;
  readonly documentsAndCashFlowIndex = documentsAndCashFlowIndex;

  constructor(
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private appBarActionsService: AppBarActionsService,
    private appBarTitleService: AppBarTitleService,
    private appPageService: AppPageService,
    private messageService: MessageService,
    private submissionService: SubmissionService,
    private facade: SubmissionFormFacade,
    private submissionFormService: SubmissionFormService
  ) {
    this.isInProgressBs = new BehaviorSubject<boolean>(false);
    this.isInProgress$ = this.isInProgressBs.asObservable();

    this.currentFormNonEmptyStatuses = new BehaviorSubject<
      Observable<string>[]
    >([]);

    this.isSubmitClickedBs = new BehaviorSubject<boolean>(false);
    this.isSubmitClicked$ = this.isSubmitClickedBs.asObservable();

    this.showCurrentFormErrorsBs = new BehaviorSubject<boolean>(false);
    this.showCurrentFormErrors$ = this.showCurrentFormErrorsBs.asObservable();

    this.stepperOrientation = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(map(({ matches }) => (matches ? "vertical" : "horizontal")));

    this.businessInformationForm = new FormGroup(
      getBusinessInformationForm(),
    );
    this.ownerInformationForm = new FormGroup(getOwnerInformationForm());
    this.documentsAndCashFlowForm = new FormGroup(
      getDocumentsAndCashFlowForm(),
    );

    this.selectedStep = 0;
    this.appBarActionsService.actions = businessInformationAppBarActions;
  }

  ngOnInit() {
    this.initRouteData();
    this.initAppBarActions();
    combineLatest([
      this.isSubmitClicked$,
      this.showCurrentFormErrors$,
      this.businessInformationForm.statusChanges,
      this.ownerInformationForm.statusChanges,
      this.documentsAndCashFlowForm.statusChanges,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateMatHeaderOptions();
      });
    this.currentFormNonEmptyStatuses
      .pipe(
        untilDestroyed(this),
        mergeMap(statuses => combineLatest(statuses)),
      )
      .subscribe(statuses => {
        this.showCurrentFormErrorsBs.next(statuses.includes("INVALID"));
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateMatHeaderOptions();
      this.stepper.steps.forEach((step, stepIndex) => {
        step.select = () => {
          if (this.selectedStep !== stepIndex && this.canSave()) {
            this.saveForm().subscribe({
              next: () => {
                this.selectedStep = stepIndex;
              },
            });
          }
        };
      });
    });
  }

  private initRouteData() {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.brokerUser = data.brokerUser;
      this.brokerDataDetails = data.brokerDataDetails;
    });
    this.route.paramMap
      .pipe(
        untilDestroyed(this),
        mergeMap(params => {
          const applicationId = params.get("id");
          if (hasValue(applicationId)) {
            this.applicationId = parseInt(applicationId);
            return this.facade.initData(this.applicationId);
          }
          return EMPTY;
        }),
      )
      .subscribe(data => {
        this.accountId = data.accountId;
        this.appBarTitleService.title = `${data.businessInformationFormData.requiredToSave.name} - Incomplete`;
        this.missingInformation = data.missingInformation;
        this.openMissingInformationDialog();
        this.businessInformationForm.patchValue(
          data.businessInformationFormData,
        );
        this.businessInformationForm.updateValueAndValidity();
        if (data.ownerInformationFormData.length > 0) {
          this.removeOwner(0);
        }
        for (const owner of data.ownerInformationFormData) {
          this.addOwner(owner);
        }
        this.documentsAndCashFlowForm.patchValue({
          applicationStage: data.businessInformationFormData.stage ?? ApplicationStage.New,
          signedApplicationFiles: data.existingSignedApplicationFiles,
          bankStatementFiles: data.existingBankStatementFiles,
        });
      });
  }

  private initAppBarActions() {
    this.isInProgress$.pipe(untilDestroyed(this)).subscribe(isInProgress => {
      const actions = this.appBarActionsService.actions;
      this.setActionDisabled("submit", isInProgress, actions);
      this.appBarActionsService.actions = [...actions];
    });
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
  }

  private setActionDisabled(
    actionName: string,
    isDisabled: boolean,
    actions: AppBarAction[],
  ) {
    const actionToDisable = actions.find(action => action.id === actionName);
    if (hasValue(actionToDisable)) {
      actionToDisable.disabled = isDisabled;
    }
  }

  actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  addOwner(initialValues?: OwnerData) {
    const newOwner = new FormGroup(
      getOwnerForm(initialValues),
    );
    (this.ownerInformationForm.controls.owners as FormArray).push(newOwner);
  }

  removeOwner(ownerIndex: number) {
    (this.ownerInformationForm.controls.owners as FormArray).removeAt(
      ownerIndex,
    );
  }

  private updateExistingOwners(ownerResponses?: OwnerData[]) {
    const ownersArray = this.ownerInformationForm.controls.owners as FormArray;
    for (let i = 0; i < ownersArray.length; i++) {
      ownersArray.controls.at(i)?.patchValue({
        isExisting: true,
        id: ownerResponses?.[i].id ?? ownersArray.controls.at(i)?.value.id,
      });
    }
  }

  private getFormGroupNonemptyValidity(form: UntypedFormGroup): boolean {
    let isValid = true;
    for (const field of Object.keys(form.controls)) {
      const control = form.get(field);
      if (ObjectHelper.isEmpty(control?.value, true)) {
        continue;
      } else if (control instanceof FormControl) {
        control["isNonempty"] = true;
        if (!this.isSubmitClickedBs.value) {
          this.updateStatusesArray(control);
        }
        isValid = isValid && control.valid;
      } else if (control instanceof FormGroup) {
        isValid = isValid && this.getFormGroupNonemptyValidity(control);
      } else if (control instanceof FormArray) {
        isValid = isValid && this.getFormArrayValidity(control);
      }
    }
    return isValid;
  }

  private getFormArrayValidity(array: FormArray): boolean {
    let isValid = true;
    for (const control of array.controls) {
      isValid =
        isValid &&
        this.getFormGroupNonemptyValidity(control as UntypedFormGroup);
    }
    return isValid;
  }

  private updateStatusesArray(control: FormControl) {
    const observables: Observable<string>[] =
      this.currentFormNonEmptyStatuses.getValue();
    observables.push(
      concat(
        defer(() => of(control.status)),
        control.statusChanges,
      ),
    );
    this.currentFormNonEmptyStatuses.next(observables);
  }

  private canSave(): boolean {
    this.isInProgressBs.next(true);
    this.currentFormNonEmptyStatuses.next([]);
    const currentForm =
      this.selectedStep === businessInformationIndex
        ? this.businessInformationForm
        : this.selectedStep === ownerInformationIndex
          ? this.ownerInformationForm
          : this.documentsAndCashFlowForm;

    const requiredToSaveFormGroup = currentForm.get("requiredToSave");

    let isValid =
      hasValueFn(requiredToSaveFormGroup, v => v.valid) ?? true;
    if (
      !this.isSubmitClickedBs.value &&
      hasValue(requiredToSaveFormGroup)
    ) {
      this.updateStatusesArray(
        requiredToSaveFormGroup as FormControl,
      );
    }
    isValid = isValid && this.getFormGroupNonemptyValidity(currentForm);
    this.showCurrentFormErrorsBs.next(!isValid);

    if (isValid) {
      return true;
    }
    if (requiredToSaveFormGroup?.touched) {
      this.submissionFormService.missingRequiredToSaveValues();
      return false;
    }
    requiredToSaveFormGroup?.markAllAsTouched();
    return false;
  }

  save() {
    if (this.canSave()) {
      this.saveForm().subscribe();
    }
  }

  next() {
    if (this.canSave()) {
      this.saveForm().subscribe({
        next: () => {
          this.selectedStep += 1;
        },
      });
    }
  }

  back() {
    if (this.canSave()) {
      this.saveForm().subscribe({
        next: () => {
          this.selectedStep -= 1;
        },
      });
    }
  }

  private getAllFormsValid(): boolean {
    return (
      this.businessInformationForm.valid &&
      this.ownerInformationForm.valid &&
      this.documentsAndCashFlowForm.valid
    );
  }

  submit() {
    this.isSubmitClickedBs.next(true);
    Object.keys(this.documentsAndCashFlowForm.controls).forEach(key => {
      this.documentsAndCashFlowForm.get(key)?.updateValueAndValidity();
    });
    this.facade
      .saveDocumentsAndCashFlowForm(
        this.applicationId,
        this.documentsAndCashFlowForm,
      )
      .pipe(
        mergeMap(() => {
          if (!this.getAllFormsValid()) {
            return of({});
          }
          return this.submissionService.submitNewSubmission(this.applicationId);
        }),
        finalize(() => this.actionComplete()),
      )
      .subscribe(() => {
        if (this.getAllFormsValid()) {
          this.formAlreadySubmitted = true;
          this.openThankYouDialog();
        }
      });
  }

  private saveForm(): Observable<
    void | ErrorResponse | ApplicationResponse | OwnerData[]
  > {
    let result;
    switch (this.selectedStep) {
      case businessInformationIndex:
        result = this.saveBusinessInformationForm();
        break;
      case ownerInformationIndex:
        result = this.saveOwnerInformationForm();
        break;
      case documentsAndCashFlowIndex:
        result = this.saveDocumentsAndCashFlowForm();
        break;
      default:
        console.error("Stepper step is out of bounds.");
        result = throwError({ hasError: true });
        break;
    }
    return result;
  }

  private saveBusinessInformationForm(): Observable<
    ErrorResponse | ApplicationResponse
  > {
    return this.facade
      .saveBusinessInformationForm(
        this.applicationId,
        this.accountId,
        this.brokerUser.brokerId,
        this.businessInformationForm,
      )
      .pipe(
        tap({
          next: response => {
            this.applicationId = (response as ApplicationResponse).id;
            this.accountId = (response as ApplicationResponse).accountId;
            this.onSaveSuccess();
          },
        }),
        finalize(() => this.actionComplete()),
      );
  }

  private saveOwnerInformationForm(): Observable<OwnerData[] | ErrorResponse> {
    return this.facade
      .saveOwnerInformationForm(
        this.applicationId,
        this.accountId,
        this.brokerUser.brokerId,
        this.ownerInformationForm,
      )
      .pipe(
        tap({
          next: response =>
            this.onSaveSuccess({ ownerResponses: response as OwnerData[] }),
        }),
        finalize(() => this.actionComplete()),
      );
  }

  private saveDocumentsAndCashFlowForm(): Observable<void> {
    return this.facade
      .saveDocumentsAndCashFlowForm(
        this.applicationId,
        this.documentsAndCashFlowForm,
      )
      .pipe(
        tap({ next: () => this.onSaveSuccess() }),
        finalize(() => this.actionComplete()),
      );
  }

  private openMissingInformationDialog() {
    this.dialog.open(IncompleteSubmissionDialogComponent, {
      data: { missingInformation: this.missingInformation },
    });
  }

  private openThankYouDialog() {
    const dialog = this.dialog.open(ThankYouDialogComponent, {
      data: { brokerDataDetails: this.brokerDataDetails },
    });
    dialog.afterClosed().subscribe(() => {
      this.appPageService.back();
    });
  }

  private actionComplete() {
    this.isInProgressBs.next(false);
  }

  private onSaveSuccess(options?: { ownerResponses?: OwnerData[] }) {
    setTimeout(() => {
      this.currentFormNonEmptyStatuses.next([]);
      this.updateExistingOwners(options?.ownerResponses);
      this.messageService.success(
        "Your submission has been successfully saved.",
      );
    });
  }

  stepChanged(newStep: number) {
    setTimeout(() => {
      this.selectedStep = newStep;
      this.appBarActionsService.actions = this.facade.getAppBarActions(
        this.selectedStep,
      );
      this.updateMatHeaderOptions();
    });
  }

  private getStepDetails(stepIndex: number) {
    switch (stepIndex) {
      case businessInformationIndex:
        return {
          step: this.businessInformationStep,
          form: this.businessInformationForm,
        };
      case ownerInformationIndex:
        return {
          step: this.ownerInformationStep,
          form: this.ownerInformationForm,
        };
      case documentsAndCashFlowIndex:
        return {
          step: this.documentsAndCashFlowStep,
          form: this.documentsAndCashFlowForm,
        };
      default:
        console.error("Stepper step is out of bounds.");
        return { step: null, form: null };
    }
  }

  private updateMatHeaderOptions() {
    for (let i = 0; i < 3; i++) {
      const { step, form } = this.getStepDetails(i);
      if (!hasValue(step) || !hasValue(form)) {
        return;
      }
      const state =
        this.selectedStep !== i && !this.isSubmitClickedBs.value
          ? "number"
          : (this.isSubmitClickedBs.value ||
            (this.showCurrentFormErrorsBs.value &&
              this.selectedStep === i)) &&
            !form.valid
            ? "error"
            : "edit";
      const hasError =
        (this.isSubmitClickedBs.value ||
          (this.showCurrentFormErrorsBs.value && this.selectedStep === i)) &&
        !form.valid;
      const completed =
        step.interacted ||
          this.isSubmitClickedBs.value ||
          (this.showCurrentFormErrorsBs.value && this.selectedStep === i)
          ? true
          : false;
      this.matHeaderOptions[i] = { state, hasError, completed };
    }
  }

  requiredToSubmit(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      this.isSubmitClickedBs.value && ObjectHelper.isEmpty(control?.value, true)
        ? { required: true }
        : null;
  }

  ngOnDestroy() {
    if (!this.formAlreadySubmitted) {
      this.save();
    }
  }
}
