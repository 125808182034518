<button *ngIf="brokerStatus === BrokerStatus.INCOMPLETE" mat-flat-button color="warn">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>error</mat-icon>
    Incomplete
  </div>
</button>

<span *ngIf="brokerStatus === BrokerStatus.PROCESSING">
  Processing
</span>

<button *ngIf="brokerStatus === BrokerStatus.CLOSING" mat-flat-button color="closing">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>assignment</mat-icon>
    Closing
  </div>
</button>

<button *ngIf="brokerStatus === BrokerStatus.OFFER" mat-flat-button color="primary">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>monetization_on</mat-icon>
    Offer
  </div>
</button>

<button *ngIf="brokerStatus === BrokerStatus.CONDITIONAL_OFFER" mat-flat-button color="primary">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>monetization_on</mat-icon>
    Conditional Offer
  </div>
</button>

<button *ngIf="brokerStatus === BrokerStatus.CONTRACT_READY" mat-flat-button color="primary">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>
      <span class="material-symbols-outlined">
      contract
      </span>
    </mat-icon>
    Contract Ready
  </div>
</button>

<button *ngIf="brokerStatus === BrokerStatus.CONTRACT_OUT" mat-flat-button color="contract-out">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>
      <span class="material-symbols-outlined">
        contract_edit
      </span>
    </mat-icon>
    Contract Out
  </div>
</button>

<button *ngIf="brokerStatus === BrokerStatus.DECLINED" mat-flat-button color="declined">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>report</mat-icon>
    Declined
  </div>
</button>

<span *ngIf="brokerStatus === BrokerStatus.OPEN">
  Open
</span>

<span *ngIf="brokerStatus === BrokerStatus.FUNDED">
  Funded
</span>

<span *ngIf="brokerStatus === BrokerStatus.CLOSED">
  Closed
</span>

<span *ngIf="brokerStatus === BrokerStatus.DORMANT">
  Dormant
</span>

<span *ngIf="brokerStatus === BrokerStatus.NOT_INTERESTED">
  Not Interested
</span>