import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BrokerState } from "../models/broker-state.model";
import { StateService } from "common";
import { BrokerUserData, BrokerDataDetails } from "../models/broker.model";

const initialState: BrokerState = null;

@Injectable({
  providedIn: "root",
})
export class BrokerStateService extends StateService<BrokerState> {
  constructor() {
    super(initialState);
  }

  getBroker$(): Observable<BrokerUserData> {
    return this.select((state: BrokerState) => state?.broker);
  }

  getBrokerDataDetails$(): Observable<BrokerDataDetails> {
    return this.select((state: BrokerState) => state?.brokerDataDetails);
  }

  setBroker(broker: BrokerUserData): void {
    const newState = {
      ...this.state,
      broker,
    };
    this.setState(newState);
  }

  setBrokerDataDetails(brokerDataDetails: BrokerDataDetails): void {
    const newState = {
      ...this.state,
      brokerDataDetails,
    };
    this.setState(newState);
  }

  isBrokerStateLoaded(): boolean {
    return !!this.state?.broker;
  }

  isBrokerDataDetailsStateLoaded(): boolean {
    return !!this.state?.brokerDataDetails;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
