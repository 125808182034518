import { QueryResult } from "common";

export enum UserDbRole {
  ADMIN = "administrator",
  USER = "user",
}

export interface BrokerUserData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: UserDbRole;
  brokerId: number;
  mobilePhone: string;
  active: boolean;
  status: BrokerUserStatus;
}

export enum BrokerUserStatus {
  None = "none",
  Invited = "invited",
  Active = "active",
  Disabled = "disabled"
}

export const BrokerUserStatusLabel = new Map<BrokerUserStatus, string>([
  [BrokerUserStatus.None, "None"],
  [BrokerUserStatus.Invited, "Invited"],
  [BrokerUserStatus.Active, "Active"],
  [BrokerUserStatus.Disabled, "Disabled"],
]);

export type BrokerProfileData = Pick<
  BrokerUserData,
  "firstName" | "lastName" | "mobilePhone"
>;

export type BrokerEditUserData = Omit<BrokerUserData, "id" | "status">;

export interface BrokerDataDetails {
  advisorFirstName: string;
  advisorLastName: string;
  advisorName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorCalendarLinkUrl: string;
  advisorAvatar: string;
  name: string;
}

export interface BrokerPasswordChangeRequest {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface BrokerUsersQueryResult extends QueryResult<BrokerUserData> {}
