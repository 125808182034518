import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader, SortDirection } from "@angular/material/sort";
import {
  UserFilters,
  UserOverflowActions,
  UserData,
  UsersQueryResult,
  UserDbRole,
  UserStatus,
  UserStatusLabel,
} from "../../domain/models/users.model";
import {
  TeamSidenavFilters,
  WebPaths,
  userRoleUiMap,
} from "../../../../shared/SharedConstants";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AppSidenavItem,
  AppSidenavService,
  AppSidenavSubItem,
  ButtonColor,
  DialogActionComponent,
  DialogActionData,
  MessageService,
  QueryParamsService,
} from "common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import fastDeepEqual from "fast-deep-equal";
import {
  hasValue,
  setPaginatorFromQueryVars,
} from "projects/partner/src/Tools";
import { UserListFacade } from "./user-list.facade";
import { UsersFacade } from "../../domain/+state/users.facade";
import { getNotificationSentDialogData } from "../user-details/user-details.data";

@UntilDestroy()
@Component({
  selector: "ifbp-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) matSort?: MatSort;

  brokerUsers: UsersQueryResult;
  currentBrokerUser: UserData;
  defaultSortActive = "lastName";
  defaultSortDirection: SortDirection = "asc";
  displayedColumns = [
    "firstName",
    "lastName",
    "mobilePhone",
    "email",
    "status",
    "role",
    "overflow",
  ];
  filters: UserFilters;
  menuOpenedRow: UserData;
  readonly UserStatus = UserStatus;
  readonly UserStatusLabel = UserStatusLabel;
  readonly teamUserRoleUiMap = userRoleUiMap;
  readonly UserOverflowActions = UserOverflowActions;
  queryParams: any;
  readonly WebPaths = WebPaths;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private appSidenavService: AppSidenavService,
    private userListFacade: UserListFacade,
    private userFacade: UsersFacade,
    private queryParamsService: QueryParamsService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.brokerUsers = data.brokerUsersQueryResult;
      this.currentBrokerUser = data.brokerUser;
    });
    this.route.queryParams.subscribe(it => {
      this.queryParams = this.queryParamsService.init(it);
      const prevFilters = this.filters;
      this.filters = new UserFilters(this.queryParams);
      this.initSort(prevFilters);
      this.setSelectedSidenavItem();
    });
  }

  private initSort(prevFilters: UserFilters) {
    if (!fastDeepEqual(prevFilters, this.filters)) {
      setPaginatorFromQueryVars(this.paginator, this.queryParams);
      if (
        (!fastDeepEqual(prevFilters?.active, this.filters?.active) ||
          !fastDeepEqual(prevFilters?.roles, this.filters?.roles)) &&
        hasValue(this.matSort)
      ) {
        // https://github.com/angular/components/issues/10242#issuecomment-470726829
        this.matSort?.sort({
          id: "",
          start: this.defaultSortDirection,
          disableClear: false,
        });
        this.matSort?.sort({
          id: this.defaultSortActive,
          start: this.defaultSortDirection,
          disableClear: false,
        });
        (
          this.matSort?.sortables.get(this.defaultSortActive) as MatSortHeader
        )._setAnimationTransitionState({
          toState: "active",
        });
      }
    }
  }

  private setSelectedSidenavItem() {
    const sidenavItems: AppSidenavItem[] =
      this.route.parent?.snapshot.data.appSidenav.items;
    let selectedFn: (item: AppSidenavItem | AppSidenavSubItem) => boolean;
    if (this.queryParams.roles === UserDbRole.ADMIN) {
      selectedFn = item => item.label === TeamSidenavFilters.ADMINS;
    } else if (this.queryParams.roles === UserDbRole.USER) {
      selectedFn = item => item.label === TeamSidenavFilters.TEAM_MEMBERS;
    } else if (this.queryParams.active === "false") {
      selectedFn = item => item.label === TeamSidenavFilters.DEACTIVATED_USERS;
    } else {
      selectedFn = item => item.label === TeamSidenavFilters.TEAM;
    }
    this.appSidenavService.items = sidenavItems.map(item => ({
      ...item,
      selected: selectedFn(item),
      subitems: item.subitems?.map(subitem => ({
        ...subitem,
        selected: selectedFn(subitem),
      })),
    }));
  }

  goToUserDetails(id: number) {
    if (id !== this.currentBrokerUser.id) {
      void this.router.navigate([WebPaths.EditUser({ user: { id } })]);
    }
  }

  openConfirmDialog(
    teamOverflowAction: UserOverflowActions,
    brokerUser: UserData,
  ) {
    const dialogData: DialogActionData = {
      body: `${teamOverflowAction} user ${brokerUser.firstName} ${brokerUser.lastName}?`,
      buttons: [
        {
          label: "CANCEL",
          buttonColor: ButtonColor.Default,
          action: false,
        },
        {
          label: "OK",
          buttonColor: ButtonColor.Primary,
          action: true,
        }
      ],
      config: {
        width: "400px",
        closeOnNavigation: true,
        autoFocus: false,
      }
    };

    DialogActionComponent.show(this.dialog, dialogData).subscribe({
      next: (value) => {
         if (value) {
          this.userFacade
            .updateUser(brokerUser.id, {
              ...brokerUser,
              active: false,
            })
            .subscribe({
              next: () => {
                const message =
                teamOverflowAction === UserOverflowActions.DISABLE
                  ? "Team member has been successfully deactivated."
                  : "Team member has been successfully deleted.";
                this.messageService.success(message);
              },
              complete: () => void this.router.navigate([], { queryParams: this.queryParams })
            });
          }
      },
    });
  }

  resendInvitation(user: UserData) {
    this.userFacade
    .resendInvitation(user.id)
    .pipe(untilDestroyed(this))
    .subscribe({
      next: () => this.openNotificationSentDialog(user),
      error: () => this.messageService.error("Error occured while trying to resend activation email.")
    });
  }

  private openNotificationSentDialog(user: UserData) {
    DialogActionComponent.show(this.dialog, getNotificationSentDialogData(user)).subscribe(() => {
      this.router.navigate([], { queryParams: this.queryParams })
    });
  }

  filter() {
    this.userListFacade.filter(this.filters, this.paginator, this.matSort);
  }
}
