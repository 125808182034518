import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { ClosingReviewRoutingModule } from "./closing-review-routing.module";
import { ClosingReviewComponent } from "./closing-review.component";
import { SubmissionsModule } from "../feature-modules/submissions/submissions.module";
@NgModule({
  imports: [CommonModule, ClosingReviewRoutingModule, SharedModule, SubmissionsModule],
  declarations: [ClosingReviewComponent],
})
export class ClosingReviewModule { }
