import { Injectable } from "@angular/core";
import { EMPTY, Observable, combineLatest } from "rxjs";
import { SubmissionsRemote } from "../../infrastructure/submissions.remote";
import { ApplicationStage, MessageService } from "common";
import { SubmissionDetailsService } from "./submission-details.service";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { ApplicationData, SubmissionQueryParams, SubmissionsHintsQueryParams, SubmissionsHintsResponse, SubmissionsQueryResult, UpdateApplicationPayload } from "../models/submissions.model";
import { NotInterestedDialogData } from "../../features/not-interested-dialog/not-interested-dialog.model";
import { DictionariesFacade } from "../../../dictionaries/domain/+state/dictionaries.facade";
import { CompetitorsFacade } from "../../../competitors/domain/+state/competitors.facade";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SubmissionsFacade {
  constructor(
    private readonly remote: SubmissionsRemote,
    private readonly submissionDetailsService: SubmissionDetailsService,
    private readonly lostReasonsFacade: DictionariesFacade,
    private readonly competitorsFacade: CompetitorsFacade,
    private readonly messageService: MessageService
  ) { }


  updateApplicationStage(
    applicationId: number,
    payload: UpdateApplicationPayload,
  ): Observable<ApplicationData> {
    return this.remote.updateApplicationStage(applicationId, payload);
  }

  goToSubmissionDetails(submissionId: number, brokerStatus: BrokerStatus, applicationStage: ApplicationStage) {
    return this.submissionDetailsService.goToSubmissionDetails(submissionId, brokerStatus, applicationStage);
  }

  getNotInterestedDialogData(): Observable<NotInterestedDialogData> {
    return combineLatest([this.lostReasonsFacade.getLostReasons$(),
    this.competitorsFacade.getCompetitors$()]).pipe(
      map(([lostReasons, competitors]) => ({
        notInterestedReasons: lostReasons,
        competitors: competitors?.filter(competitor =>
          competitor.apiName)
      }))
    );
  }

  getSubmissions(params: SubmissionQueryParams): Observable<SubmissionsQueryResult> {
    return this.remote.getSubmissions(params).pipe(catchError(error => {
      this.messageService.error(error?.error?.Message);
      return EMPTY;
    }));
  }

  getSubmissionsHints(params: SubmissionsHintsQueryParams): Observable<string[]> {
    return this.remote.getSubmissionsHints(params);
  }
}
