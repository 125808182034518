<mat-input-container fxFlex.gt-xs="100" [formGroup]="form">
  <div>
    <div fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Street address</mat-label>
        <input
          matInput
          #inlineInput
          formControlName="line1"
        />
        <mat-error *ngIf="form.get('line1').errors | hasControlError:['required']">Street address is required.</mat-error>
        <mat-error *ngIf="form.get('line1').errors | hasControlError:['maxlength']">Street address is too long.</mat-error>
        <mat-error *ngIf="form.get('line1').errors | hasControlError:['minlength']">Street address is too short.</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Unit/Suite/Apt #</mat-label>
        <input
          matInput
          formControlName="line2"
        />
        <mat-error *ngIf="form.get('line2').errors | hasControlError:['maxlength']">Unit/Suite/Apt # is too long.</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px" fxLayoutGap.lt-md="0px">
      <mat-form-field appearance="outline" fxFlex.gt-xs="calc(33% - 12px)">
        <mat-label>City</mat-label>
        <input
          matInput
          #cityInput
          formControlName="city"
        />
        <mat-error *ngIf="form.get('city').errors | hasControlError:['required']">City is required.</mat-error>
        <mat-error *ngIf="form.get('city').errors | hasControlError:['maxlength']">City is too long.</mat-error>
        <mat-error *ngIf="form.get('city').errors | hasControlError:['minlength']">City is too short.</mat-error>
      </mat-form-field>
      <div fxFlex.gt-xs="calc(33% - 12px)">
        <mat-form-field appearance="outline" fxFlex.gt-xs="100" >
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{state.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('city').errors | hasControlError:['required']">State is required.</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" fxFlex.gt-xs="calc(33% - 12px)">
        <mat-label>Zip</mat-label>
        <input
          matInput
          formControlName="zip"
          mask="00000"
        />
        <mat-error *ngIf="form.get('zip').errors | hasControlError:['required']">Zip code is required.</mat-error>
        <mat-error *ngIf="form.get('zip').errors | hasControlError:['pattern']">Please enter a valid zip code.</mat-error>
      </mat-form-field>
    </div>
  </div>
</mat-input-container>
