import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApplicationBaseData, BrandingService } from "common";
import { BankLinkEmailService } from "./send-bank-link-email.service";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-send-bank-link-email",
  templateUrl: "./send-bank-link-email.component.html",
  styleUrls: ["./send-bank-link-email.component.scss"],
})
export class SendBankLinkEmailComponent implements OnInit {
  form: UntypedFormGroup;

  companyName: string;
  emailBody: string;
  filteredOptions: Observable<string[]>;

  constructor(
    public dialogRef: MatDialogRef<SendBankLinkEmailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      application: ApplicationBaseData;
    },
    private formBuilder: UntypedFormBuilder,
    private brandingService: BrandingService,
    private bankLinkEmailService: BankLinkEmailService,
  ) {

    this.form = this.formBuilder.group({
      email: [
        undefined,
        [Validators.required, Validators.email],
      ],
    });
  }

  ngOnInit(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe(companyData => {
        this.companyName = companyData.dba ?? "";
      });

    this.filteredOptions = this.form.controls.email.valueChanges.pipe(
      startWith(this.form.controls.email.value),
      debounceTime(500),
      distinctUntilChanged(),
      map(value => this._filter(value || '')),
    );
  }

  sendBankLinkEmail() {
    if (!this.form.valid) {
      return;
    }
    this.bankLinkEmailService
      .sendBankLinkEmail(
        this.data.application.id as number,
        this.form.value.email,
      )
      .subscribe(() => this.dialogRef.close());
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.data?.application?.contactEmails?.filter(option => option.toLowerCase().includes(filterValue)) ?? [];
  }
}
