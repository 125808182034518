import { Options } from "@angular-slider/ngx-slider";
import {
  ApplicationOffer,
  ApplicationOfferBundleData,
  OfferBundleCheckoutRequirement,
} from "common";

export interface ValidOfferBundle
  extends Omit<ApplicationOfferBundleData, "offers"> {
  offers: ValidOffer[];
}

export interface ValidOffer
  extends Omit<ApplicationOffer, "repaymentTerm" | "amount" | "drawDownFee"> {
  repaymentTerm: number;
  amount: number;
  drawDownFee: number;
}

export interface SliderStepData {
  repaymentTerm: number;
  amount: number;
  index: number;
}

export enum SliderType {
  AMOUNT = "amount",
  REPAYMENT_TERM = "repaymentTerm",
}

export interface CheckoutRequirementsData {
  [id: number]: OfferBundleCheckoutRequirement[];
}

export enum SliderSetMappingType {
  AMOUNT_TO_TERM = "amountToTermMapping",
  TERM_TO_AMOUNT = "termToAmountMapping",
}

export interface AmountToTermMappingEntry {
  repaymentTerm: number;
  index: number;
}

export interface TermToAmountMappingEntry {
  amount: number;
  index: number;
}

interface AmountToTermMapping {
  [value: string]: AmountToTermMappingEntry[];
}

interface TermToAmountMapping {
  [value: string]: TermToAmountMappingEntry[];
}

interface SliderSetData {
  loanAmountOptions: Options;
  termOptions: Options;
  amountToTermMapping: AmountToTermMapping;
  termToAmountMapping: TermToAmountMapping;
}

export interface SliderSets {
  [loanType: string]: SliderSetData;
}
