import { AppBarCloseAction, AppBarOptions, AppPageOptions } from "common";

export const appBarData = (): AppBarOptions => {
  return {
    logoSize: "logo-s",
    isSidenavAppBar: true,
    closeAction: AppBarCloseAction.SIDENAV,
    closeIcon: "keyboard_arrow_left",
    backgroundClass: "dashboard-app-bar",
    containerWrapperClass: "dashboard-app-bar",
    containerClass: "space-between-center",
    sidenavOpenIcon: "keyboard_arrow_right",
  };
};

export const appUserBarData = (): AppBarOptions => {
  return {
    closeAction: AppBarCloseAction.BACK,
    logoHidden: true,
    containerClass: "start-center",
  };
};

export const appPageData = (): AppPageOptions => {
  return {
    contentClass: "simple",
    contentAlignment: "center-start",
    contentWithoutPadding: true,
    sidenavSubtitle: "",
    sidenavTitle: "",
  };
};

