import { NgModule } from '@angular/core';
import { FiltersChipListComponent } from './filters-chip-list.component';
import { SharedModule } from 'projects/partner/src/app/shared/shared.module';

@NgModule({
  imports: [SharedModule],
  exports: [FiltersChipListComponent],
  declarations: [FiltersChipListComponent]
})
export class FiltersChipListModule { }
