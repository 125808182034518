import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { states } from "common";

declare let google: any;

@Component({
  selector: "ifbp-address-form",
  templateUrl: "./address-form.component.html",
})
export class AddressFormComponent implements OnInit {
  @ViewChild("inlineInput", { read: ElementRef, static: true })
  inlineInput: ElementRef;

  @ViewChild("cityInput", { read: ElementRef, static: true })
  cityInput: ElementRef;

  @Input() form: FormGroup;

  states = states;

  googleAutocompleteStreet: any;
  googleAutocompleteCity: any;
  autocompleteEnabled = true;

  constructor(private zone: NgZone) {}

  ngOnInit() {
    try {
      this.googleAutocompleteStreet = new google.maps.places.Autocomplete(
        this.inlineInput.nativeElement,
        this.googleAutocompleteOptions,
      );
      this.googleAutocompleteStreet.addListener("place_changed", () =>
        this.googleAutocompletePlaceChange(this.googleAutocompleteStreet),
      );

      this.googleAutocompleteCity = new google.maps.places.Autocomplete(
        this.cityInput.nativeElement,
        this.googleAutocompleteOptionsCity,
      );
      this.googleAutocompleteCity.addListener("place_changed", () =>
        this.googleAutocompletePlaceChange(this.googleAutocompleteCity),
      );
    } catch (e) {
      this.autocompleteEnabled = false;
    }
  }
  private googleAutocompleteOptions = {
    types: [],
    componentRestrictions: { country: "us" },
  };

  private googleAutocompleteOptionsCity = {
    types: ["(cities)"],
    componentRestrictions: { country: "us" },
  };

  googleAutocompleteNameMapping = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    postal_code: "short_name",
  };

  googleAutocompletePlaceChange(source: any) {
    this.zone.run(() => {
      const place = source.getPlace();

      this.form.get("line1")?.setValue("");
      this.form.get("line2")?.setValue("");
      this.form.get("city")?.setValue("");
      this.form.get("state")?.setValue("");
      this.form.get("zip")?.setValue("");

      (place.address_components || []).forEach((component: any) => {
        const addressType = component.types[0];
        if (!this.googleAutocompleteNameMapping[addressType]) {
          return;
        }
        const value: string =
          component[this.googleAutocompleteNameMapping[addressType]];
        switch (addressType) {
          case "street_number":
            this.form
              .get("line1")
              ?.setValue(
                value +
                  (this.form.value.line1 ? ` ${this.form.value.line1}` : ""),
              );
            break;
          case "route":
            this.form
              .get("line1")
              ?.setValue(
                (this.form.value.line1 ? `${this.form.value.line1} ` : "") +
                  value,
              );
            break;
          case "locality":
            this.form.get("city")?.setValue(value);
            break;
          case "administrative_area_level_1":
            this.form.get("state")?.setValue(value);
            break;
          case "postal_code":
            this.form.get("zip")?.setValue(value);
            break;
        }
      });
    });
  }
}
