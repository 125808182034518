<div fxLayout="column" fxLayoutAlign="start center" class="open-details-container">
  <mat-card fxLayout="column" fxLayoutAlign="start center" class="loan-summary-card mat-elevation-z">
    <div class="header">{{accountData?.productCode | productCodeLabel}}</div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="card-row">
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">{{ accountData?.loanInfo.availableFunds | currency }}</div>
        <div class="subheading">Available funds</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">{{ accountData?.loanInfo.creditLimit | currency : 'USD' : 'symbol' : '1.0-0' }}</div>
        <div class="subheading">{{ accountData?.productCode === ProductCode.LOC ? "Credit limit" : "Loan amount"}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">
          {{ 
            accountData?.offer.repaymentTerm + (
              accountData?.offer.repaymentTerm > 1
                ? " months"
                : " month"
            )
          }}
        </div>
        <div class="subheading">Term</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">{{ accountData?.offer.paymentFrequency | paymentFrequencyLabel }}</div>
        <div class="subheading">Payment frequency</div>
      </div>
    </div>
  </mat-card>
  <ng-container *ngIf="accountData?.initialDraw || accountData?.productCode !== ProductCode.LOC">
    <div class="loan-notes">Loan has been activated</div>
  </ng-container>
  <ng-container *ngIf="!accountData?.initialDraw && accountData?.productCode === ProductCode.LOC">
    <div class="loan-notes">The agreement has been signed</div>
  </ng-container>
  <ng-container *ngIf="!accountData?.initialDraw">
    <div class="loan-notes-detail">Funds are available to draw.</div>
  </ng-container>
  <ng-container *ngIf="accountData?.initialDraw">
    <div class="loan-notes-detail">Please review initial draw details below.</div>
    <mat-card fxLayout="row" fxLayoutAlign="space-evenly center" class="initial-draw-card">
      <div fxLayout="column" class="card-column">
        <div class="subheading">Draw amount</div>
        <div class="info-text" style="border-bottom-left-radius: 15px;">{{accountData?.initialDraw?.totalAmount | currency}}</div>
      </div>
      <mat-divider vertical class="divider"></mat-divider>
      <div fxLayout="column" class="card-column">
        <div class="subheading">Draw date</div>
        <div class="info-text">{{accountData?.initialDraw?.transactionDate | date}}</div>
      </div>
      <mat-divider vertical class="divider"></mat-divider>
      <div fxLayout="column" class="card-column">
        <div class="subheading">Disbursed amount</div>
        <div class="info-text">{{accountData?.initialDraw?.disbursedAmount | currency}}</div>
      </div>
      <mat-divider vertical class="divider"></mat-divider>
      <div fxLayout="column" class="card-column">
        <div class="subheading">Status</div>
        <div class="info-text" style="border-bottom-right-radius: 15px;">{{adjustStatus(accountData?.initialDraw?.status) | enumValueLabel : TransactionStatus : TransactionStatusLabel}}</div>
      </div>
    </mat-card>
  </ng-container>
  <div
    fxLayout="row"
    fxLayoutAlign="flex-end center"
    class="open-details-button-row center-center layout margin-top"
  >
    <button
      mat-flat-button
      type="button"
      class="open-details-button"
      (click)="back()"
    >
      BACK
    </button>
  </div>
</div>
