import { Component, Input } from '@angular/core';
import { AdvisorWidgetData } from '../../../../domain/model/dashboard.model';

@Component({
  selector: 'ifbp-advisor-widget-new',
  templateUrl: './advisor-widget.component.html',
  styleUrls: ['./advisor-widget.component.scss']
})
export class AdvisorWidgetComponent {
  @Input()
  data: AdvisorWidgetData;

  openCalendarUrl() {
    window.open(this.data?.calendarUrl, "_blank");
  }
}
