<div fxLayout="column" fxLayoutAlign="start center" class="closed-details-container">
  <mat-card fxLayout="column" fxLayoutAlign="start center" class="loan-summary-card mat-elevation-z">
    <div class="header">{{accountData?.productCode | productCodeLabel}}</div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="card-row">
      <div *ngIf="isPaidOff" fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">{{ accountData?.closeDate | date : "M/d/yy"}}</div>
        <div class="subheading">Paid off date</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">{{ accountData?.loanInfo.creditLimit | currency : 'USD' : 'symbol' : '1.0-0' }}</div>
        <div class="subheading">{{ accountData?.productCode === ProductCode.LOC ? "Credit limit" : "Loan amount"}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">
          {{ 
            accountData?.offer.repaymentTerm + (
              accountData?.offer.repaymentTerm > 1
                ? " months"
                : " month"
            )
          }}
        </div>
        <div class="subheading">Term</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" class="card-column">
        <div class="info-text">{{ accountData?.offer.paymentFrequency | paymentFrequencyLabel }}</div>
        <div class="subheading">Payment frequency</div>
      </div>
    </div>
  </mat-card>
  <div class="loan-notes">Loan is closed</div>
  <p class="loan-notes-detail">
    {{
      isPaidOff 
        ? "This loan has been paid off. See the initial disbursed amount below."
        : "This loan was closed due to inactivity. Please contact your business advisor at "
         + (businessInformation.advisorPhone | phone) + " if you have any questions."
    }}
  </p>
  <mat-card *ngIf="isPaidOff" fxLayout="row" fxLayoutAlign="space-evenly center" class="initial-draw-card">
    <div fxLayout="column" class="card-column">
      <div class="subheading">Draw amount</div>
      <div class="info-text" style="border-bottom-left-radius: 15px;">{{accountData?.initialDraw.totalAmount | currency}}</div>
    </div>
    <mat-divider vertical class="divider"></mat-divider>
    <div fxLayout="column" class="card-column">
      <div class="subheading">Draw date</div>
      <div class="info-text">{{accountData?.initialDraw.transactionDate | date}}</div>
    </div>
    <mat-divider vertical class="divider"></mat-divider>
    <div fxLayout="column" class="card-column">
      <div class="subheading">Disbursed amount</div>
      <div class="info-text">{{accountData?.initialDraw.disbursedAmount | currency}}</div>
    </div>
    <mat-divider vertical class="divider"></mat-divider>
    <div fxLayout="column" class="card-column">
      <div class="subheading">Status</div>
      <div class="info-text" style="border-bottom-right-radius: 15px;">{{adjustStatus(accountData?.initialDraw.status) | enumValueLabel : TransactionStatus : TransactionStatusLabel}}</div>
    </div>
  </mat-card>
  <div
    fxLayout="row"
    fxLayoutAlign="flex-end center"
    class="closed-details-button-row center-center layout margin-top"
  >
    <button
      mat-flat-button
      type="button"
      class="closed-details-button"
      (click)="back()"
    >
      BACK
    </button>
  </div>
</div>
