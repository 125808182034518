<mat-dialog-content>
  <header>
    <h2 class="heading">Not interested reason</h2>
  </header>
  <section class="body">
    <p>Select a reason why the client is not interested. Provided additional comments if needed.</p>
    <form [formGroup]="form" class="body">
      <mat-form-field appearance="outline">
        <mat-label>Not interested reason</mat-label>
        <mat-select matInput formControlName="notInterestedReason" placeholder="Not interested reason">
          <mat-option *ngFor="let notInterestedReasonOption of notInterestedReasons" [value]="notInterestedReasonOption.key">{{notInterestedReasonOption.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="isCompetitorVisible">
        <mat-label>Competitor</mat-label>
        <mat-select matInput formControlName="competitorId" placeholder="Competitor">
          <mat-option *ngFor="let competitor of competitors" [value]="competitor.id">{{competitor.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="isCommentVisible">
        <mat-label>Additional comments</mat-label>
        <textarea matInput type="text" formControlName="additionalComment" rows="4"></textarea>
      </mat-form-field>
    </form>
  </section>
  <footer class="actions">
    <button mat-button
      [mat-dialog-close]="null"
      [color]="ButtonColor.Default">CANCEL</button>
    <button mat-button
      [mat-dialog-close]="form.value"
      [disabled]="form.invalid"
      [color]="ButtonColor.Primary">CONFIRM</button>
  </footer>
</mat-dialog-content>
