import { ButtonColor, DialogActionData } from "common";
import { UserData } from "../../domain/models/users.model";

export function getNotificationSentDialogData(user: UserData): DialogActionData {
    return {
      body:
        `An email has been sent to ${user.email} with instructions to create an account and access the broker portal.`,
      title: "Email notification sent",
      buttons: [
        { label: "OK", buttonColor: ButtonColor.Primary, action: true },
      ],
    }};
