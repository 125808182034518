<div class="card">
  <div class="img"
       *ngIf="data?.iconUrl">
    <img width="80"
         [src]="data.iconUrl">
  </div>
  <div class="content">
    <div *ngIf="data?.title"
         class="content__heading"
         [innerHTML]="data.title | safeHtml">
    </div>
    <div *ngIf="data?.body"
         class="content__text"
         [innerHTML]="data.body | safeHtml">
    </div>
    <ng-container *ngIf="data?.actions?.length > 0">
      <div class="actions"
           [class.adjust-margin]="data.actions[0].buttonType === ButtonType.Basic">
        <button *ngFor="let action of data.actions"
                class="actions__item"
                (click)="onClickAction(action)"
                [class.mat-raised-button]="action.buttonType === ButtonType.Raised"
                [class.mat-stroked-button]="action.buttonType === ButtonType.Stroked"
                [class.mat-flat-button]="action.buttonType === ButtonType.Flat"
                mat-button
                [color]="action.buttonColor">
          {{action.label}}
        </button>
      </div>
    </ng-container>
  </div>
</div>
