import { HttpClient } from "@angular/common/http";
import { AppSettings } from "projects/partner/src/app.settings";
import { Observable } from "rxjs";

import {
  EditUserData,
  UsersQueryResult,
  UserData
} from "../domain/models/users.model";
import { Injectable } from "@angular/core";
import { ApiUrls } from "../../../shared/shared-constants";

@Injectable({ providedIn: "root" })
export class UsersRemote {
  constructor(private http: HttpClient, private settings: AppSettings) {}

  getUsers(brokerId: number, params: any): Observable<UsersQueryResult> {
    const url = ApiUrls(this.settings).GetUsers;
    return this.http.get<UsersQueryResult>(url, {
      params: { ...params, brokerId },
    });
  }

  getUser(id: number): Observable<UserData> {
    const url = ApiUrls(this.settings).GetUser({ user: { id } });
    return this.http.get<UserData>(url);
  }

  createUser(data: EditUserData): Observable<UserData> {
    const url = ApiUrls(this.settings).CreateUser;
    return this.http.put<UserData>(url, { ...data, active: true });
  }

  updateUser(id: number, data: EditUserData): Observable<UserData> {
    const url = ApiUrls(this.settings).EditUser({ user: { id } });
    return this.http.patch<UserData>(url, data);
  }
  
  resendInvitation(userId: number): Observable<any> {
    return this.http.post(ApiUrls(this.settings).ResendInvitation(userId), {});
  }
}
