import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import {
  AppBarMenuItem,
  AppBarMenuItemAction,
  MenuItemType,
} from "./app-bar-dropdown.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { hasValue } from "../../../Tools";
import { UserService } from "../../user/user.service";
import { WebPaths } from "../SharedConstants";

@UntilDestroy()
@Component({
  selector: "ifbp-app-bar-dropdown",
  templateUrl: "./app-bar-dropdown.component.html",
  styleUrls: ["./app-bar-dropdown.component.scss"],
})
export class AppBarDropdownComponent implements OnInit {
  data: AppBarDropdownComponentData;
  menuItems: AppBarMenuItem[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(this.dataSet.bind(this));
  }

  private dataSet(data: Data) {
    this.data = data as AppBarDropdownComponentData;
    this.menuItems =
      this.data?.appBarDropdown?.menuItems?.filter(
        it => !hasValue(it.isHidden) || !it.isHidden,
      ) ?? [];
  }

  private onClickMenuItemAction(menuAction: AppBarMenuItemAction): void {
    switch (menuAction) {
      case AppBarMenuItemAction.LOG_OUT: {
        this.userService.logout();
        void this.router.navigate([WebPaths.SignIn]);
        break;
      }
      default: {
        console.warn(`Unsupported menu item action ${menuAction}`);
        break;
      }
    }
  }

  onClickAppBarMenuItem(menuItem: AppBarMenuItem): void {
    switch (menuItem.typeOptions.type) {
      case MenuItemType.ACTION: {
        this.onClickMenuItemAction(menuItem.typeOptions.action);
        break;
      }
      case MenuItemType.LINK: {
        void this.router.navigate([menuItem.typeOptions.url]);
        break;
      }
    }
  }
}

export interface AppBarDropdownComponentData {
  appBarDropdown: {
    menuItems: AppBarMenuItem[];
    menuIcon: string;
  };
}
