import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "ifbp-plaid-info",
  templateUrl: "./plaid-info.component.html",
  styleUrls: ["./plaid-info.component.scss"],
})
export class PlaidInfoComponent {
  constructor(public dialogRef: MatDialogRef<PlaidInfoComponent>) {}

  static show(dialog: MatDialog) {
    dialog.open(PlaidInfoComponent, {
      width: "510px",
    });
  }
}
