import { NgModule } from "@angular/core";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "common";
import { SharedModule } from "../shared/shared.module";
import { OfferDetailsRoutingModule } from "./offer-details-routing.component";
import { OfferDetailsComponent } from "./offer-details.component";
import { SelectionSubmittedDialogComponent } from "./selection-submitted-dialog/selection-submitted-dialog.component";
import { PaymentScheduleTableModule } from "./payment-schedule-table/payment-schedule-table.module";
import { SubmissionsModule } from "../feature-modules/submissions/submissions.module";

@NgModule({
  imports: [
    CommonModule,
    OfferDetailsRoutingModule,
    SharedModule,
    NgxSliderModule,
    PaymentScheduleTableModule,
    SubmissionsModule
  ],
  declarations: [OfferDetailsComponent, SelectionSubmittedDialogComponent],
})
export class OfferDetailsModule { }
