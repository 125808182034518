import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "common";
import { defaultObservableErrorHandler } from "projects/partner/src/Tools";
import { AppSettings } from "projects/partner/src/app.settings";
import { ApiUrls } from "projects/partner/src/app/shared/SharedConstants";
import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { LostReasonsQueryResult } from "../domain/model/dictionaries.model";
import { Params } from "@angular/router";

const initParams = { skip: 0, limit: 100, active: true, sort: 'value' };

@Injectable({
  providedIn: "root"
})
export class DictionariesRemote {
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private settings: AppSettings) {
  }

  getAllLostReasons(params?: Params, previous?: LostReasonsQueryResult): Observable<LostReasonsQueryResult> {
    return this.http
      .get<LostReasonsQueryResult>(
        ApiUrls(this.settings).GetLostReasons(), 
        { params: params ?? initParams }
      )
      .pipe(
        switchMap(lostReasonsQueryResult => {
          if(previous && previous.values.length) {
            lostReasonsQueryResult?.values?.unshift(...previous?.values);
          }
          if (lostReasonsQueryResult?.values?.length == lostReasonsQueryResult.totalCount) {
            return of(lostReasonsQueryResult);
          } else {
          return this.getAllLostReasons({ ...initParams, 
            skip: lostReasonsQueryResult.values.length, 
            limit: lostReasonsQueryResult.totalCount },
            lostReasonsQueryResult
            );
          }
        }),
        catchError(
          defaultObservableErrorHandler(
            this.messageService
          )
        )
      );
  }
}