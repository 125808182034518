import { NgModule } from "@angular/core";
import { SignInComponent } from './sign-in.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SharedModule } from "projects/partner/src/app/shared/shared.module";
import { SignInRoutingModule } from "./sign-in-routing.module";
import { SignInModule as CommonSignInModule } from 'common';

@NgModule({
  imports: [SharedModule, GooglePlaceModule, SignInRoutingModule, CommonSignInModule],
  declarations: [SignInComponent],
})
export class SignInModule { }
