import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { FiltersChipListModule } from "./filters-chip-list/filters-chip-list.module";
import { NoResultsFoundComponent } from './no-results-found/no-results-found.component';

@NgModule({
  declarations: [NoResultsFoundComponent],
  imports: [SharedModule, FiltersChipListModule],
  exports: [SharedModule, FiltersChipListModule, NoResultsFoundComponent],
})
export class SubmissionsSharedModule { }
