import { FilterRequest, QueryParamsBase } from "./filter.model";

export class FilterHelper {
  static filtersToParams<T extends QueryParamsBase>(data: FilterRequest<T>): T {
    const { sortData, pageData } = data;
    let sort: string | undefined = undefined;
    let pageNumber: number = 1;
    let pageSize: number | undefined = undefined;

    if (sortData) {
      const isAsc = sortData.direction === 'asc';
      const isEmpty = sortData.direction === '';
      const prefix = isAsc ? '' : '-';
      sort = isEmpty ? undefined : `${prefix}${sortData.active}`;
    }
    if (pageData) {
      pageNumber = pageData.pageIndex + 1;
      pageSize = pageData.pageSize;
    }
    return {
      ...data?.filters,
      sort: sortData ? sort : data?.filters?.sort,
      pageNumber,
      pageSize: pageData ? pageSize : data?.filters?.pageSize,
    }
  }
}
